<div class="flex flex-col w-[95vw] p-2 mb-5 ml-5 mr-5 print_only_grade_sheet">
  <mat-card *ngFor="let sheet of [].constructor(numberOfSheets); let j = index">
    <ng-container *ngFor="let position of selectedPositionNames; let i = index">
      <ng-container *ngIf="i === 0; else other_content">
        <div class="avoid-break w-[95vw]">
          <mat-card-header class="p-2.5">
            <div class="w-full">
              <div class="flex justify-between items-center">
                <h3 class="text-xxl" *ngIf="gameType.playType">
                  {{ currentWeek.opponent_name }} |
                  {{
                    gameType?.playType === 'G'
                      ? 'Game '
                      : 'Practice ' + (gameType?.countNo ? gameType?.countNo : '- ')
                  }}
                </h3>
                <h3 class="text-xxl">Play #{{ j + 1 }}</h3>
                <h3>{{ positionGroup }}</h3>
                <img class="w-[140px]" src="assets/images/logo/logo-dark-sm.png" alt="" />
              </div>
            </div>
          </mat-card-header>
          <ng-template
            [ngTemplateOutlet]="commonContent"
            [ngTemplateOutletContext]="{ position: position }"
          ></ng-template>
        </div>
      </ng-container>
      <ng-template #other_content>
        <div class="w-[95vw]">
          <ng-template
            [ngTemplateOutlet]="commonContent"
            [ngTemplateOutletContext]="{ position: position }"
          ></ng-template>
        </div>
      </ng-template>
    </ng-container>
  </mat-card>
</div>

<ng-template #commonContent let-position="position">
  <mat-card-content class="pt-2 pb-2 pl-0 pr-0">
    <div class="flex brbtm avoid-break">
      <div class="w-3/5 pr-2 flex">
        <div class="flex flex-col items-center w-[12%] pr-2">
          <div>
            <h2 class="font-bold">#</h2>
          </div>
          <div class="mb-2">
            <span class="box"></span>
          </div>
          <div class="flex items-center mb-2 mt-2">
            <h1
              [ngClass]="getFontSizeClass(position)"
              class="bg-black flex items-center justify-center text-white mt-2 flag"
            >
              {{ position }}
            </h1>
          </div>
        </div>
        <div class="w-[88%] flex flex-col pl-2">
          <div class="flex justify-between mb-1">
            <div *ngFor="let skill of skillSet">
              <!-- Skip this iteration if skill is 'PRODUCTION' -->
              <ng-container *ngIf="skill !== 'PRODUCTION'">
                <div class="flex flex-col items-center">
                  <span class="font-semibold skill">{{ skill }}</span>
                  <div class="circles-in-box mr-1">
                    <span class="circle green-circle"></span>
                    <span class="circle black-border-white-circle"></span>
                    <span class="circle red-border-white-circle"></span>
                  </div>

                  <ng-container *hasUserGroupPermission="UserGroupPermission.ProfessionalTeam">
                    <span class="horizontal_lines"></span>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="text-md font-bold italic">COMMENT | FEEDBACK</div>
          <div class="mb-2">
            <div class="w-full bigbox">
              <span class="horizontal_lines"></span>
              <span class="horizontal_lines"></span>
              <span class="horizontal_lines"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="w-2/5 pl-2">
        <div class="flex justify-between items-center bg-gray-900 text-white mb-2">
          <h3 class="center p-2 ml-[90px] flex-grow text-center">PRODUCTION</h3>
          <div class="flex items-center mr-2">
            <span class="textdisc">DISC</span>
            <span class="discbox border ml-2"></span>
          </div>
        </div>
        <div class="grid-container">
          <div class="grid-item" *ngFor="let item of productionList">
            <div class="flex items-center">
              <input type="checkbox" /> <span class="pl-1">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</ng-template>
