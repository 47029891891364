// weeks.state.ts
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable, iif, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import { WeeksService } from '@shared/services/weeks/weeks.service';
import {
  LoadWeeks,
  ResetSelectedWeek,
  ResetWeeks,
  SelectedWeek,
} from '@shared/states/weeks/weeks.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

// Define the state model
export interface WeeksStateModel {
  weeks: WeeksApi[];
  selectedWeek: WeeksApi;
}

@State<WeeksStateModel>({
  name: 'weeks',
  defaults: {
    weeks: [],
    selectedWeek: {
      pk: null,
      week_no: null,
      season_id: {
        id: null,
        name: '',
        year: null,
        user_id: null,
      },
      opponent_id: null,
      opponent_name: null,
    },
  },
})
@Injectable()
export class WeeksState {
  constructor(private weeksService: WeeksService) {}

  @Action(SelectedWeek)
  selectedWeek({ patchState }: StateContext<WeeksStateModel>, { payload }: SelectedWeek) {
    patchState({ selectedWeek: payload });
  }

  @Action(ResetSelectedWeek)
  resetSelectedWeek({ patchState }: StateContext<WeeksStateModel>) {
    patchState({
      selectedWeek: {
        pk: null,
        week_no: null,
        season_id: {
          id: null,
          name: '',
          year: null,
          user_id: null,
        },
        opponent_id: null,
        opponent_name: null,
      },
    });
  }

  @Action(LoadWeeks)
  loadWeeks(
    { getState, patchState }: StateContext<WeeksStateModel>,
    { id }: LoadWeeks
  ): Observable<WeeksApi[]> {
    let weeks = getState().weeks;
    return iif(
      () => weeks.length && weeks[0].season_id.id == id,
      of(getState().weeks),
      this.weeksService.getWeeks(id).pipe(
        tap((weeks) => {
          patchState({ weeks: weeks });
        })
      )
    );
  }

  @Action(ResetWeeks)
  resetWeeks({ patchState }: StateContext<WeeksStateModel>) {
    patchState({
      weeks: [],
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<WeeksStateModel>) {
    ctx.setState({
      weeks: [],
      selectedWeek: {
        pk: null,
        week_no: null,
        season_id: {
          id: null,
          name: '',
          year: null,
          user_id: null,
        },
        opponent_id: null,
        opponent_name: null,
      },
    });
  }
}
