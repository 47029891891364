<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-6">
  <div class="flex md:w-full md:max-w-6xl sm:rounded-2xl sm:shadow overflow-hidden sm:bg-card">
    <div
      style="
        background: url('assets/images/backgrounds/login-screen.jpg');
        background-size: cover;
        background-position: center;
      "
      class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden dark:border-r"
    >
      <!-- Content -->
      <div class="z-10 relative w-full max-w-2xl">
        <!--                <div>text</div>-->
      </div>
    </div>
    <div class="w-full sm:w-auto py-2 px-4 sm:p-8 md:p-8">
      <div class="w-full max-w-100 sm:w-100 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-auto flex justify-center items-center">
          <img
            width="100"
            height="83"
            style="max-width: 180px"
            [ngSrc]="
              (currentTheme$ | async) === 'light'
                ? 'assets/images/logo/logo-light2_.svg'
                : 'assets/images/logo/logo-dark.png'
            "
            alt="logo"
          />
        </div>

        <!-- Title -->
        <div class="mt-4 text-4xl font-extrabold tracking-tight leading-tight">Sign up</div>
        <div class="flex items-baseline mt-0.5 font-medium">
          <div>Already have an account?</div>
          <a
            [routerLink]="['/authentication/sign-in']"
            class="ml-1 text-primary-500 hover:underline"
            >Sign in
          </a>
        </div>

        <!-- Sign Up form -->
        <form [formGroup]="signUpForm" class="mt-4">
          <!-- First name field -->
          <mat-form-field class="w-1/2 pr-2">
            <mat-label>First Name</mat-label>
            <input formControlName="first_name" id="first_name" matInput />
            <mat-error *ngIf="signUpForm.get('first_name').hasError('required')">
              First name is required
            </mat-error>
            <mat-error *ngFor="let error of errorMessages['first_name']"> {{ error }}</mat-error>
          </mat-form-field>

          <!-- First name field -->
          <mat-form-field class="w-1/2 pr-2">
            <mat-label>Last Name</mat-label>
            <input formControlName="last_name" id="last_name" matInput />
            <mat-error *ngIf="signUpForm.get('last_name').hasError('required')">
              Last name is required
            </mat-error>
            <mat-error *ngFor="let error of errorMessages['last_name']"> {{ error }}</mat-error>
          </mat-form-field>

          <!-- Name field -->
          <mat-form-field class="w-1/2 pr-2">
            <mat-label>Club Name</mat-label>
            <input formControlName="club_name" id="club_name" matInput />
            <mat-error *ngIf="signUpForm.get('club_name').hasError('required')">
              Club name is required
            </mat-error>
            <mat-error *ngFor="let error of errorMessages['club_name']"> {{ error }}</mat-error>
          </mat-form-field>

          <mat-form-field class="w-1/2 pr-2">
            <mat-label>Username</mat-label>
            <input formControlName="username" id="username" autocomplete="username" matInput />
            <mat-error *ngIf="signUpForm.get('username').hasError('required')">
              Username is required
            </mat-error>

            <mat-error *ngFor="let error of errorMessages['username']">
              {{ error }}
            </mat-error>
          </mat-form-field>

          <!-- Email field -->
          <mat-form-field class="w-full pr-2">
            <mat-label>Email address</mat-label>
            <input formControlName="email" id="email" matInput />
            <mat-error *ngIf="signUpForm.get('email').hasError('required')">
              Email address is required
            </mat-error>
            <mat-error *ngIf="signUpForm.get('email').hasError('email')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="signUpForm.get('email').hasError('serverError')">
              {{ errorMessages['email'].join('\n') }}
            </mat-error>
          </mat-form-field>

          <!-- Password field -->
          <mat-form-field class="w-1/2 pr-2">
            <mat-label>Password</mat-label>
            <input
              #passwordField
              formControlName="password1"
              id="password1"
              autocomplete="current-password"
              matInput
              type="password"
            />
            <button
              (click)="
                passwordField.type === 'password'
                  ? (passwordField.type = 'text')
                  : (passwordField.type = 'password')
              "
              mat-icon-button
              matSuffix
              type="button"
            >
              <mat-icon
                *ngIf="passwordField.type === 'password'"
                [svgIcon]="'heroicons_solid:eye'"
                class="icon-size-5"
              ></mat-icon>
              <mat-icon
                *ngIf="passwordField.type === 'text'"
                [svgIcon]="'heroicons_solid:eye-slash'"
                class="icon-size-5"
              ></mat-icon>
            </button>
            <mat-error> Password is required </mat-error>
            <mat-error *ngFor="let error of errorMessages['password1']">
              {{ error }}
            </mat-error>
          </mat-form-field>

          <!-- confirmPassword field -->
          <mat-form-field class="w-1/2 pr-2">
            <mat-label>Confirm Password</mat-label>
            <input
              #confimPasswordField
              formControlName="password2"
              id="password2"
              autocomplete="current-password"
              matInput
              type="password"
            />
            <button
              (click)="
                confimPasswordField.type === 'password'
                  ? (confimPasswordField.type = 'text')
                  : (confimPasswordField.type = 'password')
              "
              mat-icon-button
              matSuffix
              type="button"
            >
              <mat-icon
                *ngIf="confimPasswordField.type === 'password'"
                [svgIcon]="'heroicons_solid:eye'"
                class="icon-size-5"
              ></mat-icon>
              <mat-icon
                *ngIf="confimPasswordField.type === 'text'"
                [svgIcon]="'heroicons_solid:eye-slash'"
                class="icon-size-5"
              ></mat-icon>
            </button>
            <mat-error *ngIf="isControlHasError('password2', 'maxlength')">
              <strong>Password should be 100 max</strong>
            </mat-error>
            <mat-error
              *ngIf="
                signUpForm.get('password2').errors && signUpForm.get('password2').errors.noMatch
              "
            >
              <strong>Password and Confirm Password didn't match.</strong>
            </mat-error>
            <mat-error *ngFor="let error of errorMessages['password2']">
              {{ error }}
            </mat-error>
          </mat-form-field>

          <!-- ToS and PP -->
          <div class="inline-flex items-end w-full mt-2">
            <mat-checkbox [color]="'primary'" formControlName="agree" class="-ml-2">
              I agree the
              <a href="javascript:" (click)="showTermsAndConditions($event)">terms & conditions</a>
            </mat-checkbox>
          </div>

          <!-- Submit button -->
          <button
            (click)="signUp()"
            [color]="'primary'"
            [disabled]="(loading$ | async) || signUpForm.invalid"
            class="fuse-mat-button-large w-full mt-1"
            mat-flat-button
          >
            <span *ngIf="!(loading$ | async)"> Submit </span>
            <mat-progress-spinner
              *ngIf="loading$ | async"
              [diameter]="24"
              [mode]="'indeterminate'"
            ></mat-progress-spinner>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
