import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { StartLoading, StopLoading } from './loading.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface LoadingStateModel {
  isLoading: boolean;
}

@State<LoadingStateModel>({
  name: 'loading',
  defaults: {
    isLoading: false,
  },
})
@Injectable()
export class LoadingState {
  @Action(StartLoading)
  startLoading(ctx: StateContext<LoadingStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
  }

  @Action(StopLoading)
  stopLoading(ctx: StateContext<LoadingStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<LoadingStateModel>) {
    ctx.setState({
      isLoading: false,
    });
  }
}
