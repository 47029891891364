import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  Skill,
  Mtp,
  PositionGroupMtps,
  AddMtp,
  EditMtp,
  UpdateMtpOrderI,
} from '../interfaces/production.interface';
import { environment } from 'environments/environment';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { Season } from '@shared/interfaces/season.interface';
import { Comment } from '@shared/interfaces/comment.interface';
import { Sob } from 'app/core/layout/components/select-sob/enums/sob.enum';

@Injectable({
  providedIn: 'root',
})
export class ProductionService {
  constructor(private http: HttpClient) {}

  getMtp(sobValue: SOB['value'], season_id: Season['id']): Observable<Mtp[]> {
    return this.http
      .get<
        Mtp[]
      >(`${environment.main_url}/Season_MTP/?season_id=${season_id}&mtp_type_id__side_of_ball=${sobValue}`)
      .pipe(
        map((mtps: Mtp[]) => {
          return mtps.map((mtp: Mtp) => {
            mtp.sobValue = sobValue;
            return mtp;
          });
        })
      );
  }

  getPositionGroupMtps(
    pgId: PositionGroup['name'],
    season_id: Season['id']
  ): Observable<PositionGroupMtps> {
    return this.http
      .get<PositionGroupMtps>(`${environment.main_url}/PositionGroup/${pgId}/pg-mtps/${season_id}/`)
      .pipe(
        map((res) => {
          res.mtps = res.mtps.sort((a, b) => a.order - b.order);
          res.season_id = season_id;
          // res.mtps = res.mtps.map((mt) => mt.mtp)
          return res;
        })
      );
  }

  getMtpsByPositionGroups(season_id: string, sob: SOB['value'], pgs: PositionGroup['name'][]) {
    let url = `${environment.main_url}/PositionGroup/pgs-mtps/${season_id}/?season=${season_id}&side_of_ball__in=${sob}`;
    if (pgs && pgs.length && sob != Sob.SpecialTeamsValue) {
      url += `&name__in=${pgs.toString()}`;
    }
    return this.http.get<PositionGroupMtps[]>(url);
  }

  addMtp(payload: AddMtp): Observable<Mtp[]> {
    return this.http.post<Mtp[]>(`${environment.main_url}/MTP_types/`, payload);
  }

  updateMtp(id: number, payload: EditMtp) {
    return this.http.patch(`${environment.main_url}/MTP_types/${id}/`, payload);
  }

  updateMtpWeight(id: number, payload: EditMtp) {
    return this.http.patch(`${environment.main_url}/Season_MTP/${id}/`, payload);
  }

  deleteMtp(id: number) {
    return this.http.delete(`${environment.main_url}/Season_MTP/${id}/`);
  }

  updateMtpOrder(id: number, payload: UpdateMtpOrderI[]): Observable<UpdateMtpOrderI[]> {
    return this.http.patch<UpdateMtpOrderI[]>(
      `${environment.main_url}/PositionGroup/${id}/update-pg-mtp/`,
      payload
    );
  }

  addMtpToPg(payload: any) {
    return this.http.post(`${environment.main_url}/PositionGroup/pg-mtps/`, payload);
  }

  deletePgMtp(id: number) {
    return this.http.delete(`${environment.main_url}/PositionGroup/${id}/delete-pg-mtp/`);
  }

  getSkills(mtpId: number, season_id: number): Observable<Skill[]> {
    return this.http
      .get<Skill[]>(`${environment.main_url}/Skill/?pg=${mtpId}&season=${season_id}`)
      .pipe(
        map((skills) =>
          skills.map((skill) => ({
            ...skill,
            classes: [...skill.classes].sort((a, b) => a.text.localeCompare(b.text)),
          }))
        )
      );
  }

  addSkill(payload: any): Observable<Skill> {
    return this.http.post<Skill>(`${environment.main_url}/Skill/`, payload);
  }

  updateSkill(skillId: number, payload: any): Observable<Skill> {
    return this.http.patch<Skill>(`${environment.main_url}/Skill/${skillId}/`, payload);
  }

  deleteSkill(skillId: number) {
    return this.http.delete(`${environment.main_url}/Skill/${skillId}/`);
  }

  getComments(mtpId: number, season_id: Season['id']): Observable<Comment[]> {
    return this.http.get<Comment[]>(
      `${environment.main_url}/Comment/?pg=${mtpId}&season=${season_id}`
    );
  }

  addComment(payload): Observable<Comment> {
    return this.http.post<Comment>(`${environment.main_url}/Comment/`, payload);
  }

  updateComment(id: Comment['id'], payload): Observable<Comment> {
    return this.http.patch<Comment>(`${environment.main_url}/Comment/${id}/`, payload);
  }

  updateCommentsOrder(payload): Observable<string> {
    return this.http.patch<string>(`${environment.main_url}/Comment/update-comments/`, payload);
  }

  deleteComment(id: Comment['id']) {
    return this.http.delete(`${environment.main_url}/Comment/${id}/`);
  }
}
