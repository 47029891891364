import { ChangePasswordApiResponse, ChangePasswordPayload } from '@shared/interfaces/user';

export class ChangePassword {
  static readonly type = '[Auth] Change Password ';
  constructor(public payload: ChangePasswordPayload) {}
}

export class ChangePasswordSuccess {
  static readonly type = '[Auth] Change Password Success';
  constructor(public payload: ChangePasswordApiResponse) {}
}

export class ChangePasswordFailure {
  static readonly type = '[Auth] Change Password Failure';
  constructor(public payload: ChangePasswordApiResponse) {}
}
