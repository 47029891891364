<div class="w-full show-matches-container print:hidden">
  <og-card pageTitle="Grade">
    <div class="text-end print:hidden">
      <div class="mb-2 flex justify-end">
        <button
          class="mx-1 pr-6"
          mat-raised-button
          color="primary"
          [disabled]="!rowData.length || noPlayIsGraded"
          (click)="exportGradingData()"
        >
          <mat-icon class="icon-size-6 pr-1" [svgIcon]="'feather:corner-right-down'"></mat-icon>
          Export Grading Data
        </button>
        <button
          class="mx-1 pr-6"
          mat-raised-button
          color="primary"
          (click)="openGradeSheetDialog()"
        >
          <mat-icon class="icon-size-6 pr-1" [svgIcon]="'feather:corner-right-down'"></mat-icon>
          Grade Sheet(s)
        </button>
      </div>
    </div>
    <div class="mat-elevation-z2 flex flex-wrap justify-center">
      <div class="py-[20px] items-center">
        <og-select-week width="290px"></og-select-week>
      </div>
      <div class="py-[20px] content-end items-center">
        <og-select-game-practice widthForGamePractice="180px" widthForPracticeNo="210px">
        </og-select-game-practice>
      </div>
      <div
        *ngIf="
          gameType && gameType.playType && (isSpecialTeams || (isPractice && gameType?.countNo))
        "
        class="py-[20px] content-end items-center"
      >
        <mat-form-field floatLabel="always" class="w-[230px] px-2">
          <mat-select
            *ngIf="!isSpecialTeams"
            label="Period Description"
            placeholder="Period Description"
            [ngModel]="gameType.qtr"
            (selectionChange)="optionChange($event)"
          >
            <mat-option [value]="null" (click)="filterByPeriodDescription(null)">None</mat-option>
            <mat-option
              *ngFor="let period of periodDescriptions"
              [value]="period.id"
              (click)="filterByPeriodDescription(period.id)"
              >{{ period.value }}</mat-option
            >
          </mat-select>

          <mat-select
            *ngIf="isSpecialTeams"
            label="Phase"
            placeholder="Phase"
            [ngModel]="gameType.phaseTypes"
            (selectionChange)="optionChange($event)"
          >
            <mat-option [value]="null" (click)="filterByPhaseTypes(null)">None</mat-option>
            <mat-option
              *ngFor="let type of phaseTypes"
              [value]="type"
              (click)="filterByPhaseTypes(type)"
              >{{ type }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="mt-[10px]">
      <ag-grid-angular
        *ngIf="rowData.length"
        style="height: 550px"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
      >
      </ag-grid-angular>
      <div
        class="flex justify-center text-[15px] font-bold"
        *ngIf="
          !(isLoading$ | async) &&
          !rowData.length &&
          this.currentWeek?.week_no != null &&
          this.currentWeek?.week_no != undefined &&
          (this.gameType?.playType == 'G' || this.gameType?.gp_count)
        "
      >
        No data has been uploaded for this
        {{ this.gameType?.playType == 'G' ? 'Game' : 'Practice' }}
      </div>
    </div>
  </og-card>
</div>

<!-- Print Only Content-->
<og-grade-sheet-preview
  *ngIf="numberOfSheets"
  [numberOfSheets]="numberOfSheets"
  [selectedPositionNames]="selectedPositionNames"
  [gameType]="gameType"
  [currentWeek]="currentWeek"
  [positionGroup]="positionGroup"
  [skillSet]="skillSet"
  [productionList]="productionList"
></og-grade-sheet-preview>
