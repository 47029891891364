import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { Season } from '@shared/interfaces/season.interface';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import {
  GamestratDataUploadInitialData,
  GamestratVideoUploadProgress,
  RemoveGamestratUploadedData,
} from './game-strat-data-upload.actions';
import { VideoProgressI } from '@shared/interfaces/data-upload-interface';

export interface GamestratDataUploadStateI {
  sob: SOB;
  season: Season;
  week: WeeksApi;
  matchType: string;
  gameNo: string;
  videoUploadProgress: VideoProgressI;
}
export interface GamestratDataUploadStateModel {
  gamestratDataUpload: GamestratDataUploadStateI[];
}

@State<GamestratDataUploadStateModel>({
  name: 'GamestratDataUpload',
  defaults: {
    gamestratDataUpload: [],
  },
})
@Injectable()
export class GamestratDataUploadState {
  constructor() {}

  @Action(GamestratDataUploadInitialData)
  dataUploadSOB(
    ctx: StateContext<GamestratDataUploadStateModel>,
    { sob, season, payload }: GamestratDataUploadInitialData
  ) {
    let state = ctx.getState().gamestratDataUpload;
    state.push({
      sob: sob,
      season: season,
      week: payload.week,
      matchType: payload.match_type,
      gameNo: payload.game_number,
      videoUploadProgress: { isUploading: false, totalFiles: 0, filesUploaded: 0, percent: 0 },
    });

    ctx.patchState({
      gamestratDataUpload: state,
    });
  }

  @Action(GamestratVideoUploadProgress)
  gamestratVideoUploadProgress(
    ctx: StateContext<GamestratDataUploadStateModel>,
    { sobValue, payload }: GamestratVideoUploadProgress
  ) {
    let state = ctx.getState().gamestratDataUpload;
    state = state.map((s) => {
      if (s.sob.value == sobValue) s.videoUploadProgress = payload;
      return s;
    });

    ctx.patchState({
      gamestratDataUpload: state,
    });
  }

  @Action(RemoveGamestratUploadedData)
  removeGamestratUploadedData(
    ctx: StateContext<GamestratDataUploadStateModel>,
    { sobValue }: RemoveGamestratUploadedData
  ) {
    let state = ctx.getState().gamestratDataUpload;
    state = state.filter((s) => {
      if (s.sob.value != sobValue) return s;
    });

    ctx.patchState({
      gamestratDataUpload: state,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<GamestratDataUploadStateModel>) {
    ctx.setState({
      gamestratDataUpload: [],
    });
  }
}
