import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import {
  GetWeeksPlayersApi,
  PayloadPlayer,
  PlayerComparisonData,
} from '../interfaces/player-comparison-interface';

@Injectable({
  providedIn: 'root',
})
export class PlayerComparisonService {
  constructor(private http: HttpClient) {}

  getGamesPracticesPlayers(
    pgId: PositionGroup['name'],
    seasonId: Season['id'],
    sob: SOB['value']
  ): Observable<GetWeeksPlayersApi> {
    return this.http.get<GetWeeksPlayersApi>(
      `${environment.main_url}/Player/weeks_matches_pgs/${pgId}/${seasonId}/${sob}/`
    );
  }

  getComparisonReportData(
    seasonId: Season['id'],
    pg: PositionGroup,
    sob: SOB['value'],
    payloadPlayers: PayloadPlayer[]
  ): Observable<PlayerComparisonData> {
    const payload = {
      season_id: seasonId,
      side_of_ball: sob,
      position_group: pg.name,
      players: [...payloadPlayers],
    };
    return this.http.post<PlayerComparisonData>(
      `${environment.main_url}/PlayerReport/v3/player-comparison/`,
      payload
    );
  }
}
