import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { MobileScreen, SmallScreen } from './breakpoint.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface BreakpointStateModel {
  isSmallScreen: boolean;
  isMobileScreen: boolean;
}

@State<BreakpointStateModel>({
  name: 'breakpoint',
  defaults: {
    isSmallScreen: false,
    isMobileScreen: false,
  },
})
@Injectable()
export class BreakpointState {
  @Action(SmallScreen)
  setSmallScreen(ctx: StateContext<BreakpointStateModel>, { state }: SmallScreen) {
    ctx.patchState({
      isSmallScreen: state,
    });
  }

  @Action(MobileScreen)
  setMobileScreen(ctx: StateContext<BreakpointStateModel>, { state }: MobileScreen) {
    ctx.patchState({
      isMobileScreen: state,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<BreakpointStateModel>) {
    ctx.setState({ isSmallScreen: false, isMobileScreen: false });
  }
}
