import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AddSubstitutionResponse,
  PlayerInfo,
  Substitution,
} from '../interfaces/player-substitution.interface';
import { PlayerSubstitutionService } from '../services/player-substitution.service';
import {
  AddSubstitutionPlayers,
  DeleteSubstitution,
  DeleteSubstitutionPlayer,
  LoadPlayersByPositionGroup,
  LoadSubstitutions,
  PatchSubstitution,
  PatchSubstitutionPlayer,
  PostSubstitution,
} from './player-substitution.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { NotificationService } from '@shared/services/notification/notification.service';

// Define the state model
export interface PlayerSubstitutionStateModel {
  substitutions: Substitution[];
  playersByPositionGroup: { [key: number]: PlayerInfo[] };
}

@State<PlayerSubstitutionStateModel>({
  name: 'playerSubstitution',
  defaults: {
    substitutions: [],
    playersByPositionGroup: {},
  },
})
@Injectable()
export class PlayerSubstitutionState {
  constructor(
    private playerSubstitutionService: PlayerSubstitutionService,
    private notificationService: NotificationService
  ) {}

  @Action(LoadSubstitutions)
  loadSubstitutions(
    { patchState }: StateContext<PlayerSubstitutionStateModel>,
    { seasonId, sob }: LoadSubstitutions
  ): Observable<Substitution[]> {
    return this.playerSubstitutionService.getSubstitutions(seasonId, sob).pipe(
      tap((substitutions) => {
        patchState({ substitutions });
      })
    );
  }

  @Action(PostSubstitution)
  postSubstitution(
    { patchState, getState }: StateContext<PlayerSubstitutionStateModel>,
    { payload }: PostSubstitution
  ): Observable<Substitution> {
    return this.playerSubstitutionService.postSubstitution(payload).pipe(
      tap((substitution: Substitution) => {
        patchState({ substitutions: [...getState().substitutions, substitution] });
      })
    );
  }

  @Action(PatchSubstitution)
  patchSubstitution(
    { patchState, getState }: StateContext<PlayerSubstitutionStateModel>,
    { substitutionId, payload }: PatchSubstitution
  ) {
    return this.playerSubstitutionService.patchSubstitution(substitutionId, payload).pipe(
      tap(() => {
        const updatedSubstitution = [...getState().substitutions];
        const index = updatedSubstitution.findIndex((sub) => sub.id === substitutionId);
        if (index !== -1) {
          updatedSubstitution[index] = { ...updatedSubstitution[index], ...payload };
        }
        patchState({ substitutions: updatedSubstitution });
      })
    );
  }

  @Action(DeleteSubstitution)
  deleteSubstitution(
    { patchState, getState }: StateContext<PlayerSubstitutionStateModel>,
    { substitutionId }: DeleteSubstitution
  ) {
    return this.playerSubstitutionService.deleteSubstitution(substitutionId).pipe(
      tap(() => {
        const updatedSubstitution = getState().substitutions.filter(
          (sub) => sub.id !== substitutionId
        );
        patchState({ substitutions: updatedSubstitution });
      })
    );
  }

  @Action(PatchSubstitutionPlayer)
  patchSubstitutionPlayer(
    { patchState, getState }: StateContext<PlayerSubstitutionStateModel>,
    { playerPosSubId, payload }: PatchSubstitutionPlayer
  ) {
    return this.playerSubstitutionService.patchSubstitutionPlayer(playerPosSubId, payload).pipe(
      tap(() => {
        const updatedSubstitutionList = getState().substitutions.map((sub) => {
          const playerPositions = sub.player_position.map((playerPos) => {
            if (playerPos.id === playerPosSubId) {
              return { ...playerPos, ...payload };
            }
            return playerPos;
          });
          return { ...sub, player_position: playerPositions };
        });
        patchState({ substitutions: updatedSubstitutionList });
      })
    );
  }

  @Action(DeleteSubstitutionPlayer)
  deleteSubstitutionPlayer(
    { patchState, getState }: StateContext<PlayerSubstitutionStateModel>,
    { playerPosSubId }: DeleteSubstitutionPlayer
  ) {
    return this.playerSubstitutionService.deleteSubstitutionPlayer(playerPosSubId).pipe(
      tap(() => {
        const updatedSubstitutionList = getState().substitutions.map((sub) => {
          const playerPositions = sub.player_position.filter(
            (playerPos) => playerPos.id !== playerPosSubId
          );
          return { ...sub, player_position: playerPositions };
        });
        patchState({ substitutions: updatedSubstitutionList });
      })
    );
  }

  @Action(LoadPlayersByPositionGroup)
  loadPlayersByPositionGroup(
    { patchState, getState }: StateContext<PlayerSubstitutionStateModel>,
    { positionGroups, seasonId }: LoadPlayersByPositionGroup
  ): Subscription[] {
    if (!positionGroups.length) {
      patchState({ playersByPositionGroup: {} });
    }

    const { playersByPositionGroup } = getState();
    const pgIdsInStateSet = new Set(Object.keys(playersByPositionGroup).map((id) => +id));

    const addedPgs = positionGroups
      .filter((pg) => !pgIdsInStateSet.has(+pg.name))
      .map((pg) => +pg.name);

    const removedPgs = Array.from(pgIdsInStateSet).filter(
      (pgId) => !positionGroups.some((pg) => +pg.name === pgId)
    );

    const updatedState = removedPgs.reduce((state, pgId) => {
      const { [pgId]: _, ...remaining } = state;
      return remaining;
    }, playersByPositionGroup);

    patchState({ playersByPositionGroup: updatedState });

    return addedPgs.map((positionGroup) => {
      return this.playerSubstitutionService
        .getPlayersByPositionGroup(positionGroup, seasonId)
        .pipe(
          tap((players) => {
            const currentState = getState().playersByPositionGroup;

            if (!players.length) {
              let pgName: string;
              positionGroups.forEach((pg) => {
                if (pg.name === positionGroup) {
                  pgName = pg.pg_name;
                }
              });
              this.notificationService.info(`No Player is available in ${pgName}`);
            }
            patchState({
              playersByPositionGroup: { ...currentState, [positionGroup]: players },
            });
          })
        )
        .subscribe();
    });
  }

  @Action(AddSubstitutionPlayers)
  addSubstitutionPlayers(
    { patchState, getState }: StateContext<PlayerSubstitutionStateModel>,
    { substitutionId, payload }: AddSubstitutionPlayers
  ): Observable<AddSubstitutionResponse> {
    return this.playerSubstitutionService.addSubstitutionPlayers(substitutionId, payload).pipe(
      tap((response: AddSubstitutionResponse) => {
        const updatedSubstitution = [...getState().substitutions];
        const index = updatedSubstitution.findIndex((sub) => sub.id === substitutionId);
        if (index !== -1) {
          // Update the existing substitution with the new response data
          updatedSubstitution[index] = { ...updatedSubstitution[index], ...response };
        }
        patchState({ substitutions: updatedSubstitution });
      })
    );
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<PlayerSubstitutionStateModel>) {
    ctx.setState({
      substitutions: [],
      playersByPositionGroup: [],
    });
  }
}
