import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { Season } from '@shared/interfaces/season.interface';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import {
  HudlDataUploadInitialData,
  HudlVideoUploadProgress,
  RemoveHudlUploadedData,
} from './hudl-data-upload.actions';
import { VideoProgressI } from '@shared/interfaces/data-upload-interface';

export interface HudlDataUploadStateI {
  sob: SOB;
  season: Season;
  week: WeeksApi;
  matchType: string;
  gameNo: string;
  videoUploadProgress: VideoProgressI;
}
export interface HudlDataUploadStateModel {
  hudlDataUpload: HudlDataUploadStateI[];
}

@State<HudlDataUploadStateModel>({
  name: 'HUDLDataUpload',
  defaults: {
    hudlDataUpload: [],
  },
})
@Injectable()
export class HudlDataUploadState {
  constructor() {}

  @Action(HudlDataUploadInitialData)
  dataUploadSOB(
    ctx: StateContext<HudlDataUploadStateModel>,
    { sob, season, payload }: HudlDataUploadInitialData
  ) {
    let state = ctx.getState().hudlDataUpload;
    state.push({
      sob: sob,
      season: season,
      week: payload.week,
      matchType: payload.match_type,
      gameNo: payload.game_number,
      videoUploadProgress: { isUploading: false, totalFiles: 0, filesUploaded: 0, percent: 0 },
    });

    ctx.patchState({
      hudlDataUpload: state,
    });
  }

  @Action(HudlVideoUploadProgress)
  hudlVideoUploadProgress(
    ctx: StateContext<HudlDataUploadStateModel>,
    { sobValue, payload }: HudlVideoUploadProgress
  ) {
    let state = ctx.getState().hudlDataUpload;
    state = state.map((s) => {
      if (s.sob.value == sobValue) s.videoUploadProgress = payload;
      return s;
    });

    ctx.patchState({
      hudlDataUpload: state,
    });
  }

  @Action(RemoveHudlUploadedData)
  removeHudlUploadedData(
    ctx: StateContext<HudlDataUploadStateModel>,
    { sobValue }: RemoveHudlUploadedData
  ) {
    let state = ctx.getState().hudlDataUpload;
    state = state.filter((s) => {
      if (s.sob.value != sobValue) return s;
    });

    ctx.patchState({
      hudlDataUpload: state,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<HudlDataUploadStateModel>) {
    ctx.setState({
      hudlDataUpload: [],
    });
  }
}
