import { LoginApiResponse } from '@shared/interfaces/user';
import { UserData } from '../../../../modules/user-profile/interfaces/user-profile.interface';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { email: string; password: string }) {}
}

export class LoginSuccess {
  static readonly type = '[Auth] Login Success';
  constructor(public payload: LoginApiResponse) {}
}

export class LoginFailure {
  static readonly type = '[Auth] Login Failure';
  constructor(public payload: string) {}
}

export class UpdateLoginUser {
  static readonly type = '[Auth] Update Login User';
  constructor(public payload: UserData) {}
}
