import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseCardComponent } from '@fuse/components/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'og-card',
  standalone: true,
  imports: [CommonModule, FuseCardComponent, MatIconModule, RouterLink, MatButtonModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  classes = 'absolute inset-0 flex flex-col min-w-0 overflow-y-auto';
  @Input() pageTitle = '';
  @Input() cardStyles = '';
  // @Input() actionButtons: any[];

  constructor(private cdr: ChangeDetectorRef) {
    window.onbeforeprint = () => {
      this.classes = 'inset-0 flex flex-col min-w-0 overflow-y-auto';
      this.cdr.detectChanges();
    };
    window.onafterprint = () => {
      this.classes = 'absolute inset-0 flex flex-col min-w-0 overflow-y-auto';
      this.cdr.detectChanges();
    };
  }
}
