import { ResetPasswordApiResponse, ResetPasswordPayload } from '@shared/interfaces/user';

export class ResetPassword {
  static readonly type = '[Auth] Reset Password';
  constructor(public payload: ResetPasswordPayload) {}
}

export class ResetPasswordSuccess {
  static readonly type = '[Auth] Reset Password Success';
  constructor(public payload: ResetPasswordApiResponse) {}
}

export class ResetPasswordFailure {
  static readonly type = '[Auth] Reset Password Failure';
  constructor(public payload: ResetPasswordApiResponse) {}
}
