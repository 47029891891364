import { Season } from '@shared/interfaces/season.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';

export class GetBoxOptions {
  static readonly type = '[boxOption] Get BoxOptions';
  constructor(
    public sobAlias: SOB['alias'],
    public seasonId: Season['id']
  ) {}
}

export class ResetBoxOptions {
  static readonly type = '[boxOption] Reset BoxOptions';
}
