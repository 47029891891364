import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PracticeNoApi } from '@shared/interfaces/game-practice.interface';

@Injectable({
  providedIn: 'root',
})
export class GamePracticeService {
  constructor(private http: HttpClient) {}

  getPracticeNo(
    seasonId: number,
    weekNo: number,
    playType: string,
    sob: string
  ): Observable<PracticeNoApi[]> {
    return this.http.get<PracticeNoApi[]>(
      `${environment.main_url}/Matches/match_game_no_search/${seasonId}/${weekNo}/${playType}/${sob}/`
    );
  }
}
