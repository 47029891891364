import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { AuthService } from 'app/core/auth/auth.service';
import { finalize, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ThemeSelectors } from '@NgXs/Theme/theme.state';
import { LoadingSelectors } from '@NgXs/loading/loading.selectors';
import { ForgotPassword } from '@NgXs/authentication/actions/forgot-password.action';
import { StopLoading } from '@NgXs/loading/loading.action';

@Component({
  selector: 'auth-forgot-password',
  templateUrl: './forgot-password.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  standalone: true,
  imports: [
    NgIf,
    FuseAlertComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
    AsyncPipe,
    NgOptimizedImage,
  ],
})
export class AuthForgotPasswordComponent implements OnInit {
  @ViewChild('forgotPasswordNgForm') forgotPasswordNgForm: NgForm;
  @Select(ThemeSelectors.currentTheme) currentTheme$: Observable<'dark' | 'light' | 'auto'>;
  @Select(LoadingSelectors.isLoading) isLoading$: Observable<boolean>;

  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };
  forgotPasswordForm: UntypedFormGroup;
  showAlert: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private store: Store
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Send the reset link
   */
  sendResetLink(): void {
    this.forgotPasswordForm.disable();

    // Hide the alert
    this.showAlert = false;
    let payload = { email: this.forgotPasswordForm.get('email').value };

    this.store
      .dispatch(new ForgotPassword(payload))
      .pipe(
        finalize(() => {
          this.forgotPasswordForm.enable();
          this.forgotPasswordNgForm.resetForm();
          this.showAlert = true;
        })
      )
      .subscribe({
        next: (state) => {
          const { isSuccess, message } = state.auth.forgotPassword;
          if (isSuccess) {
            this.alert = {
              type: 'success',
              message:
                message ||
                "Password reset sent! You'll receive an email if you are registered on our system.",
            };
          } else {
            this.alert = {
              type: 'error',
              message: message || 'Email not found!',
            };
          }
        },
        error: (error) => {
          console.log({ error });
        },
        complete: () => {
          this.store.dispatch(new StopLoading());
        },
      });
  }
}
