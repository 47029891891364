import { Season } from '@shared/interfaces/season.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';

export class GetMapReport {
  static readonly type = '[mapReport] Get MapReport';
  constructor(
    public sobAlias: SOB['alias'],
    public seasonId: Season['id']
  ) {}
}

// export class EditAATESettings {
//   static readonly type = '[aateSetting] Edit AATESettings';
//   constructor(public payload: AATESettingStateModel) {}
// }
