import { Injectable } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class QueryParamsService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  updateQueryParams(queryParams: {}, url = [], type: QueryParamsHandling = 'merge') {
    this.router.navigate(url, {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: type,
    });
  }

  removeQueryParams(queryParams: {}) {
    const currentUrl = this.router.url;
    const urlTree = this.router.parseUrl(currentUrl);
    urlTree.queryParams = { ...urlTree.queryParams, ...queryParams };
    for (const key in urlTree.queryParams) {
      if (
        Object.prototype.hasOwnProperty.call(urlTree.queryParams, key) &&
        !urlTree.queryParams[key]
      ) {
        delete urlTree.queryParams[key];
      }
    }
    this.router.navigateByUrl(urlTree.toString());
  }
}
