// auth.state.ts
import { Injectable, NgZone } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { Login, LoginFailure, LoginSuccess, UpdateLoginUser } from '../actions/login.action';
import {
  ChangePasswordApiResponse,
  ForgotPasswordApiResponse,
  LoginApiResponse,
  ResetPasswordApiResponse,
} from '@shared/interfaces/user';
import { AuthService } from '@auth/auth.service';
import { Observable, catchError, of, tap } from 'rxjs';
import {
  ForgotPassword,
  ForgotPasswordFailure,
  ForgotPasswordSuccess,
} from '@NgXs/authentication/actions/forgot-password.action';
import { HttpErrorResponse } from '@angular/common/http';
import { SignUp, SignUpFailure, SignUpSuccess } from '@NgXs/authentication/actions/signUp.action';
import { SignUpApiResponse } from '@shared/interfaces/signUp';
import { Router } from '@angular/router';
import { SetUserOnReload } from '@NgXs/authentication/actions/reload.actions';
import {
  ChangePassword,
  ChangePasswordFailure,
  ChangePasswordSuccess,
} from '@NgXs/authentication/actions/change-password.action';
import { SignOut } from '@NgXs/authentication/actions/signOut.actions';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearAllStates } from '../actions/clear-all-state.action';
import {
  ResetPassword,
  ResetPasswordFailure,
  ResetPasswordSuccess,
} from '@NgXs/authentication/actions/reset-password.actions';

export interface AuthStateModel {
  login: LoginApiResponse | null;
  isAuthenticated: boolean;
  error: Record<string, string[] | string>;
  forgotPassword: {
    message: string;
    isSuccess: boolean;
  };
  changePassword: {
    message: string;
    isSuccess: boolean;
  };
  resetPassword: {
    message: string;
    isSuccess: boolean;
  };
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    login: null,
    isAuthenticated: false,
    error: {},
    forgotPassword: {
      message: null,
      isSuccess: false,
    },
    changePassword: {
      message: null,
      isSuccess: false,
    },
    resetPassword: {
      message: null,
      isSuccess: false,
    },
  },
})
@UntilDestroy({ checkProperties: true })
@Injectable()
export class AuthState {
  storageMedium: Storage;
  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;

  constructor(
    private apiService: AuthService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.storageMedium$.subscribe((storageMedium: any) => {
      this.storageMedium = storageMedium;
    });
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.apiService.signIn(action.payload).pipe(
      tap((response: LoginApiResponse) => {
        // Handle successful login here
        this.storageMedium.setItem('login', JSON.stringify(response));
        ctx.dispatch(new LoginSuccess(response));
      }),
      catchError((error) => {
        // Handle login failure here
        ctx.dispatch(new LoginFailure(error));
        return of(error); // Return an observable with the error for further handling
      })
    );
  }

  @Action(LoginSuccess)
  loginSuccess(ctx: StateContext<AuthStateModel>, action: LoginSuccess) {
    ctx.patchState({
      login: action.payload,
      isAuthenticated: true,
      error: null,
    });
    return of(action);
  }

  @Action(LoginFailure)
  loginFailure(ctx: StateContext<AuthStateModel>, action: LoginFailure) {
    ctx.patchState({
      login: null,
      isAuthenticated: false,
      error: { signIn: action.payload },
    });
    return of(action);
  }

  @Action(SignUp)
  signup(ctx: StateContext<AuthStateModel>, action: SignUp) {
    return this.apiService.signUp(action.payload).pipe(
      tap((response: SignUpApiResponse) => {
        // Handle successful signup here
        ctx.dispatch(new SignUpSuccess(response));
      }),
      catchError((error) => {
        // Handle signup failure here
        ctx.dispatch(new SignUpFailure(error.error)); // Use error.error instead of error
        return of(error); // Return an observable with the error for further handling
      })
    );
  }

  @Action(SignUpSuccess)
  signupSuccess() {
    this.router.navigate(['/authentication/sign-in']).then();
  }

  @Action(SignUpFailure)
  signupFailure(ctx: StateContext<AuthStateModel>, action: LoginFailure) {
    ctx.patchState({
      error: { signUp: action.payload },
    });
  }

  @Action(ForgotPassword)
  forgotPassword(ctx: StateContext<AuthStateModel>, action: ForgotPassword) {
    return this.apiService.forgotPassword(action.payload).pipe(
      tap((response: ForgotPasswordApiResponse) => {
        ctx.dispatch(new ForgotPasswordSuccess(response));
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.error) {
          ctx.dispatch(new ForgotPasswordFailure(error.error)); // Dispatch ForgotPasswordFailure with error response
        } else {
          ctx.dispatch(new ForgotPasswordFailure(error));
        }
        return of(error);
      })
    );
  }

  @Action(ForgotPasswordSuccess)
  ForgotPasswordSuccess(ctx: StateContext<AuthStateModel>, action: ForgotPasswordSuccess) {
    ctx.patchState({
      forgotPassword: {
        isSuccess: true,
        message: action.payload.detail || 'Password reset sent!',
      },
    });
  }

  @Action(ForgotPasswordFailure)
  forgotPasswordFailure(ctx: StateContext<AuthStateModel>, action: ForgotPasswordFailure) {
    ctx.patchState({
      forgotPassword: {
        isSuccess: false,
        message: action.payload.detail || 'An error occurred while resetting the password.',
      },
    });
  }

  @Action(SetUserOnReload)
  setUserOnReload(ctx: StateContext<AuthStateModel>) {
    const user: LoginApiResponse = JSON.parse(this.storageMedium.getItem('login')) || null;
    ctx.patchState({
      login: user,
      isAuthenticated: !!user?.access, // Set isAuthenticated based on whether a user is present
    });
  }
  @Action(SignOut)
  signOut(ctx: StateContext<AuthStateModel>) {
    this.ngZone.run(() => {
      this.router.navigateByUrl('/authentication/sign-out').then();
      this.storageMedium.clear();
    });
  }

  @Action(ChangePassword)
  changePassword(ctx: StateContext<AuthStateModel>, action: ChangePassword) {
    return this.apiService.changePassword(action.payload).pipe(
      tap((response: ChangePasswordApiResponse) => {
        ctx.dispatch(new ChangePasswordSuccess(response));
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.error) {
          ctx.dispatch(new ChangePasswordFailure(error.error)); // Dispatch ForgotPasswordFailure with error response
        } else {
          ctx.dispatch(new ChangePasswordFailure(error));
        }
        return of(error);
      })
    );
  }

  @Action(ChangePasswordSuccess)
  changePasswordSuccess(ctx: StateContext<AuthStateModel>, action: ChangePasswordSuccess) {
    ctx.patchState({
      changePassword: {
        isSuccess: true,
        message: action.payload.detail || 'Password has been changed!',
      },
    });
  }

  @Action(ChangePasswordFailure)
  changePasswordFailure(ctx: StateContext<AuthStateModel>, action: ChangePasswordFailure) {
    ctx.patchState({
      changePassword: {
        isSuccess: false,
        message: action.payload.detail || 'An error occurred while changing the password.',
      },
    });
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<AuthStateModel>, action: ResetPassword) {
    return this.apiService.resetPassword(action.payload).pipe(
      tap((response: ResetPasswordApiResponse) => {
        ctx.dispatch(new ResetPasswordSuccess(response));
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.error) {
          ctx.dispatch(new ResetPasswordFailure(error.error)); // Dispatch ResetPasswordFailure with error response
        } else {
          ctx.dispatch(new ResetPasswordFailure(error));
        }
        return of(error);
      })
    );
  }

  @Action(ResetPasswordSuccess)
  resetPasswordSuccess(ctx: StateContext<AuthStateModel>, action: ResetPasswordSuccess) {
    ctx.patchState({
      resetPassword: {
        isSuccess: true,
        message: action.payload.detail || 'Password has been reset successfully!',
      },
    });
  }

  @Action(ResetPasswordFailure)
  resetPasswordFailure(ctx: StateContext<AuthStateModel>, action: ResetPasswordFailure) {
    const errorPayload = action.payload;
    let errorMessage = 'An error occurred while resetting the password.';

    if (typeof errorPayload === 'object' && errorPayload !== null) {
      const errorMessages = Object.keys(errorPayload)
        .map((key) => {
          const errorDetail = errorPayload[key];
          if (Array.isArray(errorDetail)) {
            return errorDetail[0];
          }
          return errorDetail;
        })
        .filter(Boolean);

      if (errorMessages.length > 0) {
        errorMessage = errorMessages.join(', ');
      }
    }

    ctx.patchState({
      resetPassword: {
        isSuccess: false,
        message: errorMessage,
      },
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<AuthStateModel>) {
    ctx.setState({
      changePassword: { isSuccess: false, message: '' },
      login: null,
      isAuthenticated: false,
      error: {},
      forgotPassword: {
        message: null,
        isSuccess: false,
      },
      resetPassword: {
        message: null,
        isSuccess: false,
      },
    });
  }

  @Action(UpdateLoginUser)
  updateLoginUser(ctx: StateContext<AuthStateModel>, action: UpdateLoginUser) {
    const state = ctx.getState();
    const storedAuthData = this.storageMedium.getItem('login');
    const loginData = JSON.parse(storedAuthData);
    loginData.user = {
      ...loginData.user,
      ...action.payload,
    };

    this.storageMedium.setItem('login', JSON.stringify(loginData));
    ctx.patchState({
      login: {
        ...state.login,
        user: loginData.user,
      },
    });
  }
}
