<mat-form-field [ngClass]="['w-full', fieldVariation]">
  <mat-select
    *ngIf="showNested"
    [(ngModel)]="selectedSessionSeason"
    (ngModelChange)="seasonChange($event)"
  >
    <mat-select-trigger>
      {{ selectedSessionSeason?.year }} {{ selectedSessionSeason?.name }}
    </mat-select-trigger>
    <mat-accordion class="custom-dropdown-season-select" #accordion>
      <div
        *ngFor="let year of formattedSeason | keyvalue: returnZero"
        class="mat-elevation-z2 mb-1"
      >
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> {{ year.key }}</mat-panel-title>
          </mat-expansion-panel-header>

          <mat-option *ngFor="let season of year.value" [value]="season">
            {{ season.name }}
          </mat-option>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </mat-select>

  <mat-select
    *ngIf="showDashed"
    [placeholder]="placeholder"
    [(ngModel)]="selectedSeason"
    (ngModelChange)="propagateChange($event.id ? $event.id : $event)"
  >
    <mat-option *ngIf="showDefaultAtSignup" [value]="seasonEnum.DefaultAtSignUp">
      Default at Sign-Up
    </mat-option>
    <mat-option *ngFor="let season of seasons" [value]="season">
      {{ season.year }} - {{ season.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
