<og-floating-labels></og-floating-labels>

<fuse-loading-bar></fuse-loading-bar>
<div *hasPermission></div>
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- club name -->
    <div class="p-4 pt-8">
      <div
        class="flex items-center justify-evenly text-center font-bold w-full text-[20px] tracking-[1px] cursor-pointer"
        (click)="home()"
      >
        <img
          alt="club-logo"
          class="h-[60px] w-[60px] mr-1 rounded-full object-contain"
          [src]="(userData$ | async)?.club_info?.logo ?? 'assets/images/logo/logo-neutral.png'"
        />
        {{ (userData$ | async)?.club_info?.name }}
      </div>
    </div>
    <!-- User -->
    <div class="flex flex-col items-center w-full px-2 pt-0">
      <div class="relative w-22 h-22">
        <ng-container *ngIf="(userData$ | async)?.coach?.avatar; else noLogo">
          <img
            alt="coach-avatar"
            class="w-full h-full rounded-full"
            [src]="(userData$ | async)?.coach?.avatar"
          />
        </ng-container>
        <ng-template #noLogo>
          <mat-icon class="icon-size-24" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </ng-template>
      </div>
      <div class="flex flex-col items-center justify-center w-full mt-3">
        <div
          class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium"
        >
          {{ (userData$ | async)?.first_name }}
          {{ (userData$ | async)?.last_name }}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary"
        >
          {{ (userData$ | async)?.email }}
        </div>
      </div>

      <div class="w-[93%] mt-[20px]">
        <og-select-season
          class="w-[12%]"
          [showNested]="true"
          fieldVariation="fuse-mat-dense"
        ></og-select-season>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<div class="flex flex-col flex-auto w-full min-w-0">
  <div
    class="fixed w-full flex flex-0 items-center h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
    [ngStyle]="{
      'max-width': !isScreenSmall && isSideMenuClosed ? 'calc(100% - 280px)' : null
    }"
  >
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>

    <og-select-sob *ngIf="!isMobileScreen"></og-select-sob>

    <div class="flex items-center ml-auto space-x-0.5 sm:space-x-2">
      <button
        mat-fab
        extended
        class="mr-3"
        [routerLink]="['/help-center']"
        queryParamsHandling="preserve"
      >
        Need Support
        <i class="ph ph-question-mark"></i>
      </button>

      <img
        src="assets/images/logo/trademark-white-bg.png"
        class="h-[32px] w-[auto] mr-3"
        alt="Optimum Grading Trademark"
      />

      <user [showAvatar]="true"></user>
    </div>
  </div>

  <div class="flex flex-col flex-auto mt-[67px]">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

<!--  <div-->
<!--    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"-->
<!--  >-->
<!--    <span class="font-medium text-secondary">Optimum Grading &copy; {{ currentYear }}</span>-->
<!--  </div>-->
</div>
