import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AuthSelectors } from '@NgXs/authentication/selectors/auth.selectors';
import { SetUserOnReload } from '@NgXs/authentication/actions/reload.actions';
import { Observable } from 'rxjs';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate, CanActivateChild {
  storageMedium: Storage;

  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;
  @Select(AuthSelectors.isAuthenticated) isAuthenticated$: Observable<boolean>;

  constructor(
    private store: Store,
    private router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.storageMedium$.subscribe((storageMedium: Storage) => {
      this.storageMedium = storageMedium;
    });
  }

  canActivate(): boolean {
    return this.checkAuthentication();
  }

  canActivateChild(): boolean {
    return this.checkAuthentication();
  }

  private checkAuthentication(): boolean {
    const isAuthenticated: boolean = !!JSON.parse(this.storageMedium.getItem('login'))?.access;
    if (isAuthenticated) {
      // User is authenticated, dispatch SetUserOnReload and prevent access
      this.store.dispatch(new SetUserOnReload());
      const redirectURL =
        this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/dashboard';
      this.router.navigateByUrl(redirectURL); // Redirect to the desired route
      return false;
    }

    return true; // User is not authenticated, allow access
  }
}
