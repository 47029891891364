import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';

export class LoadWeeksPlayers {
  static readonly type = '[PlayerComparison] Load Weeks Players';
  constructor(
    public pgId: PositionGroup['name'],
    public seasonId: Season['id'],
    public sob: SOB['value']
  ) {}
}
