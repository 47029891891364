import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

export interface StorageMediumStateModel {
  storageMedium: Storage;
}

@State<StorageMediumStateModel>({
  name: 'storageMedium',
  defaults: {
    storageMedium: localStorage,
  },
})
@Injectable()
export class StorageMediumState {}
