import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { finalize, Observable, Subject, takeUntil } from 'rxjs';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { SelectPositionGroupComponent } from '@shared/components/select-position-group/select-position-group.component';
import { Position } from 'app/modules/position/interfaces/position.interface';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { SeasonSelectors } from '@shared/states/season/season.selector';
import { SobSelectors } from 'app/core/layout/components/select-sob/state/sob.selector';
import { ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PositionGroupMtps, Skill } from 'app/modules/production/interfaces/production.interface';
import { StartLoading, StopLoading } from '@NgXs/loading/loading.action';
import { GetPgMtps, GetSkills } from 'app/modules/production/state/production.action';
import { LoadingSelectors } from '@NgXs/loading/loading.selectors';
import { PositionGridComponent } from '@shared/components/position-grid/position-grid.component';

@Component({
  selector: 'og-grade-sheet-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    SelectPositionGroupComponent,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    PositionGridComponent,
  ],
  templateUrl: './grade-sheet-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradeSheetDialogComponent implements OnInit, OnDestroy {
  @ViewChild('sheetCount') sheetCount: ElementRef;

  @Select(SeasonSelectors.selectedSeason) currentSeason$: Observable<Season | null>;
  @Select(SobSelectors.selectedSob) currentSob$: Observable<SOB | null>;
  @Select(LoadingSelectors.isLoading) isLoading$: Observable<boolean>;

  showError = true;
  selectedPG: PositionGroup;
  rowData: Position[] = [];
  productionList: string[] = [];
  private destroy$ = new Subject<void>();
  seasonId: Season['id'];
  currentSob: SOB;
  selectedPositions: Position[] = [];
  skillSet: string[] = [];
  numberOfSheets: number;
  selectedPositionNames: string[] = [];
  columnDefs = [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: 'left-aligned',
      cellStyle: {
        'justify-content': 'flex-start',
      },
      width: 50,
    },
    {
      field: 'name',
      headerName: 'Position',
      rowDrag: true,
      flex: 4,
      editable: true,
      suppressMovable: true,
      headerClass: 'left-aligned',
      cellStyle: {
        'justify-content': 'flex-start',
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 4,
      editable: true,
      suppressMovable: true,
      headerClass: 'left-aligned',
      cellStyle: {
        'justify-content': 'flex-start',
      },
    },
  ];
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  context: any;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<GradeSheetDialogComponent>
  ) {
    this.context = { parentComponent: this };
  }

  ngOnInit(): void {
    this.subscribeToObservables();
  }

  private subscribeToObservables(): void {
    this.currentSeason$.pipe(takeUntil(this.destroy$)).subscribe((season: Season) => {
      this.seasonId = season.id;
      this.rowData = [];
      this.selectedPG = null;
      this.cdr.detectChanges();
    });
    this.currentSob$.pipe(takeUntil(this.destroy$)).subscribe((sob: SOB) => {
      this.currentSob = sob;
      this.rowData = [];
      this.selectedPG = null;
      this.cdr.detectChanges();
    });
  }
  onGridReady(params?: GridReadyEvent) {
    if (params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridApi.sizeColumnsToFit();
    }
  }

  positionGroupChange(pg: PositionGroup) {
    this.selectedPG = pg;
    this.rowData = [...pg.positions];

    this.productionList = [];
    // Get Production List from MTPs
    if (pg && pg.name) {
      this.store.dispatch(new StartLoading());
      this.store
        .dispatch(new GetPgMtps(pg.name, this.seasonId, this.currentSob.value))
        .pipe(
          takeUntil(this.unsubscribeAll),
          finalize(() => this.store.dispatch(new StopLoading()))
        )
        .subscribe((res) => {
          let pgMtps = [
            ...res.production_data.positionGroupMtps.find(
              (pg: PositionGroupMtps) => pg.name == this.selectedPG.name
            )?.mtps,
          ];
          this.productionList = pgMtps.map((item) => item.mtp.mtp_type).sort();
          this.getSkills();
        });
    }
  }

  getSkills() {
    this.store.dispatch(new StartLoading());
    this.store
      .dispatch(new GetSkills(this.selectedPG.name, this.seasonId))
      .pipe(
        takeUntil(this.unsubscribeAll),
        finalize(() => this.store.dispatch(new StopLoading()))
      )
      .subscribe((res) => {
        let skills: Skill[] = [
          ...res.production_data.positionGroupMtps.find(
            (pg: PositionGroup) => pg.name == this.selectedPG.name
          )?.skills,
        ];
        this.skillSet = skills.map((skill: Skill) => skill.label);
        this.cdr.markForCheck();
      });
  }

  rowSelectionChanged() {
    const currentSelectedRows: Position[] = this.gridApi.getSelectedRows();
    // Maintain the order of selection and removal
    currentSelectedRows.forEach((row) => {
      if (!this.selectedPositions.some((pos) => pos.id === row.id)) {
        this.selectedPositions.push(row);
      }
    });
    this.selectedPositions = this.selectedPositions.filter((pos) =>
      currentSelectedRows.some((row) => row.id === pos.id)
    );
    this.selectedPositionNames = this.selectedPositions.map((pos) => pos.name);
  }

  getSheetCount(value: string): void {
    const count = parseInt(value, 10);
    if (!isNaN(count) && count > 0) {
      this.numberOfSheets = count;
    }
  }

  printGradeSheet() {
    this.dialogRef.close({
      positions: this.selectedPositionNames,
      skills: this.skillSet,
      productionList: this.productionList,
      positionGroup: this.selectedPG.pg_name,
      sheetCount: this.numberOfSheets,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
