import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import {
  GetInfiniteVideo,
  SetInfiniteVideoUrl,
  SetSelectedPlayIndex,
  SetTotalPlays,
} from './infinite-video.action';

export interface InfiniteVideoStateModel {
  infiniteVideo: {
    hlsUrl: string;
    currentPlayIndex: number;
    totalPlays: number;
  };
}

@State<InfiniteVideoStateModel>({
  name: 'infiniteVideo',
  defaults: {
    infiniteVideo: {
      hlsUrl: null,
      currentPlayIndex: null,
      totalPlays: null,
    },
  },
})
@Injectable()
export class InfiniteVideoState {
  constructor() {}

  @Action(GetInfiniteVideo)
  getInfiniteVideo({ getState }: StateContext<InfiniteVideoState>): Observable<any> {
    return of(getState());
  }

  @Action(SetInfiniteVideoUrl)
  setInfiniteVideoUrl(
    { getState, patchState }: StateContext<InfiniteVideoStateModel>,
    { hlsUrl }: SetInfiniteVideoUrl
  ) {
    patchState({
      infiniteVideo: {
        hlsUrl: hlsUrl,
        currentPlayIndex: getState().infiniteVideo.currentPlayIndex,
        totalPlays: getState().infiniteVideo.totalPlays,
      },
    });
  }

  @Action(SetSelectedPlayIndex)
  setInfiniteVideoSelectePlayIndex(
    { getState, patchState }: StateContext<InfiniteVideoStateModel>,
    { playIndex }: SetSelectedPlayIndex
  ) {
    patchState({
      infiniteVideo: {
        hlsUrl: getState().infiniteVideo.hlsUrl,
        currentPlayIndex: playIndex,
        totalPlays: getState().infiniteVideo.totalPlays,
      },
    });
  }

  @Action(SetTotalPlays)
  setTotalPlays(
    { getState, patchState }: StateContext<InfiniteVideoStateModel>,
    { totalPlays }: SetTotalPlays
  ) {
    patchState({
      infiniteVideo: {
        hlsUrl: getState().infiniteVideo.hlsUrl,
        currentPlayIndex: getState().infiniteVideo.currentPlayIndex,
        totalPlays: totalPlays,
      },
    });
  }

  @Action(ClearAllStates)
  clearInfiniteVideoState(ctx: StateContext<InfiniteVideoStateModel>) {
    ctx.setState({
      infiniteVideo: {
        hlsUrl: null,
        currentPlayIndex: null,
        totalPlays: null,
      },
    });
  }
}
