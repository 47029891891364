import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateSkillClassFilter,
  SkillClassFilter,
} from '@shared/interfaces/skill-class-filter.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SkillClassFilterService {
  constructor(private http: HttpClient) {}

  getSkillClasses(seasonId: number, sobValue: string): Observable<SkillClassFilter[]> {
    return this.http.get<SkillClassFilter[]>(
      `${environment.main_url}/skill-classes/?skill__season=${seasonId}&skill__pg__side_of__ball="${sobValue}"`
    );
  }

  getSkillClassessForMultipleSkills(
    skillIds: number[],
    season_id: number
  ): Observable<SkillClassFilter[]> {
    return this.http.get<SkillClassFilter[]>(
      `${environment.main_url}/skill-classes/?skill__in=${skillIds.join(',')}&season=${season_id}`
    );
  }

  getSkillClassById(skillClassId: SkillClassFilter['id']): Observable<SkillClassFilter[]> {
    return this.http.get<SkillClassFilter[]>(
      `${environment.main_url}/skill-classes/${skillClassId}`
    );
  }

  addSkillClass(payload: CreateSkillClassFilter): Observable<SkillClassFilter> {
    return this.http.post<SkillClassFilter>(`${environment.main_url}/skill-classes/`, payload);
  }

  updateSkillClass(
    skillClassId: number,
    payload: {
      text: CreateSkillClassFilter['text'];
    }
  ): Observable<SkillClassFilter> {
    return this.http.patch<SkillClassFilter>(
      `${environment.main_url}/skill-classes/${skillClassId}/`,
      payload
    );
  }

  deleteSkillClass(skillClassId: SkillClassFilter['id']) {
    return this.http.delete(`${environment.main_url}/skill-classes/${skillClassId}/`);
  }
}
