<ag-grid-angular
  class="ag-theme-alpine"
  style="height: 460px"
  [context]="context"
  [rowData]="players"
  [columnDefs]="columnDefs"
  [rowHeight]="rowHeight"
  [suppressScrollOnNewData]="true"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
