import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { PositionGroupSelectors } from '@shared/states/position-group/position-group.selectors';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectedPositionGroups } from '@shared/states/position-group/position-group.actions';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SeasonState } from '@shared/states/season/season.state';
import { SeasonSelectors } from '@shared/states/season/season.selector';
import { Season } from '@shared/interfaces/season.interface';
import { SobSelectors } from '../../../core/layout/components/select-sob/state/sob.selector';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';
import { Sob } from '../../../core/layout/components/select-sob/enums/sob.enum';

@Component({
  selector: 'og-multi-select-position-groups',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  templateUrl: './multi-select-position-groups.component.html',
  styleUrls: ['./multi-select-position-groups.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectPositionGroupsComponent implements OnInit {
  @Select(PositionGroupSelectors.positionGroups) positionGroups$: Observable<PositionGroup[]>;
  @Select(SeasonSelectors.selectedSeason) selectedSeason$: Observable<Season>;
  @Select(SobSelectors.selectedSob) currentSob$: Observable<SOB | null>;

  @Output() pgsChange = new EventEmitter();

  currentSob: SOB;
  positionGroups: PositionGroup[];
  selectedPGs: FormControl = new FormControl([]);
  initialSelectedPGs: PositionGroup[] = [];
  private destroy$ = new Subject<void>();
  isAllSelected = false; // New property to track 'Select All' state
  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.positionGroups$
      .pipe(takeUntil(this.destroy$))
      .subscribe((positionGroups: PositionGroup[]) => {
        this.positionGroups = positionGroups;
        this.isAllSelected = false;
        this.cdr.detectChanges();
      });

    this.selectedSeason$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (season) => {
        this.selectedPGs.reset([]);
        this.onMatSelectClosed();
        this.isAllSelected = false;
        this.cdr.detectChanges();
      },
    });
    this.currentSob$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (sob) => {
        this.currentSob = sob;
        this.selectedPGs.reset([]);
        this.onMatSelectClosed();
        this.isAllSelected = false;
        this.cdr.detectChanges();
      },
    });
  }
  onMatSelectOpened(): void {
    this.initialSelectedPGs = [...this.selectedPGs.value];
  }
  onMatSelectClosed(): void {
    this.pgsChange.emit(this.selectedPGs.value);
    this.store.dispatch(new SelectedPositionGroups(this.selectedPGs.value));
  }

  toggleAllSelection(): void {
    this.isAllSelected = !this.isAllSelected; // Toggle the state
    if (this.isAllSelected) {
      // If all selected, set the form control to all values
      this.selectedPGs.setValue(this.positionGroups);
      this.cdr.detectChanges();
    } else {
      // If deselected, clear the form control
      this.selectedPGs.setValue([]);
      this.cdr.detectChanges();
    }
  }
  ngOnDestroy(): void {
    this.selectedPGs.setValue([]);
    this.destroy$.next();
    this.destroy$.complete();
    this.cdr.detectChanges();
    this.store.dispatch(new SelectedPositionGroups([]));
  }

  checkForSelectAll() {
    const selectedCount = this.selectedPGs.value.length;
    const totalCount = this.positionGroups.length;

    this.isAllSelected = selectedCount === totalCount;
    this.cdr.detectChanges();
  }

  protected readonly Sob = Sob;
}
