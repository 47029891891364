import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OppData, Opponent } from '@shared/interfaces/opponent/opponent.interface';
import { environment } from 'environments/environment';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OpponentService {
  constructor(private http: HttpClient) {}

  addOpponent(requestBody: { name: string; season_id: number }[]) {
    return this.http.post<Opponent[]>(
      `${environment.main_url}/Opponent/opp_data_list/`,
      requestBody
    );
  }

  editOpponent(id: Opponent['id'], requestBody: { name: string }) {
    return this.http.patch<Opponent>(`${environment.main_url}/Opponent/${id}/`, requestBody);
  }

  deleteOpponent(id: Opponent['id']) {
    return this.http.delete(`${environment.main_url}/Opponent/${id}/`);
  }

  getOpponents(seasonId: number) {
    return this.http.get<Opponent[]>(`${environment.main_url}/Opponent/?season_id=${seasonId}`);
  }

  addSchedule(requestBody: any) {
    return this.http
      .post<OppData[]>(`${environment.main_url}/Week/`, requestBody)
      .pipe(map((schedule) => schedule[0]));
  }

  editSchedule(requestBody: any) {
    return this.http.patch<OppData>(`${environment.main_url}/Week/${requestBody.id}/`, requestBody);
  }

  deleteSchedule(id: number) {
    return this.http.delete(`${environment.main_url}/Week/${id}/`);
  }
}
