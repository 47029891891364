import { Season } from '@shared/interfaces/season.interface';
import { AATESettingStateModel } from './aate-setting.state';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';

export class GetAATESettings {
  static readonly type = '[aateSetting] Get AATESettings';
  constructor(
    public sobValue: SOB['value'],
    public seasonId: Season['id']
  ) {}
}

export class EditAATESettings {
  static readonly type = '[aateSetting] Edit AATESettings';
  constructor(public payload: AATESettingStateModel) {}
}
