import { Action, State, StateContext } from '@ngxs/store';
import { ConditionFilter, PlayFields } from '../../interfaces/infinite-athlete.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClearAllPlayFilters, GetPlayFilters, SetPlayFilters } from './play-filters.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface PlayFilterStateModel {
  filters: {
    situation: ConditionFilter[];
    play: ConditionFilter[];
    results: ConditionFilter[];
    players: ConditionFilter[];
    officials: ConditionFilter[];
    special_teams: ConditionFilter[];
    all_filters: ConditionFilter[];
  };
}

@State<PlayFilterStateModel>({
  name: 'playFilters',
  defaults: {
    filters: {
      situation: [],
      play: [],
      results: [],
      players: [],
      officials: [],
      special_teams: [],
      all_filters: [],
    },
  },
})
@Injectable()
export class PlayFilterState {
  constructor() {}

  @Action(GetPlayFilters)
  getPlayFields({ getState }: StateContext<PlayFields>): Observable<any> {
    return of(getState());
  }

  @Action(SetPlayFilters)
  setPlayFilters(
    { getState, patchState }: StateContext<PlayFilterStateModel>,
    { filterName, playFilters }: SetPlayFilters
  ) {
    const state = getState();
    state.filters[filterName] = playFilters;
    patchState(state);
  }

  @Action(ClearAllStates)
  @Action(ClearAllPlayFilters)
  clearAllFilters(ctx: StateContext<PlayFilterStateModel>) {
    ctx.setState({
      filters: {
        situation: [],
        play: [],
        results: [],
        players: [],
        officials: [],
        special_teams: [],
        all_filters: [],
      },
    });
  }
}
