export class LoadPracticeNumbers {
  static readonly type = '[GamePractice] Load Practice Numbers';

  constructor(
    public seasonId: number,
    public weekNo: number,
    public playType: string,
    public sob: string
  ) {}
}

export class SetSelectedGameType {
  static readonly type = '[GamePractice] Set Selected Game Type';

  constructor(
    public playType: string,
    public countNo: number,
    public gp_count: number,
    public qtr: number,
    public type: string
  ) {}
}

export class ResetSelectedGameType {
  static readonly type = '[GamePractice] ResetSelected Game Type';
}
