import { SOB } from '../interfaces/sob.interface';

export class SelectedSob {
  static readonly type = '[Sob] Selected Sob';
  constructor(public payload: SOB) {}
}

export class LoadSobs {
  static readonly type = '[Sob] Load Sobs';
  constructor(public sobValue: SOB['value']) {}
}
