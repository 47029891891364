import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, tap, iif, of } from 'rxjs';
import { EditAATESettings, GetAATESettings } from './aate-setting.action';
import { SystemSettingService } from '@shared/services/system-setting/system-setting.service';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface AATESettingStateModel {
  id: number;
  user: number;
  sob: string;
  series_seq: string;
  aate: {
    align: string;
    assign: string;
    effort: string;
    tech: string;
  };
  box: {
    box1: string;
    box2: string;
    box3: string;
    box4: string;
  };
  efficiency_down_settings: {
    id: number;
    value: number;
    user_setting: number;
    down: number;
    operator: string;
    report: string;
  }[];
  scouting_down_settings: Array<any>;
  scouting_fields: Array<any>;
  scouting_report_columns: object;
}

@State<AATESettingStateModel>({
  name: 'aateSetting',
  defaults: {
    id: null,
    user: null,
    sob: null,
    series_seq: null,
    aate: {
      align: null,
      assign: null,
      effort: null,
      tech: null,
    },
    box: {
      box1: null,
      box2: null,
      box3: null,
      box4: null,
    },
    efficiency_down_settings: null,
    scouting_down_settings: null,
    scouting_fields: null,
    scouting_report_columns: null,
  },
})
@Injectable()
export class AATESettingState {
  constructor(private settingService: SystemSettingService) {}

  @Action(GetAATESettings)
  getAATESettings(
    ctx: StateContext<AATESettingStateModel>,
    { sobValue, seasonId }: GetAATESettings
  ): Observable<any> {
    return this.settingService.getSettingsBySob(sobValue, seasonId).pipe(
      tap((newAateSettings) => {
        if (newAateSettings.length) ctx.patchState(newAateSettings[0]);
        else this.clearState(ctx);
      })
    );
  }

  @Action(EditAATESettings)
  editSeasonDescription(
    { patchState }: StateContext<AATESettingStateModel>,
    { payload }: EditAATESettings
  ) {
    if (payload) patchState(payload);
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<AATESettingStateModel>) {
    ctx.setState({
      id: null,
      user: null,
      sob: null,
      series_seq: null,
      aate: {
        align: null,
        assign: null,
        effort: null,
        tech: null,
      },
      box: {
        box1: null,
        box2: null,
        box3: null,
        box4: null,
      },
      efficiency_down_settings: null,
      scouting_down_settings: null,
      scouting_fields: null,
      scouting_report_columns: null,
    });
  }
}
