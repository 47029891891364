import { SignUpApiResponse } from '@shared/interfaces/signUp';

import { SignUpForm } from '@shared/interfaces/signUp';

export class SignUp {
  static readonly type = '[Auth] SignUp';
  constructor(public payload: SignUpForm) {}
}

export class SignUpSuccess {
  static readonly type = '[Auth] SignUp Success';
  constructor(public payload: SignUpApiResponse) {}
}

export class SignUpFailure {
  static readonly type = '[Auth] SignUp Failure';
  constructor(public payload: string) {}
}
