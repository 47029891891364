import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { OppData, Opponent } from '@shared/interfaces/opponent/opponent.interface';
import { OpponentService } from '../services/opponent.service';
import {
  AddOpponent,
  AddSchedule,
  DeleteOpponent,
  DeleteSchedule,
  EditOpponent,
  EditSchedule,
  GetOpponents,
} from './opponents.action';
import { catchError, map, of, tap } from 'rxjs';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface OpponentStateModel {
  allOpponents: Opponent[];
}

@State<OpponentStateModel>({
  name: 'opponents',
  defaults: {
    allOpponents: [],
  },
})
@Injectable()
export class OpponentState {
  constructor(private opponentService: OpponentService) {}

  @Action(AddOpponent)
  addOpponent(
    { patchState, getState }: StateContext<OpponentStateModel>,
    { payload }: AddOpponent
  ) {
    return this.opponentService
      .addOpponent([{ name: payload.name, season_id: payload.season_id }])
      .pipe(
        tap((opponent: any) => {
          patchState({ allOpponents: [...getState().allOpponents, opponent[0]] });
        })
      );
  }

  @Action(DeleteOpponent)
  deleteOpponent(
    { patchState, getState }: StateContext<OpponentStateModel>,
    { payload }: DeleteOpponent
  ) {
    return this.opponentService.deleteOpponent(payload.id).pipe(
      tap(() => {
        let allOpponents = getState().allOpponents.filter(
          (opponent: Opponent) => opponent.id != payload.id
        );
        patchState({ allOpponents });
      })
    );
  }

  @Action(EditOpponent)
  editOpponent(
    { patchState, getState }: StateContext<OpponentStateModel>,
    { payload }: EditOpponent
  ) {
    return this.opponentService.editOpponent(payload.id, { name: payload.name }).pipe(
      tap((opponent: Opponent) => {
        let allOpponents = getState().allOpponents;
        allOpponents = allOpponents.map((opp: Opponent) => {
          if (opp.id == opponent.id) return opponent;
          return opp;
        });

        patchState({ allOpponents });
      })
    );
  }

  @Action(GetOpponents)
  getOpponents({ patchState }: StateContext<OpponentStateModel>, { payload }: GetOpponents) {
    return this.opponentService.getOpponents(payload).pipe(
      tap((opponents: Opponent[]) => {
        patchState({
          allOpponents: opponents,
        });
        return opponents;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(AddSchedule)
  addSchedule(
    { patchState, getState }: StateContext<OpponentStateModel>,
    { payload }: AddSchedule
  ) {
    return this.opponentService.addSchedule(payload).pipe(
      tap((schedule: OppData) => {
        let allOpponents = getState().allOpponents;
        allOpponents = allOpponents.map((opp: Opponent) => {
          if (opp.id == schedule.opponent_id) {
            opp.opp_data.push(schedule);
          }
          return opp;
        });

        patchState({ allOpponents });
      })
    );
  }

  @Action(EditSchedule)
  editSchedule(
    { patchState, getState }: StateContext<OpponentStateModel>,
    { payload }: EditSchedule
  ) {
    return this.opponentService.editSchedule(payload).pipe(
      tap((schedule: OppData) => {
        let allOpponents = getState().allOpponents;
        allOpponents = allOpponents.map((opp: Opponent) => {
          opp.opp_data = opp.opp_data.map((od: OppData) => {
            if (schedule.id == od.id) return schedule;
            else return od;
          });
          return opp;
        });

        patchState({ allOpponents });
      })
    );
  }

  @Action(DeleteSchedule)
  deleteSchedule(
    { patchState, getState }: StateContext<OpponentStateModel>,
    { payload }: DeleteSchedule
  ) {
    return this.opponentService.deleteSchedule(payload.id).pipe(
      tap(() => {
        let allOpponents = getState().allOpponents;
        allOpponents = allOpponents.map((opp: Opponent) => {
          opp.opp_data = opp.opp_data.filter((od: OppData) => od.id != payload.id);
          return opp;
        });

        patchState({ allOpponents });
      })
    );
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<OpponentStateModel>) {
    ctx.setState({
      allOpponents: [],
    });
  }
}
