export enum Sob {
  ViewAll = 'view_pg_all',
  ViewOffense = 'view_pg_offense',
  ViewDefense = 'view_pg_defense',
  ViewSpecialTeams = 'view_pg_specialteams',

  O = 'Offense',
  Offense = 'Offense',
  OffenseValue = 'O',
  OffenseAlias = 'Offense',

  D = 'Defense',
  Defense = 'Defense',
  DefenseValue = 'D',
  DefenseAlias = 'Defense',

  ST = 'SpecialTeams',
  SpecialTeams = 'Special Teams',
  SpecialTeamsValue = 'ST',
  SpecialTeamsAlias = 'SpecialTeams',
}
