import { LoadWeeksPlayers } from './player-comparison-action';
import { Action, State, StateContext } from '@ngxs/store';
import { GetWeeksPlayersApi } from '../interfaces/player-comparison-interface';
import { PlayerComparisonService } from '../services/player-comparison.service';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';

export class PlayerComparisonStateModel {
  public weeksPlayers: GetWeeksPlayersApi;
}

@State<PlayerComparisonStateModel>({
  name: 'playerComparison',
  defaults: {
    weeksPlayers: null,
  },
})
@Injectable()
export class PlayerComparisonState {
  constructor(private playerComparisonService: PlayerComparisonService) {}

  @Action(LoadWeeksPlayers)
  loadWeeksPlayers(
    { patchState }: StateContext<PlayerComparisonStateModel>,
    { pgId, seasonId, sob }: LoadWeeksPlayers
  ) {
    return this.playerComparisonService.getGamesPracticesPlayers(pgId, seasonId, sob).pipe(
      tap((data) => {
        patchState({ weeksPlayers: data });
      })
    );
  }
}
