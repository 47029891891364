import { NgFor } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Permission } from 'app/core/permissions/enums/permissions.enum';
import { SOB } from './interfaces/sob.interface';
import { SelectedSob } from './state/sob.action';
import { Store } from '@ngxs/store';
import { QueryParamsService } from '@shared/services/queryParams/query-params.service';
import { filter, Subject } from 'rxjs';
import { SobSelectors } from './state/sob.selector';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SeasonSelectors } from '@shared/states/season/season.selector';
@Component({
  selector: 'og-select-sob',
  templateUrl: './select-sob.component.html',
  styleUrls: ['./select-sob.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, NgFor, MatButtonToggleModule],
})
export class SelectSobComponent implements OnInit, OnDestroy {
  Permission = Permission;
  selectedSob = '';
  sobs: SOB[] = [];
  selectedUrl = '';

  @Input() showAbbreviated = false;

  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private queryParamsService: QueryParamsService
  ) {}

  ngOnInit(): void {
    this.selectedSob = this.store.selectSnapshot(SobSelectors.selectedSob).value;
    this.sobs = this.store.selectSnapshot(SobSelectors.allSobs);

    let paramsSob = this.route.snapshot.queryParams['sob'];
    if (!paramsSob) {
      this.queryParamsService.updateQueryParams({
        sob: this.selectedSob,
        season: this.store.selectSnapshot(SeasonSelectors.selectedSeason).id,
      });
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.selectedUrl = event.urlAfterRedirects;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  sobChange(sob: SOB): void {
    this.selectedSob = sob.value;

    this.queryParamsService.updateQueryParams({ sob: this.selectedSob });
    this.store.dispatch(new SelectedSob(sob));
  }
}
