<h1 mat-dialog-title class="print:hidden">Grade Sheet</h1>

<mat-dialog-content class="mat-typography h-[65vh] print:hidden">
  <div
    class="w-full flex justify-center items-center lg:justify-start md:justify-start sm:justify-start xsm:justify-center lg:w-1/2 md:w-1/2 sm:w-1/2 xsm:w-full"
  >
    <og-select-position-group
      class="w-1/2 min-w-[230px]"
      (positionGroupChangeEvent)="positionGroupChange($event)"
    ></og-select-position-group>
    <mat-form-field class="w-1/2 min-w-[200px] ml-[10px] grade-sheet-count">
      <input
        #sheetCount
        matInput
        type="number"
        placeholder="Number Of Plays"
        min="1"
        (blur)="getSheetCount(sheetCount.value)"
        required
      />
    </mat-form-field>
  </div>

  <div class="mt-4" *ngIf="rowData.length">
    <div>
      <span
        class="inline-flex items-center rounded-md bg-gray-50 mb-2 px-2 py-1 text-base font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
      >
        Positions will be sorted in the order of selection.
      </span>
    </div>
    <og-position-grid
      [columnDefs]="columnDefs"
      [context]="context"
      [rowData]="rowData"
      [suppressMoveWhenRowDragging]="true"
      [suppressRowDrag]="true"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="rowSelectionChanged()"
    ></og-position-grid>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="print:hidden">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="printGradeSheet()"
    [disabled]="(isLoading$ | async) || !(selectedPositionNames.length && sheetCount.value)"
  >
    Print
  </button>
</mat-dialog-actions>
