import { ForgotPasswordApiResponse } from '@shared/interfaces/user';

export class ForgotPassword {
  static readonly type = '[Auth] Forgot Password ';
  constructor(public payload: { email: string }) {}
}

export class ForgotPasswordSuccess {
  static readonly type = '[Auth] Forgot Password Success';
  constructor(public payload: ForgotPasswordApiResponse) {}
}

export class ForgotPasswordFailure {
  static readonly type = '[Auth] Forgot Password Failure';
  constructor(public payload: ForgotPasswordApiResponse) {}
}
