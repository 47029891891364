import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import {
  AddSubsPlayersPayload,
  AddSubstitutionResponse,
  PatchSubsPayload,
  PlayerInfo,
  PlayerSubstitutionRowData,
  Substitution,
  SubstitutionFormPayload,
  SubstitutionPlayerPosition,
} from '../interfaces/player-substitution.interface';
import { environment } from '../../../../environments/environment';
import { Player } from '@shared/interfaces/player';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';
import { PositionGroup } from '@shared/interfaces/position-group.interface';

@Injectable({
  providedIn: 'root',
})
export class PlayerSubstitutionService {
  substitutionsList = [];

  constructor(private http: HttpClient) {}

  getSubstitutions(seasonId: Season['id'], sob: SOB['value']): Observable<Substitution[]> {
    return this.http
      .get<Substitution[]>(`${environment.main_url}/Substitution/?season=${seasonId}&sob=${sob}`)
      .pipe(
        tap((x: any) => {
          this.substitutionsList = x;
        })
      );
  }

  postSubstitution(payload: SubstitutionFormPayload): Observable<Substitution> {
    return this.http.post<Substitution>(`${environment.main_url}/Substitution/`, payload);
  }

  patchSubstitution(
    substitutionId: Substitution['id'],
    payload: SubstitutionFormPayload
  ): Observable<Substitution> {
    return this.http.patch<Substitution>(
      `${environment.main_url}/Substitution/${substitutionId}/`,
      payload
    );
  }

  deleteSubstitution(substitutionId: Substitution['id']) {
    return this.http.delete(`${environment.main_url}/Substitution/${substitutionId}/`);
  }

  patchSubstitutionPlayer(
    playerPosSubId: Substitution['id'],
    payload: PatchSubsPayload
  ): Observable<SubstitutionPlayerPosition> {
    return this.http.patch<SubstitutionPlayerPosition>(
      `${environment.main_url}/player-position-substitution/${playerPosSubId}/`,
      payload
    );
  }

  deleteSubstitutionPlayer(playerPosSubId: Substitution['id']) {
    return this.http.delete(
      `${environment.main_url}/player-position-substitution/${playerPosSubId}/`
    );
  }

  getPlayersByPositionGroup(
    positionGroup: PositionGroup['name'],
    seasonId: Season['id']
  ): Observable<PlayerInfo[]> {
    return this.http
      .get<
        Player[]
      >(`${environment.main_url}/Player/?playerposition__position_group__in=${positionGroup}&playerposition__season=${seasonId}`)
      .pipe(
        map((players) => {
          return players.map((player) => {
            const playerSeasonDetail = player.season_wise_player_detail[seasonId];
            const jersey_no = playerSeasonDetail ? playerSeasonDetail['jersey'] : null;

            return {
              avatar: player.avatar,
              id: player.id,
              jersey_no,
              firstname: player.firstname,
              lastname: player.lastname,
            };
          });
        })
      );
  }

  addSubstitutionPlayers(
    substitutionId: Substitution['id'],
    payload: AddSubsPlayersPayload
  ): Observable<AddSubstitutionResponse> {
    return this.http.patch<AddSubstitutionResponse>(
      `${environment.main_url}/Substitution/${substitutionId}/`,
      payload
    );
  }

  substitutionPlayersOrdering(payload: { id: number; order: number }[]): Observable<string> {
    return this.http.post<string>(
      `${environment.main_url}/player-position-substitution/substitution-player-order/`,
      payload
    );
  }

  getPlayersByPositionGroupCellEditor(
    positionGroup: PositionGroup['name'],
    seasonId: Season['id']
  ): Observable<PlayerSubstitutionRowData['player'][]> {
    return this.http
      .get<
        Player[]
      >(`${environment.main_url}/Player/?playerposition__position_group__in=${positionGroup}&playerposition__season=${seasonId}`)
      .pipe(
        map((players) => {
          return players.map((player) => {
            const playerSeasonDetail = player.season_wise_player_detail[seasonId];
            const jersey = playerSeasonDetail ? playerSeasonDetail['jersey'] : null;

            return {
              id: player.id,
              jersey,
              firstname: player.firstname,
              lastname: player.lastname,
              name: `#${jersey} ${player.firstname} ${player.lastname}`,
            };
          });
        })
      );
  }
}
