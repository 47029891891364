<!doctype html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <style>
      .container {
        font-family: serif;
        width: 90%;
        margin: 0 auto;
      }
      .title {
        font-size: 2rem;
        font-family: serif;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .para {
        color: #000000;
        opacity: 1;
        font-size: 18px;
        word-spacing: 3px;
        line-height: 22px;
      }

      h3 {
        font-weight: 600;
        font-size: 20px;
        margin: 25px 0;
      }
    </style>

    <div class="container">
      <h2 class="title">SOFTWARE SUBSCRIPTION AGREEMENT</h2>
      <p class="para">
        This Software Subscription Agreement (“SSA”) by and between Optimum Grading LLC, an Illinois
        limited liability company (“Optimum”), and the customer identified at the end of the SSA
        (“Customer”), governs the provision of any Subscriptions, Services, and other information
        provided by the parties. This SSA and any exhibits or documents separately executed by the
        parties are collectively referred to as the “Agreement.” This Agreement will be effective on
        the date of Customer’s signature on this document (the “Effective Date”).
      </p>

      <h3>1. &nbsp;&nbsp;&nbsp;&nbsp; <u> Definitions.</u></h3>
      <p class="para">
        1.1 &nbsp; “<u>Activation Date</u>” means the date on which Customer is provided access to
        the Subscription.
      </p>
      <p class="para">
        1.2 &nbsp; “<u>Optimum Technology</u>” means Optimum’s proprietary software, technology,
        frameworks, platforms, methodologies, facilitation guides, questionnaires, techniques,
        general purpose consulting and related know-how, logic, coherence and methods of operation
        of systems, user interfaces, screen designs, presentation materials, and best-practices
        documentation, including any enhancements, modifications or derivatives thereof, which are
        provided to Customer. Subscriptions, maintenance, and Services each individually and
        collectively include elements of Optimum Technology.
      </p>
      <p class="para">
        1.3 &nbsp; “<u>Customer Data</u>” means all data, information and materials provided by
        Customer for use with the Optimum Technology.
      </p>
      <p class="para">
        1.4 &nbsp; “<u>Subscription</u>” means one or more subscription(s) to Optimum Technology,
        including, related Support that may be provided by Optimum from time to time for the
        Subscription.
      </p>
      <p class="para">
        1.5 &nbsp; “<u>Named User(s)</u>” means number of identified and authorized persons in
        addition to Customer who are given password-protected access to the Subscription. The number
        of Named Users will based on the Subscription selected.
      </p>

      <h3>2. &nbsp;&nbsp;&nbsp;&nbsp; <u> Services Provided. </u></h3>
      <p class="para">
        2.1 &nbsp; <u>Subscription Access.</u> Subject to the terms and conditions of this
        Agreement, Optimum will make the Subscription available to Customer and Named Users over the
        internet for their use (“Services”).
      </p>
      <p class="para">
        2.2 &nbsp; <u>Security.</u> Optimum will operate an information security program utilizing
        industry standard policies and technologies to protect Customer Data from unauthorized
        disclosure or access. In addition to the Customer’s responsibilities outlined elsewhere in
        the Agreement, Customer shall take commercially reasonable security precautions to prevent
        unauthorized or fraudulent use of Optimum Technology by Customer and the Named Users with
        access to the Subscription.
      </p>
      <p class="para">
        2.3 &nbsp; <u>Support.</u> During the Term, Optimum will provide remote email support to
        Customer and Named Users. Such support consists solely of assistance with usage questions or
        troubleshooting bugs related to the Subscription supplied by Optimum (“Support”). Support
        will be provided remotely by Optimum.
      </p>
      <p class="para">
        2.4 &nbsp; <u>Services.</u> Customer acknowledges that the provision of Services by Optimum
        is dependent on Customer providing Customer Data and relevant information in connection with
        those Services. Customer further acknowledges that any delays in response, feedback, or
        access could result in sub-optimal results for Customer.
      </p>

      <h3>3. &nbsp;&nbsp;&nbsp;&nbsp; <u> Fees and Payment Terms. </u></h3>
      <p class="para">
        3.1 &nbsp; <u>Fees.</u> All Customer fees and other charges are due upon execution of this
        Agreement. All payments must be submitted in U.S. Dollars. Fees for any Renewal Term may be
        increased by Optimum.
      </p>
      <p class="para">
        3.2 &nbsp; <u>Late Payment.</u> For any credit card payment that either fails to process or
        is charged back without Optimum’s consent, Customer will be required to pay interest at the
        rate of one and onehalf percent (1.5%) per month (prorated for partial periods) or at the
        maximum rate permitted by law, whichever is less. In addition to other rights and remedies
        available to Optimum hereunder together with cost of collection (including reasonable legal
        fees), Optimum may suspend access to Subscription and stop providing Services if Customer
        does not correct any delinquent amounts within three (3) days of written notice of
        nonpayment. Any previously suspended Subscription and/or Services will be promptly restored
        following Optimum’s receipt of all delinquent amounts. Suspension under this Section 3.2
        will not constitute a termination of this Agreement, nor will it relieve Customer of
        obligations or liabilities under this Agreement.
      </p>

      <h3>4. &nbsp;&nbsp;&nbsp;&nbsp; <u> Customer Obligations. </u></h3>
      <p class="para">
        4.1 &nbsp; <u>Customer Control.</u> Customer will be solely responsible for administering
        and monitoring the Named Users within the Subscription. Optimum is not responsible for any
        damages resulting from Customer’s or Names User’s failure to manage the confidentiality of
        Customer’s login ID and passwords and Customer is responsible for any actions arising out of
        use or misuse of Customer’s login IDs.
      </p>
      <p class="para">
        4.2 &nbsp; <u>Prohibited Uses and Customer Restrictions.</u> The Subscription and Services
        must be used solely by Customer and Named Users. Customer must not permit a competitor of
        Optimum to access the Optimum Technology, except with Optimum’s prior written consent.
        Customer must not: (a) send or store material containing software viruses, worms, Trojan
        horses or other harmful computer code, files, scripts, agents or programs in, to or from the
        Optimum Technology; (b) interfere with or disrupt the integrity or performance of the
        Optimum Technology, or the data contained therein; (c) use the Optimum Technology in a
        manner inconsistent with its applicable documentation; or (d) attempt to gain unauthorized
        access to the Optimum Technology or related systems or networks.
      </p>

      <h3>
        5. &nbsp;&nbsp;&nbsp;&nbsp;
        <u>Ownership, Rights & Confidential Information. </u>
      </h3>
      <p class="para">
        5.1 &nbsp; <u>Customer Data Ownership and License.</u> As between Customer and Optimum, and
        subject to the rights of Optimum set forth below, Customer owns all right, title and
        interest in and to all Customer Data and all Customer-provided names, images, sketches,
        artwork, and logos (“Customer Content”). Customer has sole responsibility for the accuracy,
        quality and right to use of all Customer Data. Customer grants to Optimum and its suppliers
        a non-exclusive, fully paidup license to use, reproduce, store, modify and publicly display
        Customer Data to the extent necessary for Optimum to perform its obligations under this
        Agreement.
      </p>
      <p class="para">
        5.2 &nbsp; <u> Results.</u> Customer acknowledges and agrees that Optimum may (a) anonymize
        and aggregate benchmarking results of Customer use of the Optimum Technology with results of
        other customers’ use (collectively “Results”); and (b) use and disclose the Results for any
        purpose.
      </p>
      <p class="para">
        5.3 &nbsp; <u>Optimum Ownership.</u> As between Customer and Optimum, Optimum and/or its
        licensors are the sole owners of all right, title and interest in and to the Optimum
        Technology. If Customer provides any feedback, comments, suggestions, ideas, requests or
        recommendations for modifications or improvements to the Optimum Technology (“Feedback”),
        Customer hereby assigns and agrees to assign all right, title and interest in any such
        Feedback to Optimum to be used for any purpose. All rights not expressly granted to Customer
        hereunder are reserved by Optimum and its licensors.
      </p>
      <p class="para">
        5.4 &nbsp; <u>Optimum Technology License.</u> Subject to payment in full to Optimum, Optimum
        grants Customer a royalty free, non-exclusive, non-transferable, time-bounded license to use
        the Optimum Technology as included in Subscriptions and Services. For use in connection with
        Subscriptions and Services, the term of Customer’s permitted use will be the duration of the
        Subscription.
      </p>

      <h3>
        6. &nbsp;&nbsp;&nbsp;&nbsp;
        <u>Disclaimer of Warranties.</u>
      </h3>
      <p class="para">
        OPTIMUM MAKES NO WARRANTIES, EXPRESS OR IMPLIED, INCLUDING EXPRESS OR IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. Optimum does not
        warrant that the Subscription or the data stored through the use of the Subscription are not
        susceptible to intrusion, attack or computer virus infection.
      </p>

      <h3>
        7. &nbsp;&nbsp;&nbsp;&nbsp;
        <u>Limitation Of Liability and Damages. </u>
      </h3>
      <p class="para">
        7.1 &nbsp; <u>Limitation of Liability.</u> IN NO EVENT WILL OPTIMUM BE LIABLE UNDER ANY
        THEORY OF LIABILITY, FOR DAMAGES WHICH, IN THE AGGREGATE, EXCEED THE AMOUNT OF THE FEES PAID
        BY CUSTOMER FOR THE SUBSCRIPTION AND SERVICES GIVING RISE TO SUCH DAMAGES IN THE ONE (1)
        YEAR PERIOD PRIOR TO THE CLAIM.
      </p>
      <p class="para">
        7.2 &nbsp; <u>Limitation of Damages. </u>IN NO EVENT WILL OPTIMUM, OPTIMUM’S LICENSORS OR
        CONSULTANTS BE LIABLE, FOR ANY SPECIAL, INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE, OR
        CONSEQUENTIAL DAMAGES EVEN IF NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGE, AND
        NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY.
      </p>

      <h3>
        8. &nbsp;&nbsp;&nbsp;&nbsp;
        <u>Term And Termination. </u>
      </h3>
      <p class="para">
        8.1 &nbsp; <u>Term.</u> Unless terminated earlier in accordance with this Section 9, the
        initial term of this Agreement is 1 year beginning on the Effective Date (“Initial Term”).
        At the end of the Initial Term, this Agreement will terminate unless it is renewed (“Renewal
        Term”) at least thirty (30) days before expiration of the Initial Term or the then-current
        Renewal Term. The terms Initial Term and Renewal Term will be collectively referred to in
        this Agreement as the “Term”. Optimum will make each Subscription available to Customer from
        the period commencing on the Activation Date applicable to the Subscription in question and
        through the Term of the Agreement.
      </p>
      <p class="para">
        8.2 &nbsp; <u>Effect of Termination.</u> Upon termination of this Agreement or termination
        of a Subscription or Service, as the case may be, Optimum may immediately cease providing
        any such terminated Subscription or Service and Customer’s rights to use the affected
        Optimum Technology will cease. Upon termination of this Agreement, Optimum will have no
        further obligation or liability hereunder and all fees due (if any) under the Agreement will
        become due and payable to Optimum immediately upon such termination. The provisions of
        Sections 1, 3, 4, 5, 6.1, 6.2, 8, and 9 will survive termination or expiration of the
        Agreement.
      </p>

      <h3>
        9. &nbsp;&nbsp;&nbsp;&nbsp;
        <u>General.</u>
      </h3>
      <p class="para">
        9.1 &nbsp; <u>Entire Agreement.</u> This Agreement constitutes the entire agreement between
        the parties relating to the subject matter hereof and supersedes all previous agreements or
        representations, whether oral or written. This Agreement may not be modified or amended,
        except in writing signed by an authorized representative of each party.
      </p>
      <p class="para">
        9.2 &nbsp; <u>Counterparts.</u> This Agreement may be executed in counterparts, which
        together form one legal instrument.
      </p>
      <p class="para">
        9.3 &nbsp; <u>Publicity.</u> Optimum may identify Customer as a customer of Optimum for
        purposes or marketing the player or Optimum’s business.
      </p>
      <p class="para">
        9.4 &nbsp; <u>Assignment.</u> Customer may not assign this Agreement or otherwise transfer
        any rights hereunder whether by operation of law, change of control, or in any other manner,
        without the prior written consent of Optimum. Any assignment or transfer in violation of
        this Section will be null and void.
      </p>
      <p class="para">
        9.5 &nbsp; <u>Choice of Law.</u> This agreement will be governed and interpreted in
        accordance with the laws of the state of Illinois without regard to the conflicts of law
        provisions of any state or jurisdiction. Any action will be brought in the state or federal
        courts located in Champaign, Illinois.
      </p>
      <p class="para">
        9.6 &nbsp; <u>Force Majeure.</u> Except for Customer’s payment obligations, neither party
        will be liable to the other party for any delay or failure of Optimum to perform its
        obligations hereunder if such delay or failure arises from any cause or causes beyond the
        reasonable control of such other party. Such causes include, but are not limited to, acts of
        God, floods, fires, loss of electricity or other utilities, pandemic, epidemic, or delays by
        Customer in providing required resources or support or performing any other requirements
        hereunder.
      </p>
      <p class="para">
        9.7 &nbsp; <u>Independent Contractor.</u> Optimum is an independent contractor and nothing
        in this Agreement will be deemed to make Optimum an agent, employee, partner or joint
        venture of Customer. Optimum will have no authority to bind, commit, or otherwise obligate
        Customer in any manner whatsoever.
      </p>
      <p class="para">
        9.8 &nbsp; <u>No Waiver.</u> No failure or delay in enforcing any right or exercising any
        remedy will be deemed a waiver of any right or remedy.
      </p>
    </div>
  </body>
</html>
