import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Season } from '@shared/interfaces/season.interface';
import { environment } from 'environments/environment';
import { tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SeasonService {
  constructor(private http: HttpClient) {}

  getSeasons() {
    return this.http.get<Season[]>(environment.main_url + `/AddSeason/`).pipe(
      tap((seasons: any) => {
        return seasons.sort((a, b) => b.year - a.year);
      })
    );
  }

  addSeason(requestBody) {
    return this.http.post(`${environment.main_url}/AddSeason/create_season/`, requestBody);
  }

  setDefaultSeason(requestBody) {
    return this.http.patch(`${environment.main_url}/AddSeason/default_season_update/`, requestBody);
  }

  editSeasonDesc(id, requestBody) {
    return this.http.patch(`${environment.main_url}/AddSeason/${id}/`, requestBody);
  }

  deleteSeason(id) {
    return this.http.delete(`${environment.main_url}/AddSeason/${id}/`);
  }
}
