import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map, Observable } from 'rxjs';
import { PositionGroup } from '../../interfaces/position-group.interface';
import { Injectable } from '@angular/core';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';
import { Season } from '@shared/interfaces/season.interface';

@Injectable({ providedIn: 'root' })
export class PositionGroupService {
  constructor(private http: HttpClient) {}

  getPositionGroup(sob: SOB['value'], seasonId: Season['id']): Observable<PositionGroup[]> {
    return this.http.get<PositionGroup[]>(
      `${environment.main_url}/PositionGroup/?side_of_ball=${sob}&season=${seasonId}`
    );
  }

  getAllPositionGroups(seasonId: Season['id']): Observable<PositionGroup[]> {
    return this.http.get<PositionGroup[]>(
      `${environment.main_url}/PositionGroup/?season=${seasonId}`
    );
  }

  getAllPositionGroupsWithoutSeasons(): Observable<PositionGroup[]> {
    return this.http.get<PositionGroup[]>(
      environment.main_url + `/PositionGroup/?side_of_ball__in=O,D,ST`
    );
  }
}
