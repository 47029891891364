import { Injectable } from '@angular/core';
import { Permissions } from './permissions.types';
import { User } from '@shared/interfaces/user';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class PermissionsService {
  user!: User;

  // @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;

  constructor() {
    // this.storageMedium$.subscribe((storageMedium: Storage) => {
    //   if (storageMedium) this.user = JSON.parse(storageMedium.getItem('login'))?.user;
    // });
  }

  get Permissions(): Permissions['permissions'] {
    this.user = JSON.parse(localStorage.getItem('login'))?.user;
    if (this.user && this.user.group_permissions && this.user.group_permissions.length)
      return this.user.group_permissions;

    return [];
  }

  get UserGroupPermissions(): Permissions['user_group'] {
    this.user = JSON.parse(localStorage.getItem('login'))?.user;
    if (this.user && this.user.group_list && this.user.group_list.length)
      return this.user.group_list;

    return [];
  }

  get UserPermissionsOnly(): Permissions['user_permissions'] {
    this.user = JSON.parse(localStorage.getItem('login'))?.user;
    if (this.user && this.user.user_permissions && this.user.user_permissions.length)
      return this.user.user_permissions;

    return [];
  }
}
