<div class="w-full h-full absolute drag-boundary">
  <div
    *ngFor="let task of backgroundTasks; let isFirst = first"
    class="float-label"
    cdkDrag
    cdkDragBoundary=".drag-boundary"
    style="transform: translate3d(1610px, 171px, 0px)"
    [style.margin-top]="!isFirst ? '45px' : null"
  >
    <div class="float-label-sob" [style.color]="getColor('Offense')">Offense</div>

    <div class="float-label-text">Upload in progress...</div>

    <div class="progres">
      <div class="w-full h-[25px] rounded-full progress relative">
        <span
          class="absolute left-[49%] font-medium text-base"
          [ngClass]="65 > 53 ? 'text-white' : 'text-black'"
          >{{ 65 }} %</span
        >
        <div
          class="rounded-full progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          [style.width.%]="65"
        ></div>
      </div>
    </div>
  </div>
</div>
