import { Selector } from '@ngxs/store';
import { SeasonState, SeasonStateModel } from './season.state';

export class SeasonSelectors {
  @Selector([SeasonState])
  static selectedSeason(state: SeasonStateModel): SeasonStateModel['currentSeason'] {
    return state.currentSeason;
  }

  @Selector([SeasonState])
  static getAllSeasons(state: SeasonStateModel): SeasonStateModel['allSeasons'] | null {
    return state.allSeasons.sort((a, b) => b.year - a.year);
  }

  @Selector([SeasonState])
  static allSelectedSeasonsForDeletion(
    state: SeasonStateModel
  ): SeasonStateModel['allSelectedSeasonForDeletion'] {
    return state.allSelectedSeasonForDeletion;
  }
}
