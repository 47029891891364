import { Injectable } from '@angular/core';
import { ErrorToast } from '@shared/components/toast/error.toast';
import { InfoToast } from '@shared/components/toast/info.toast';
import { SuccessToast } from '@shared/components/toast/success.toast';
import { WarningToast } from '@shared/components/toast/warning.toast';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  options: GlobalConfig;
  lastInserted: number[] = [];

  constructor(private toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  success(title: string, message = '') {
    const options = { ...this.options };
    options.toastComponent = SuccessToast;
    options.toastClass = 'successtoast';

    this.toastr.show(message, title, options);
  }

  info(title: string, message = '') {
    const options = { ...this.options };
    options.toastComponent = InfoToast;
    options.toastClass = 'infotoast';

    this.toastr.show(message, title, options);
  }

  warning(title: string, message = '') {
    const options = { ...this.options };
    options.toastComponent = WarningToast;
    options.toastClass = 'warningtoast';

    //incase if you are increasing the timeout of the toastr, also increase the delay time in settimeout in errortoast component.
    // this has been done as a workaround to remove toast if triggered from the token.interceptor.ts, beacuse toasts triggering from that component was not automatically getting removal and required changedetection to run for their removal.
    this.toastr.show(message, title, options);
  }

  error(title: string, message = '') {
    const options = { ...this.options };
    options.toastComponent = ErrorToast;
    options.toastClass = 'errortoast';

    //incase if you are increasing the timeout of the toastr, also increase the delay time in settimeout in errortoast component.
    // this has been done as a workaround to remove toast if triggered from the token.interceptor.ts, beacuse toasts triggering from that component was not automatically getting removal and required changedetection to run for their removal.
    this.toastr.show(message, title, options);
  }
}
