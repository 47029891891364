import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetUserOnReload } from '@NgXs/authentication/actions/reload.actions';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { LoadSobs } from 'app/core/layout/components/select-sob/state/sob.action';
import {
  GetAllPositionGroups,
  GetPositionGroups,
} from '@shared/states/position-group/position-group.actions';
import { SobSelectors } from 'app/core/layout/components/select-sob/state/sob.selector';
import { GetSeasons } from '@shared/states/season/season.action';
import { SeasonSelectors } from '@shared/states/season/season.selector';

@Injectable({
  providedIn: 'root',
})
export class AppResolver implements Resolve<any> {
  constructor(private store: Store) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return forkJoin([
      this.store.dispatch(new SetUserOnReload()),

      forkJoin([
        this.store.dispatch(new GetSeasons()),
        this.store.dispatch(new LoadSobs(route.queryParams['sob'])),
      ]).pipe(
        switchMap(() => {
          return forkJoin([
            this.store.dispatch(
              new GetAllPositionGroups(
                this.store.selectSnapshot(SeasonSelectors.selectedSeason)?.id
              )
            ),
            this.store.dispatch(
              new GetPositionGroups(
                this.store.selectSnapshot(SobSelectors.selectedSob)?.value,
                this.store.selectSnapshot(SeasonSelectors.selectedSeason)?.id
              )
            ),
          ]);
        })
      ),
    ]);
  }
}
