import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { GradingData, GradingMatch } from '../interfaces/grading.interface';
import { PlayerMatchI } from 'app/modules/show-matches/interfaces/show-matches.interface';
import { Sob } from 'app/core/layout/components/select-sob/enums/sob.enum';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { Select, Store } from '@ngxs/store';
import { ResetGradingData, UpdateGradingMatch } from '../state/grading.actions';
import { ProductionService } from 'app/modules/production/services/production.service';
import { PositionGroupSelectors } from '@shared/states/position-group/position-group.selectors';
import { CommentService } from '@shared/services/comment/comment.service';
import { PositionGroupMtps } from 'app/modules/production/interfaces/production.interface';
import { Comment } from '@shared/interfaces/comment.interface';
import { Season } from '@shared/interfaces/season.interface';
import { Player } from '@shared/interfaces/player';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import { GetPositionGroups } from '@shared/states/position-group/position-group.actions';

@Injectable({ providedIn: 'root' })
export class GradingService {
  storageMedium: Storage;

  isCommentBoxHovered: boolean = false;
  isCommentFocused: boolean = false;

  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;

  constructor(
    private store: Store,
    private http: HttpClient,
    private commentService: CommentService,
    private productionService: ProductionService
  ) {
    this.storageMedium$.subscribe((storageMedium: any) => {
      this.storageMedium = storageMedium;
    });
  }

  getGradingMatch(matchId: GradingMatch['id']): Observable<GradingMatch> {
    return this.http.get<GradingMatch>(`${environment.main_url}/Matches/${matchId}/`);
  }

  getGradingData(match: GradingMatch, playerId: PlayerMatchI['id']): Observable<GradingData[]> {
    let sobValue = match.role.length
      ? match.role != Sob.SpecialTeamsAlias
        ? match.role[0].toUpperCase()
        : Sob.SpecialTeamsValue
      : Sob.OffenseValue;

    let url = `${environment.main_url}/PlayerMatch/?match_id=${match.id}`;
    if (playerId) url += `&player_id=${playerId}`;

    let visiblePgs: PositionGroup['name'][] = JSON.parse(localStorage.getItem('visiblePgs'));
    if (visiblePgs && visiblePgs.length)
      url += `&visible_pgs=${visiblePgs.toString()}&position__postion_group__in=${visiblePgs.toString()}`;

    // let visiblePlayers: number[] = JSON.parse(localStorage.getItem('visiblePlayers'));
    // if (visiblePlayers && visiblePlayers.length)
    //   url += `&visible_players=${visiblePlayers.toString()}`;

    return this.http.get<PlayerMatchI[]>(url).pipe(
      switchMap((players: PlayerMatchI[]) => {
        if (!players.length) {
          this.store.dispatch(new ResetGradingData());
          return of([]);
        } else {
          return forkJoin([
            this.productionService.getMtpsByPositionGroups(
              match.season_id.toString(),
              sobValue,
              visiblePgs
            ),
            this.commentService.getCommentsByPositionGroups(
              visiblePgs && visiblePgs.length ? visiblePgs : '',
              Number(match.season_id)
            ),

            this.store.selectSnapshot(PositionGroupSelectors.positionGroups).length
              ? of(this.store.selectSnapshot(PositionGroupSelectors.positionGroups))
              : this.store
                  .dispatch(new GetPositionGroups(sobValue, Number(match.season_id)))
                  .pipe(map((state) => state.position_group.positionGroups)),
          ]).pipe(
            map(([pgMTPs, comments, pgwithPositions]) =>
              this.createGrade(players, pgMTPs, comments, pgwithPositions)
            )
          );
        }
      })
    );
  }

  createGrade(
    players: PlayerMatchI[],
    pgMTPs: PositionGroupMtps[],
    comments: Comment[],
    pgWithPositions: PositionGroup[]
  ): GradingData[] {
    let grades: GradingData[] = pgMTPs
      .map((obj) => ({
        ...obj,
        checked: true,
        expanded: true,
        positions: pgWithPositions.filter((f) => f.name == obj.name)[0]?.positions,
        players: [
          ...players
            .filter((f) => +f.position_group == +obj.name)
            .map((mp) => ({ ...mp, checked: true, expanded: true })),
        ],
        comments: comments.filter((f) => f.pg == obj.name),
      }))
      .filter((ft) => ft.players.length > 0);

    return grades;
  }

  updateType(id, payload): Observable<GradingMatch> {
    return this.http.patch<GradingMatch>(`${environment.main_url}/Matches/${id}/`, payload).pipe(
      tap((match: GradingMatch) => {
        this.store.dispatch(new UpdateGradingMatch(match));
      })
    );
  }

  deletePlayer(playerId: PlayerMatchI['id']) {
    return this.http.delete(`${environment.main_url}/PlayerMatch/${playerId}/`);
  }

  deleteAllPlayers(players: GradingData['players']) {
    const playerIds = players.map((player) => player.id);
    return this.http.delete(
      `${environment.main_url}/player-match/players/?ids=${playerIds.toString()}`
    );
  }

  gradingSwap(
    pg: PositionGroup['name'],
    seasonId: Season['id'],
    notIdIn: string
  ): Observable<Player[]> {
    return this.http.get<Player[]>(
      `${
        environment.main_url
      }/Player/?playerposition__position_group__in=${pg}&playerposition__season=${seasonId}&not__id__in=${notIdIn.toString()}`
    );
  }

  gradingUpdatePlayer(id: PlayerMatchI['id'], requestBody) {
    return this.http.patch(`${environment.main_url}/PlayerMatch/${id}/`, requestBody);
  }

  gradingSwapPlayer(oldPlayer: PlayerMatchI, requestBody) {
    return this.http.patch(`${environment.main_url}/PlayerMatch/${oldPlayer.id}/`, requestBody);
  }

  setGradingPlayerScore(skillId: number, requestBody) {
    return this.http.patch(`${environment.main_url}/Score/${skillId}/`, requestBody);
  }

  updateScore(payload) {
    return this.http.patch(`${environment.main_url}/Score/batch-update-scores/`, payload);
  }

  addSkillClasstoSkillScore(
    scoreId: number,
    requestBody: {
      skill_class: number;
    }
  ) {
    return this.http.patch(`${environment.main_url}/Score/${scoreId}/`, requestBody);
  }

  getGradingPlayerMatch(id): Observable<PlayerMatchI> {
    return this.http.get<PlayerMatchI>(`${environment.main_url}/PlayerMatch/${id}/`);
  }

  postPlayerMatchMTP(requestBody) {
    return this.http.post(`${environment.main_url}/PlayerMatchMTP/toggleMtp/`, requestBody);
  }

  nextMatch(payload) {
    return this.http.post(`${environment.main_url}/Matches/add-players/`, payload);
  }
}
