import { Injectable } from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Injectable({ providedIn: 'root' })
export class AlertService {
  types = {
    success: {
      icon: 'heroicons_outline:check-circle',
      color: 'primary',
    },
    info: {
      icon: 'heroicons_outline:information-circle',
      color: 'accent',
    },
    warning: {
      icon: 'heroicons_outline:exclamation-triangle',
      color: 'warn',
    },
  };

  constructor(private fuseConfirmationService: FuseConfirmationService) {}

  show(
    type: string,
    title: string,
    message: string,
    messageColorClass = 'text-secondary',
    confrimButtonText = 'Confirm'
  ) {
    return this.fuseConfirmationService.open({
      title,
      message,
      messageColorClass,
      icon: {
        show: true,
        name: this.types[type].icon,
        color: this.types[type].color,
      },
      actions: {
        confirm: {
          show: true,
          label: confrimButtonText,
          color: this.types[type].color,
        },
        cancel: {
          show: true,
          label: 'Cancel',
        },
      },
      dismissible: false,
    });
  }
}
