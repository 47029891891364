import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

import { SignUpForm } from '@shared/interfaces/signUp';
import {
  ChangePasswordApiResponse,
  ChangePasswordPayload,
  ForgotPasswordApiResponse,
  LoginApiResponse,
  ResetPasswordApiResponse,
  ResetPasswordPayload,
  User,
} from '@shared/interfaces/user';
import { Select, Store } from '@ngxs/store';
import { SetUserOnReload } from '@NgXs/authentication/actions/reload.actions';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class AuthService {
  storageMedium: Storage;

  @Select(StorageMediumSelector.storageMedium)
  storageMedium$: Observable<Storage | null>;

  constructor(
    private store: Store,
    private _httpClient: HttpClient
  ) {
    this.storageMedium$.subscribe((storageMedium: Storage) => {
      this.storageMedium = storageMedium;
    });
  }

  get login(): LoginApiResponse {
    return JSON.parse(this.storageMedium.getItem('login')) as LoginApiResponse;
  }
  get user(): User {
    return (JSON.parse(this.storageMedium.getItem('login')) as LoginApiResponse)?.user;
  }
  get accessToken(): string {
    return (JSON.parse(this.storageMedium.getItem('login')) as LoginApiResponse)?.access;
  }
  get refreshToken(): string {
    return (JSON.parse(this.storageMedium.getItem('login')) as LoginApiResponse)?.refresh;
  }

  newRefreshToken() {
    return this._httpClient
      .post<any>(`${environment.main_url}/api/token/refresh/`, {
        refresh: this.refreshToken,
      })
      .pipe(
        map((response: any) => {
          let loginUser: LoginApiResponse = this.login;
          if (response.access) {
            loginUser.access = response.access;
            loginUser.refresh = response.refresh;

            this.storageMedium.setItem('login', JSON.stringify(loginUser));

            this.store.dispatch(new SetUserOnReload());
          }
          return loginUser;
        })
      );
  }

  forgotPassword(payload: { email: string }): Observable<ForgotPasswordApiResponse> {
    return this._httpClient.post<ForgotPasswordApiResponse>(
      `${environment.main_url}/rest-auth/password/reset/`,
      payload
    );
  }

  changePassword(payload: ChangePasswordPayload): Observable<ChangePasswordApiResponse> {
    return this._httpClient.post<ChangePasswordApiResponse>(
      `${environment.main_url}/rest-auth/password/change/`,
      payload
    );
  }

  resetPassword(payload: ResetPasswordPayload): Observable<ResetPasswordApiResponse> {
    return this._httpClient.post<ResetPasswordApiResponse>(
      `${environment.main_url}/rest-auth/password/reset/confirm/`,
      payload
    );
  }

  signIn(credentials: { email: string; password: string }): Observable<LoginApiResponse> {
    const loginUrl = `${environment.main_url}/api/token/`;
    return this._httpClient.post<LoginApiResponse>(loginUrl, credentials);
  }

  signUp(user: SignUpForm): Observable<any> {
    return this._httpClient.post(`${environment.main_url}/rest-auth/registration/`, user);
  }
}
