<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
  <div class="flex md:w-full md:max-w-6xl sm:rounded-2xl sm:shadow overflow-hidden sm:bg-card">
    <div
      style="
        background: url('assets/images/backgrounds/login-screen.jpg');
        background-size: cover;
        background-position: center;
      "
      class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden dark:border-r"
    >
      <!-- Content -->
      <div class="z-10 relative w-full max-w-2xl">
        <!--                <div>text</div>-->
      </div>
    </div>
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-auto flex justify-center items-center">
          <img
            width="100"
            height="83"
            style="max-width: 180px"
            [ngSrc]="
              (currentTheme$ | async) === 'light'
                ? 'assets/images/logo/logo-light2_.svg'
                : 'assets/images/logo/logo-dark.png'
            "
            alt="logo"
          />
        </div>

        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Sign in</div>
        <div class="flex items-baseline mt-0.5 font-medium">
          <div>Don't have an account?</div>
          <a class="ml-1 text-primary-500 hover:underline" routerLink="/authentication/sign-up"
            >Sign up
          </a>
        </div>

        <!-- Alert -->
        <ng-container *ngIf="showAlert">
          <fuse-alert
            *ngFor="let alert of alerts"
            class="mt-8 -mb-4"
            [appearance]="'outline'"
            [showIcon]="false"
            [type]="alert.type"
            [@shake]="alert.type === 'error'"
          >
            {{ alert.message }}
          </fuse-alert>
        </ng-container>

        <!-- Sign in form -->
        <form class="mt-8" [formGroup]="signInForm" #signInNgForm="ngForm">
          <!-- Email field -->
          <mat-form-field class="w-full">
            <mat-label>Email address or Username</mat-label>
            <input id="email" autocomplete="username" matInput formControlName="email" />
            <mat-error *ngIf="signInForm.get('email').hasError('required')">
              Email / Username is required
            </mat-error>
          </mat-form-field>

          <!-- Password field -->
          <mat-form-field class="w-full">
            <mat-label>Password</mat-label>
            <input
              id="password"
              matInput
              type="password"
              autocomplete="current-password"
              formControlName="password"
              #passwordField
            />
            <button
              mat-icon-button
              type="button"
              (click)="
                passwordField.type === 'password'
                  ? (passwordField.type = 'text')
                  : (passwordField.type = 'password')
              "
              matSuffix
            >
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'password'"
                [svgIcon]="'heroicons_solid:eye'"
              ></mat-icon>
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'text'"
                [svgIcon]="'heroicons_solid:eye-slash'"
              ></mat-icon>
            </button>
            <mat-error> Password is required </mat-error>
          </mat-form-field>

          <!-- Actions -->
          <div class="inline-flex items-center justify-end w-full mt-1.5">
            <a
              class="text-md font-medium text-primary-500 hover:underline"
              routerLink="/authentication/forgot-password"
              >Forgot password?
            </a>
          </div>

          <!-- Submit button -->
          <button
            class="fuse-mat-button-large w-full mt-6"
            mat-flat-button
            [color]="'primary'"
            [disabled]="isLoading$ | async"
            (click)="signIn()"
          >
            <span *ngIf="!(isLoading$ | async)"> Sign in </span>
            <mat-progress-spinner
              *ngIf="isLoading$ | async"
              [diameter]="24"
              [mode]="'indeterminate'"
            ></mat-progress-spinner>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
