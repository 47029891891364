import { State, Action, StateContext, Store } from '@ngxs/store';
import { SOB } from '../interfaces/sob.interface';
import { Sob } from '../enums/sob.enum';
import { Injectable } from '@angular/core';
import { LoadSobs, SelectedSob } from './sob.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { PermissionsService } from 'app/core/permissions/permissions.service';
import { ActivatedRoute } from '@angular/router';

export interface SobStateModel {
  selectedSob: SOB;
  allSobs: SOB[];
}

@State<SobStateModel>({
  name: 'sob',
  defaults: {
    selectedSob: {
      name: Sob.Offense,
      value: Sob.OffenseValue,
      alias: Sob.OffenseAlias,
    },
    allSobs: [],
  },
})
@Injectable()
export class SobState {
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService
  ) {}

  @Action(SelectedSob)
  selectedSob({ patchState }: StateContext<SobStateModel>, { payload }: SelectedSob) {
    patchState({
      selectedSob: { ...payload },
    });
  }

  @Action(LoadSobs)
  loadSobs({ patchState }: StateContext<SobStateModel>, { sobValue }: LoadSobs) {
    patchState({
      allSobs: [...this.permissionCheck(sobValue)],
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<SobStateModel>) {
    ctx.setState({
      selectedSob: {
        name: '',
        value: '',
        alias: '',
      },
      allSobs: [],
    });
  }

  permissionCheck(sobValue: SOB['value']): SOB[] {
    let sobs: SOB[] = [];

    let permissions = this.permissionsService.UserPermissionsOnly;
    if (permissions.includes(Sob.ViewAll)) {
      let groupPermissions = this.permissionsService.Permissions.concat(
        this.permissionsService.UserPermissionsOnly
      );
      if (groupPermissions.includes(Sob.ViewSpecialTeams)) {
        sobs = [
          {
            name: Sob.Offense,
            value: Sob.OffenseValue,
            alias: Sob.OffenseAlias,
          },
          {
            name: Sob.Defense,
            value: Sob.DefenseValue,
            alias: Sob.DefenseAlias,
          },
          {
            name: Sob.SpecialTeams,
            value: Sob.SpecialTeamsValue,
            alias: Sob.SpecialTeamsAlias,
          },
        ];
      } else {
        sobs = [
          {
            name: Sob.Offense,
            value: Sob.OffenseValue,
            alias: Sob.OffenseAlias,
          },
          {
            name: Sob.Defense,
            value: Sob.DefenseValue,
            alias: Sob.DefenseAlias,
          },
        ];
      }
    } else {
      if (permissions.includes(Sob.ViewOffense)) {
        sobs.push({
          name: Sob.Offense,
          value: Sob.OffenseValue,
          alias: Sob.OffenseAlias,
        });
      }
      if (permissions.includes(Sob.ViewDefense)) {
        sobs.push({
          name: Sob.Defense,
          value: Sob.DefenseValue,
          alias: Sob.DefenseAlias,
        });
      }
      if (permissions.includes(Sob.ViewSpecialTeams)) {
        sobs.push({
          name: Sob.SpecialTeams,
          value: Sob.SpecialTeamsValue,
          alias: Sob.SpecialTeamsAlias,
        });
      }
    }

    if (sobValue) {
      const selectedSob = sobs.find((sob: SOB) => sob.value === sobValue) || sobs[0];
      this.store.dispatch(new SelectedSob(selectedSob));
    } else {
      this.store.dispatch(new SelectedSob(sobs[0]));
    }

    return sobs;
  }
}
