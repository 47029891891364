<div class="d-flex justify-content-between align-items-center">
  <button
    mat-icon-button
    [disabled]="!played && !skipPlay"
    (click)="onClick('view')"
    [class.btn-graded-plays]="!(!played && !skipPlay)"
    class="icon-btn mx-1"
    color="accent"
  >
    <mat-icon class="icon-size-5" svgIcon="remove_red_eye"></mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="onClick('edit')"
    [class.btn-graded-plays]="!(!played && !skipPlay)"
    class="icon-btn mx-1"
    color="primary"
  >
    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
  </button>
  <button
    *hasPermission="Permission.DeleteMatches"
    mat-icon-button
    (click)="onDelete()"
    class="icon-btn mx-1"
    color="warn"
  >
    <mat-icon class="icon-size-5">delete</mat-icon>
  </button>
</div>
