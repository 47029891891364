import { Selector } from '@ngxs/store';
import { AuthState, AuthStateModel } from '../states/auth.state';
import { LoginApiResponse } from '@shared/interfaces/user'; // Import your AuthState and AuthStateModel

export class AuthSelectors {
  @Selector([AuthState])
  static isAuthenticated(state: AuthStateModel): boolean {
    return state.isAuthenticated;
  }

  @Selector([AuthState])
  static currentUser(state: AuthStateModel): LoginApiResponse | null {
    return state.login;
  }

  @Selector([AuthState])
  static error(state: AuthStateModel): Record<string, string[] | string> {
    return state.error;
  }
  @Selector([AuthState])
  static forgotPasswordMessage(state: AuthStateModel): {
    message: string;
    isSuccess: boolean;
  } {
    return state.forgotPassword;
  }
  @Selector([AuthState])
  static changePasswordMessage(state: AuthStateModel): {
    message: string;
    isSuccess: boolean;
  } {
    return state.changePassword;
  }
  @Selector([AuthState])
  static resetPasswordMessage(state: AuthStateModel): {
    message: string;
    isSuccess: boolean;
  } {
    return state.resetPassword;
  }
}
