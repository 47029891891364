import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import _ from 'lodash';
import { ResetVideoDurationProgress, SetVideoDurationProgress } from './video-duration.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface VideoDurationStateModel {
  videoDurationProgress: string;
}

@State<VideoDurationStateModel>({
  name: 'videoDurationProgress',
  defaults: {
    videoDurationProgress: null,
  },
})
@Injectable()
export class VideoDurationState {
  constructor() {}

  @Action(SetVideoDurationProgress)
  setVideoDurationProgress(
    { patchState }: StateContext<VideoDurationStateModel>,
    { progress }: SetVideoDurationProgress
  ) {
    patchState({ videoDurationProgress: progress });
  }

  @Action(ResetVideoDurationProgress)
  resetVideoDurationProgress({ patchState }: StateContext<VideoDurationStateModel>) {
    patchState({
      videoDurationProgress: null,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<VideoDurationStateModel>) {
    ctx.setState({
      videoDurationProgress: null,
    });
  }
}
