import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { ConfirmPasswordValidator } from '@shared/validators/confirm-password.validator';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { ThemeSelectors } from '@NgXs/Theme/theme.state';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { SignUp } from '@NgXs/authentication/actions/signUp.action';
import { StartLoading, StopLoading } from '@NgXs/loading/loading.action';
import { LoadingSelectors } from '@NgXs/loading/loading.selectors';

@Component({
  selector: 'sign-up-modern-reversed',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    FuseAlertComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    AsyncPipe,
    NgOptimizedImage,
    NgForOf,
  ],
})
export class AuthSignUpComponent implements OnInit, OnDestroy {
  @ViewChild('signUpNgForm') signUpNgForm: NgForm;
  @Select(ThemeSelectors.currentTheme) currentTheme$: Observable<'dark' | 'light' | 'auto'>;
  @Select(LoadingSelectors.isLoading) loading$: Observable<boolean>;
  errorMessages: Record<string, string[]> = {
    first_name: [],
    last_name: [],
    club_name: [],
    username: [],
    email: [],
    password1: [],
    password2: [],
    type: [],
    agree: [],
  };
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };
  signUpForm: UntypedFormGroup;
  private formSubscription: Subscription;

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {
    localStorage.clear();
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.signUpForm = this._formBuilder.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        club_name: ['', Validators.required],
        username: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
        password2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
        type: ['Head', Validators.required],
        agree: [false, Validators.requiredTrue],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );

    this.formSubscription = this.signUpForm.valueChanges.subscribe((form) => {
      this.cdr.detectChanges();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   */
  signUp(): void {
    this.store.dispatch(new StartLoading());

    this.store.dispatch(new SignUp(this.signUpForm.value)).subscribe({
      next: (res) => {
        const errors = res.auth.error as any;

        if (errors && errors.signUp && Object.keys(errors?.signUp).length > 0) {
          for (const errorKey in errors?.signUp) {
            this.signUpForm.get(errorKey).setErrors({ serverError: true });
            this.errorMessages[errorKey] = errors?.signUp[errorKey];
          }
        }
      },
      error: (err) => {
        console.log({ err });
      },
      complete: () => {
        this.store.dispatch(new StopLoading());
      },
    });
  }

  showTermsAndConditions(event: { stopPropagation: () => void }) {
    this.dialog.open(TermsAndConditionsComponent, {
      width: '1000px',
      height: '94vh',
    });
    event.stopPropagation();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.signUpForm.controls[controlName];
    if (!control) {
      return false;
    }

    return control.hasError(validationType) && (control.dirty || control.touched);
  }
}
