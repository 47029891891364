import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { MatchApiResponse } from '@shared/interfaces/system-settings.interface';
import { SystemSettingService } from '@shared/services/system-setting/system-setting.service';
import { iif, of, tap } from 'rxjs';
import {
  GetMappings,
  GradingDataUploadEvent,
  ResetDataImportMappings,
  UpdateMappings,
} from './data-import.action';

export interface DataImportStateModel {
  gradingDataUploadEvent: { state: boolean; res };
  mappings: MatchApiResponse;
}

@State<DataImportStateModel>({
  name: 'dataImport',
  defaults: {
    gradingDataUploadEvent: { state: false, res: null },
    mappings: null,
  },
})
@Injectable()
export class DataImportState {
  constructor(private systemSettingService: SystemSettingService) {}

  @Action(GetMappings)
  getMappings(ctx: StateContext<DataImportStateModel>, payload: GetMappings) {
    return this.systemSettingService.getMapReportBySob(payload.sob, payload.season).pipe(
      tap((matchRespose: MatchApiResponse[]) => {
        ctx.patchState({
          mappings: matchRespose[0],
        });
      })
    );
  }

  @Action(UpdateMappings)
  updateMappings(ctx: StateContext<DataImportStateModel>, payload: UpdateMappings) {
    return this.systemSettingService
      .updateMappings(payload.sessionUserId, payload.matchRespose)
      .pipe(
        tap((matchRespose: MatchApiResponse) => {
          ctx.patchState({
            mappings: matchRespose,
          });
        })
      );
  }

  @Action(GradingDataUploadEvent)
  gradingDataUploadEvent(
    ctx: StateContext<DataImportStateModel>,
    { data }: GradingDataUploadEvent
  ) {
    ctx.patchState({
      gradingDataUploadEvent: { state: data.state, res: data.res },
    });
  }

  @Action(ResetDataImportMappings)
  resetDataImportMappings(ctx: StateContext<DataImportStateModel>) {
    ctx.patchState({
      mappings: null,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<DataImportStateModel>) {
    ctx.setState({
      gradingDataUploadEvent: { state: false, res: null },
      mappings: null,
    });
  }
}
