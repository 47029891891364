import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  CoachReportPayload,
  MatchData,
  ShowReportApi,
  PlayerMatchI,
  GradingDataPayload,
} from '../interfaces/show-matches.interface';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { Season } from '@shared/interfaces/season.interface';
import { Player, PlayerSubstituion } from '@shared/interfaces/player';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { GradingMatch } from 'app/modules/grading/interfaces/grading.interface';
import { Sob } from 'app/core/layout/components/select-sob/enums/sob.enum';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import { Select } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class ShowMatchesService {
  storageMedium: Storage;

  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;

  constructor(private http: HttpClient) {
    this.storageMedium$.subscribe((storageMedium: any) => {
      this.storageMedium = storageMedium;
    });
  }

  getMatches(
    seasonId: number,
    weekNo: number,
    countNo: number,
    playType: string,
    sob: string,
    periodDescription: number | null,
    type: string | null
  ): Observable<MatchData[]> {
    let params = `week_id__season_id__id=${seasonId}&week_id__week_no=${weekNo}&play_type=${playType}&count=${countNo}&role=${sob}`;
    if (periodDescription) params += `&qtr=${periodDescription}`;
    if (type) params += `&types=${type}`;
    return this.http.get<MatchData[]>(`${environment.main_url}/Show-Matches/?${params}`);
  }

  setPlayerMatch(payload) {
    return this.http.post(`${environment.main_url}/PlayerMatch/`, payload);
  }

  getPlayerMatch(matchId: GradingMatch['id'], sobValue: SOB['value']): Observable<PlayerMatchI[]> {
    // let visiblePgs: PositionGroup['name'][] = JSON.parse(localStorage.getItem('visiblePgs'));
    let url = `${environment.main_url}/PlayerMatch/?match_id=${matchId}`;

    // if (visiblePgs && visiblePgs.length && sobValue != Sob.SpecialTeamsValue) {
    //   url = `${
    //     environment.main_url
    //   }/PlayerMatch/?match_id=${matchId}&visible_pgs=${visiblePgs.toString()}&position__postion_group__in=${visiblePgs.toString()}`;
    // }
    return this.http.get<PlayerMatchI[]>(url);
  }

  getCoachReport(payload: CoachReportPayload): Observable<ShowReportApi> {
    return this.http.post<ShowReportApi>(
      `${environment.main_url}/PlayerReport/coach-report/`,
      payload
    );
  }

  getPlayerGradingData(payload: GradingDataPayload): Observable<Blob> {
    return this.http.post(`${environment.main_url}/export-player-grading-data/`, payload, {
      responseType: 'blob',
    });
  }

  getPlayersByPg(pgIds: PositionGroup['name'][], seasonId: Season['id']): Observable<Player[]> {
    return this.http
      .get<
        Player[]
      >(`${environment.main_url}/Player/?playerposition__position_group__in=${pgIds.join(',')}&playerposition__season=${seasonId}`)
      .pipe(
        map((players: Player[]) =>
          players.map((player: Player) => {
            player.disable = false;
            return player;
          })
        )
      );
  }

  getSubstitutionsByPg(
    seasonId: Season['id'],
    pgIds: PositionGroup['name'][],
    sob: SOB['value']
  ): Observable<PlayerSubstituion[]> {
    return this.http.get<PlayerSubstituion[]>(
      `${environment.main_url}/Substitution/?season=${seasonId}&player_position__position__postion_group__in=${pgIds}&sob=${sob}`
    );
  }

  patchPlayerSwapOnGrading(payload) {
    return this.http.patch(`${environment.main_url}/PlayerMatch/substitution_swap/`, payload);
  }

  deletePlayerMatch(matchId: number): Observable<PlayerMatchI[]> {
    return this.http.delete<PlayerMatchI[]>(`${environment.main_url}/Matches/${matchId}/`);
  }
}
