import { Action, State, StateContext } from '@ngxs/store';
import { Game } from '../../interfaces/infinite-athlete.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { GetSelectedGame, SetSelectedGame } from './selected-game.action';

export interface SelectedGameStateModel {
  selectedGame: Game;
}

@State<SelectedGameStateModel>({
  name: 'selectedGame',
  defaults: {
    selectedGame: {
      week: null,
      game_type: null,
      ngsId: null,
      season: null,
      gsisGameKey: null,
      weekName: null,
      phase: null,
      time: null,
      id: null,
      type: null,
      v1Id: null,
      homeTeamEdge: null,
      awayTeamEdge: null,
    },
  },
})
@Injectable()
export class SelectedGameState {
  constructor() {}

  @Action(GetSelectedGame)
  getPlayFields({ getState }: StateContext<SelectedGameState>): Observable<any> {
    return of(getState());
  }

  @Action(SetSelectedGame)
  setSelectedGame(
    { patchState }: StateContext<SelectedGameStateModel>,
    { selectedGame }: SetSelectedGame
  ) {
    patchState({
      selectedGame: selectedGame,
    });
  }

  @Action(ClearAllStates)
  clearSelectedGame(ctx: StateContext<SelectedGameStateModel>) {
    ctx.setState({
      selectedGame: {
        week: null,
        game_type: null,
        ngsId: null,
        season: null,
        gsisGameKey: null,
        weekName: null,
        phase: null,
        time: null,
        id: null,
        type: null,
        v1Id: null,
        homeTeamEdge: null,
        awayTeamEdge: null,
      },
    });
  }
}
