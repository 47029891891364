import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeSelectors } from '@NgXs/Theme/theme.state';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  currentTheme: string;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
    private ccService: NgcCookieConsentService //this import is necessary in order to show the cookie consent popup. Dont remove it.
  ) {}

  ngOnInit() {
    this.store
      .select(ThemeSelectors.currentTheme)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((theme) => {
        this.currentTheme = theme;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
