import { Position, PositionOrderPayload } from 'app/modules/position/interfaces/position.interface';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { Season } from '@shared/interfaces/season.interface';

export class GetPositionGroups {
  static readonly type = '[PositionGroup] PositionGroups';

  constructor(
    public sob: SOB['value'],
    public seasonId: Season['id']
  ) {}
}

export class GetAllPositionGroups {
  static readonly type = '[PositionGroup] AllPositionGroups';
  constructor(public seasonId: Season['id']) {}
}

export class SelectedPositionGroups {
  static readonly type = '[PositionGroup] Selected PositionGroups';
  constructor(public payload: PositionGroup[]) {}
}

export class AddPositionInPositionGroup {
  static readonly type = '[PositionGroup] Add Position In PositionGroup';
  constructor(public position: Position) {}
}

export class EditPositionInPositionGroup {
  static readonly type = '[PositionGroup] Edit Position In PositionGroup';
  constructor(public position: Position) {}
}

export class DeletePositionInPositionGroup {
  static readonly type = '[PositionGroup] Delete Position In PositionGroup';
  constructor(public position: Position) {}
}

export class AddPositionGroup {
  static readonly type = '[PositionGroup] Add PositionGroup';
  constructor(public positionGroup: PositionGroup) {}
}

export class EditPositionGroup {
  static readonly type = '[PositionGroup] Edit PositionGroup';
  constructor(public positionGroup: PositionGroup) {}
}

export class DeletePositionGroup {
  static readonly type = '[PositionGroup] Delete PositionGroup';
  constructor(public positionGroup: PositionGroup) {}
}

export class PostPositionOrder {
  static readonly type = '[PositionGroup] Post Position Order Success';
  constructor(public payload: PositionOrderPayload[]) {}
}

export class GetAllPositionGroupsWithoutSeasons {
  static readonly type = '[PositionGroup] AllPositionGroupsWithoutSeasons';
}
