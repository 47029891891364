import { AuthSelectors } from '@NgXs/authentication/selectors/auth.selectors';
import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { SettingsComponent } from '../settings/settings.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { SignOut } from '@NgXs/authentication/actions/signOut.actions';
import { user } from '@shared/services/user/data';
import { BreakpointSelectors } from '@NgXs/breakpoint/breakpoint.selectors';
import { SelectSobComponent } from '../select-sob/select-sob.component';
import { LoadUserProfile } from '../../../../modules/user-profile/state/user-profile.action';
import { UserProfileSelectors } from '../../../../modules/user-profile/state/user-profile.selector';
import { UserData } from '../../../../modules/user-profile/interfaces/user-profile.interface';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    NgIf,
    MatIconModule,
    NgClass,
    MatDividerModule,
    FuseDrawerComponent,
    SettingsComponent,
    RouterLink,
    SelectSobComponent,
  ],
})
export class UserComponent implements OnInit, OnDestroy {
  static ngAcceptInputType_showAvatar: BooleanInput;
  @Select(UserProfileSelectors.getUserProfileState) userData$: Observable<UserData | null>;
  @Select(BreakpointSelectors.mobileScreen)
  isMobileScreen$: Observable<boolean | null>;

  @Input() showAvatar: boolean = true;

  @ViewChild('settingsDrawer') settingsDrawer: FuseDrawerComponent;

  clickSubscription: Subscription | undefined;

  constructor(
    private _router: Router,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadUserProfile());

    this.clickSubscription = fromEvent(document, 'click').subscribe(() => {
      if (!this.settingsDrawer.opened) this.settingsDrawer.close();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.clickSubscription.unsubscribe();
  }

  signOut(): void {
    this.store.dispatch(new SignOut());
  }

  redirectTo(path: string): void {
    this._router.navigate([path], {
      queryParamsHandling: 'preserve',
    });
    this.cdr.detectChanges();
  }

  protected readonly user = user;
}
