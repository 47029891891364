import { ConditionFilter } from '../../interfaces/infinite-athlete.interface';

export class GetPlayFilters {
  static readonly type = '[playFilters] Get PlayFilters';
  constructor() {}
}

export class SetPlayFilters {
  static readonly type = '[setPlayFilters] Set PlayFilters';
  constructor(
    public filterName: string,
    public playFilters: ConditionFilter[]
  ) {}
}

export class ClearAllPlayFilters {
  static readonly type = '[playFilters] Clear AllPlayFilters';
  constructor() {}
}
