import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LoadingSelectors } from '@NgXs/loading/loading.selectors';
import { Select } from '@ngxs/store';
import { MultiSelectPositionGroupsComponent } from '../multi-select-position-groups/multi-select-position-groups.component';
import { SobSelectors } from 'app/core/layout/components/select-sob/state/sob.selector';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { Sob } from 'app/core/layout/components/select-sob/enums/sob.enum';
import { SelectPositionGroupComponent } from '../select-position-group/select-position-group.component';

@Component({
  selector: 'og-select-position-group-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    SelectPositionGroupComponent,
    MultiSelectPositionGroupsComponent,
  ],
  templateUrl: './select-position-group-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPositionGroupDialogComponent implements OnInit, OnDestroy {
  currentSob: SOB;
  selectedPgs: PositionGroup[] = [];

  @Select(LoadingSelectors.isLoading) isLoading$: Observable<boolean>;
  @Select(SobSelectors.selectedSob) currentSob$: Observable<SOB | null>;

  protected readonly Sob = Sob;
  private unSubscribeAll$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<SelectPositionGroupDialogComponent>
  ) {}

  ngOnInit(): void {
    this.currentSob$.pipe(takeUntil(this.unSubscribeAll$)).subscribe({
      next: (sob) => {
        this.currentSob = sob;
        this.cdr.detectChanges();
      },
    });
  }

  closeDialog(): void {
    this.dialogRef.close(this.selectedPgs);
  }

  ngOnDestroy(): void {
    this.unSubscribeAll$.next();
    this.unSubscribeAll$.complete();
  }
}
