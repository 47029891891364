<div class="flex">
  <mat-form-field
    *ngIf="showGamePracticeSelect"
    floatLabel="always"
    class="px-2"
    [ngStyle]="{ width: widthForGamePractice ?? '230px' }"
  >
    <mat-select
      label="Practice/Game"
      placeholder="Practice/Game"
      [formControl]="selectedGameType"
      (selectionChange)="onGameTypeChange($event)"
    >
      <mat-option *ngFor="let game of gamesType | keyvalue" [value]="game.key">{{
        game.value
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    *ngIf="showPracticeNoSelect"
    floatLabel="always"
    class="px-2"
    [ngStyle]="{ width: widthForPracticeNo ?? '230px' }"
  >
    <mat-select
      placeholder="Practice No"
      [formControl]="selectedPracticeNo"
      [compareWith]="comparer"
      (selectionChange)="onPracticeChange($event)"
    >
      <mat-option *ngFor="let practice of practiceNoOptions" [value]="practice"
        >{{ practice.count }}
        <ng-container *ngIf="practice.played_at"
          >- {{ practice.played_at | date: 'MM/dd/yyyy' }}</ng-container
        >
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
