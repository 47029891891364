<ag-grid-angular
  style="width: 100%"
  class="ag-theme-alpine"
  [animateRows]="true"
  [columnDefs]="columnDefs"
  [context]="context"
  [domLayout]="'autoHeight'"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  [rowData]="rowData"
  [rowDragManaged]="rowDragManaged"
  [rowSelection]="'multiple'"
  [suppressRowDrag]="suppressRowDrag"
  [suppressRowClickSelection]="true"
  (cellValueChanged)="onCellValueChanged($event)"
  (gridReady)="onGridReady($event)"
  (rowDragEnter)="onRowDragEnter($event)"
  (rowDragEnd)="onRowDragEnd($event)"
  (selectionChanged)="onSelectionChanged($event)"
></ag-grid-angular>
