import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { HasUserGroupPermissionDirective } from 'app/core/permissions/directives/has-usergoup.directive';
import { UserGroupPermission } from 'app/core/permissions/enums/user-group.enum';

@Component({
  selector: 'og-grade-sheet-preview',
  standalone: true,
  imports: [MatCardModule, CommonModule, HasUserGroupPermissionDirective],
  templateUrl: './grade-sheet-preview.component.html',
  styleUrls: ['./grade-sheet-preview.component.scss'],
})
export class GradeSheetPreviewComponent {
  @Input() numberOfSheets: number;
  @Input() selectedPositionNames: string[];
  @Input() gameType: any;
  @Input() currentWeek: any;
  @Input() positionGroup: string;
  @Input() skillSet: string[];
  @Input() productionList: string[];

  protected readonly UserGroupPermission = UserGroupPermission;

  constructor() {}

  getFontSizeClass(position: string): string {
    const length = position.length;
    return `length-${length}`;
  }
}
