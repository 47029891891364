import { GamePracticeState, GamePracticeStateModel } from './game-practice.state';
import { Selector } from '@ngxs/store';
import { PracticeNoApi } from '@shared/interfaces/game-practice.interface';

export class GamePracticeSelectors {
  @Selector([GamePracticeState])
  static getPracticeNumbers(state: GamePracticeStateModel): PracticeNoApi[] {
    return state.practiceNumbers;
  }

  @Selector([GamePracticeState])
  static getSelectedGameType(state: GamePracticeStateModel): {
    playType: string;
    countNo: number;
    gp_count: number;
    qtr: number;
    type: string;
  } {
    return state.selectedGameType;
  }
}
