import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, tap, iif, of } from 'rxjs';
import { SystemSettingService } from '@shared/services/system-setting/system-setting.service';
import { GetMapReport } from './map-report.action';

export interface MapReportStateModel {
  id: number;
  user_id: number;
  role: string;
  source: string;
  report_name: string;
  added_at: string;
  data: { [p: string]: string };
  scouting_fields: { [p: string]: string };
}

@State<MapReportStateModel>({
  name: 'mapReport',
  defaults: {
    id: null,
    user_id: null,
    role: null,
    source: null,
    report_name: null,
    added_at: null,
    data: null,
    scouting_fields: null,
  },
})
@Injectable()
export class MapReportState {
  constructor(private settingService: SystemSettingService) {}

  @Action(GetMapReport)
  getMapReport(
    { getState, patchState }: StateContext<MapReportStateModel>,
    { sobAlias, seasonId }: GetMapReport
  ): Observable<any> {
    return this.settingService.getMapReportBySob(sobAlias, seasonId).pipe(
      tap((newMapReport: any) => {
        if (newMapReport.length > 0) patchState(newMapReport[0]);
      })
    );
  }

  // @Action(EditAATESettings)
  // editSeasonDescription(
  //   { patchState }: StateContext<MapReportStateModel>,
  //   { payload }: EditAATESettings
  // ) {
  //   if (payload) patchState(payload);
  // }
}
