import { Season } from '@shared/interfaces/season.interface';
import { MatchApiResponse } from '@shared/interfaces/system-settings.interface';
import { User } from '@shared/interfaces/user';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';

export class GetMappings {
  static readonly type = '[Data Import] Get Mappings';
  constructor(
    public sob: SOB['alias'],
    public season: Season['id']
  ) {}
}

export class UpdateMappings {
  static readonly type = '[Data Import] Update Mappings';
  constructor(
    public sessionUserId: User['pk'],
    public matchRespose: MatchApiResponse
  ) {}
}

export class GradingDataUploadEvent {
  static readonly type = '[Data Import] Grading Data Upload Event';
  constructor(public data: { state: boolean; res }) {}
}

export class ResetDataImportMappings {
  static readonly type = '[Data Import] Reset Data Import Mappings';
}
