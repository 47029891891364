// game-practice.state.ts
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PracticeNoApi } from '@shared/interfaces/game-practice.interface';
import { GamePracticeService } from '@shared/services/game-practice/game-practice.service';
import {
  LoadPracticeNumbers,
  ResetSelectedGameType,
  SetSelectedGameType,
} from '@shared/states/game-practice/game-practice.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface GamePracticeStateModel {
  practiceNumbers: PracticeNoApi[];
  selectedGameType: {
    playType: string;
    countNo: number;
    gp_count: number;
    qtr: number;
    type: string;
  };
}

@State<GamePracticeStateModel>({
  name: 'gamePractice',
  defaults: {
    practiceNumbers: [],
    selectedGameType: {
      playType: null,
      countNo: null,
      gp_count: null,
      qtr: null,
      type: null,
    },
  },
})
@Injectable()
export class GamePracticeState {
  constructor(private gamePracticeService: GamePracticeService) {}

  // Actions
  @Action(LoadPracticeNumbers)
  loadPracticeNumbers(
    { patchState }: StateContext<GamePracticeStateModel>,
    { seasonId, weekNo, playType, sob }: LoadPracticeNumbers
  ): Observable<PracticeNoApi[]> {
    return this.gamePracticeService.getPracticeNo(seasonId, weekNo, playType, sob).pipe(
      tap((practiceNumbers) => {
        patchState({ practiceNumbers });
      })
    );
  }

  @Action(SetSelectedGameType)
  SetSelectedGameType(
    { patchState }: StateContext<GamePracticeStateModel>,
    { playType, countNo, gp_count, qtr, type }: SetSelectedGameType
  ) {
    patchState({ selectedGameType: { playType, countNo, gp_count, qtr, type } });
  }

  @Action(ResetSelectedGameType)
  resetSelectedGameType({ patchState }: StateContext<GamePracticeStateModel>) {
    patchState({
      selectedGameType: {
        playType: null,
        countNo: null,
        gp_count: null,
        qtr: null,
        type: null,
      },
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<GamePracticeStateModel>) {
    ctx.setState({
      practiceNumbers: [],
      selectedGameType: {
        playType: null,
        countNo: null,
        gp_count: null,
        qtr: null,
        type: null,
      },
    });
  }
}
