import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Season } from '@shared/interfaces/season.interface';
import { GetSettings, MatchApiResponse } from '@shared/interfaces/system-settings.interface';
import { User } from '@shared/interfaces/user';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SystemSettingService {
  constructor(private http: HttpClient) {}

  getSettingsBySob(sobValue: SOB['value'], seasonId: Season['id']) {
    return this.http.get(`${environment.main_url}/setting/?sob=${sobValue}&season_id=${seasonId}`);
  }

  getBoxOptionsBySob(
    sobAlias: SOB['alias'],
    seasonId: Season['id']
  ): Observable<{ [p: string]: string }> {
    return this.http.get<{ [p: string]: string }>(
      `${environment.main_url}/MapReport/box_options/${seasonId}/${sobAlias}/`
    );
  }

  postAATESettings(requestBody: any): Observable<GetSettings> {
    return this.http.post<GetSettings>(`${environment.main_url}/setting/`, requestBody);
  }

  patchAATESettings(recordId: number, requestBody: any) {
    return this.http.patch(`${environment.main_url}/setting/${recordId}/`, requestBody);
  }

  updateMappings(
    sessionUserId: User['pk'],
    payload: MatchApiResponse
  ): Observable<MatchApiResponse> {
    return this.http.post<MatchApiResponse>(
      `${environment.main_url}/MapReport/map_update/${sessionUserId}/`,
      payload
    );
  }

  getMapReportBySob(
    sobAlias: SOB['alias'],
    seasonId: Season['id']
  ): Observable<MatchApiResponse[]> {
    return this.http.get<MatchApiResponse[]>(
      `${environment.main_url}/MapReport/?report_name=Match&role=${sobAlias}&season_id=${seasonId}`
    );
  }
}
