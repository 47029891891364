import { VideoProgressI } from '@shared/interfaces/data-upload-interface';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';

export class HudlDataUploadInitialData {
  static readonly type = '[HudlDataUpload] Hudl Data Upload Initial Data';

  constructor(
    public sob: SOB,
    public season: Season,
    public payload: any
  ) {}
}

export class HudlVideoUploadProgress {
  static readonly type = '[HudlDataUpload] Hudl Video Upload Progrss';

  constructor(
    public sobValue: SOB['value'],
    public payload: VideoProgressI
  ) {}
}

export class RemoveHudlUploadedData {
  static readonly type = '[HudlDataUpload] Remove Hudl Uploaded Data';

  constructor(public sobValue: SOB['value']) {}
}
