import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { UserProfileService } from '../services/user-profile.service';
import {
  LoadUserProfile,
  EditUserProfileDetails,
  EditUserProfileAvatar,
} from './user-profile.action';
import { tap } from 'rxjs/operators';
import { CoachData, UserData } from '../interfaces/user-profile.interface';
import { UpdateLoginUser } from '@NgXs/authentication/actions/login.action';

export interface UserProfileStateModel {
  userProfile: UserData | null;
}

@State<UserProfileStateModel>({
  name: 'userProfile',
  defaults: {
    userProfile: null,
  },
})
@Injectable()
export class UserProfileState {
  constructor(
    private userProfileService: UserProfileService,
    private store: Store
  ) {}

  @Action(LoadUserProfile)
  loadUserProfile(ctx: StateContext<UserProfileStateModel>) {
    ctx.setState({
      userProfile: null,
    });
    return this.userProfileService.getProfileData().pipe(
      tap((userProfile: UserData) => {
        ctx.patchState({
          userProfile,
        });

        this.store.dispatch(new UpdateLoginUser(userProfile));
      })
    );
  }

  @Action(EditUserProfileDetails)
  editUserProfileDetails(ctx: StateContext<UserProfileStateModel>, action: EditUserProfileDetails) {
    return this.userProfileService.editProfileDetails(action.payload).pipe(
      tap((userProfile: UserData) => {
        ctx.patchState({
          userProfile,
        });

        this.store.dispatch(new UpdateLoginUser(userProfile));
      })
    );
  }

  @Action(EditUserProfileAvatar)
  editUserProfileAvatar(ctx: StateContext<UserProfileStateModel>, action: EditUserProfileAvatar) {
    return this.userProfileService.editAvatar(action.payload, action.id).pipe(
      tap((updatedAvatar: CoachData) => {
        const state = ctx.getState();
        const updatedUserProfile: UserData | null = {
          ...state.userProfile,
          coach: {
            ...state.userProfile?.coach,
            avatar: updatedAvatar.avatar,
          },
        };
        ctx.patchState({
          userProfile: updatedUserProfile,
        });
      })
    );
  }
}
