import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  FilteredPlaysPayload,
  PlayFields,
  SeasonGamesApiResponse,
  SeasonGamesPayload,
} from '../interfaces/infinite-athlete.interface';

@Injectable({
  providedIn: 'root',
})
export class InfiniteAthleteService {
  constructor(private http: HttpClient) {}

  authenticateIA(seasonYear: number) {
    return this.http.post(`${environment.main_url}/video/infinite/games/`, { season: seasonYear });
  }

  getPreDefinedFilters(): Observable<PlayFields> {
    return this.http.get<PlayFields>(`${environment.main_url}/video/infinite/play_fields/`);
  }

  getGames(payload: SeasonGamesPayload): Observable<SeasonGamesApiResponse> {
    return this.http.post<SeasonGamesApiResponse>(
      `${environment.main_url}/video/infinite/season_games/`,
      payload
    );
  }

  getFilteredPlays(payload: FilteredPlaysPayload): Observable<any> {
    return this.http.post<any>(`${environment.main_url}/video/infinite/filtered_plays/`, payload);
  }

  playVideo(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.main_url}/video/infinite/play_video/`, payload);
  }

  getAngleThumbnail(payload: any) {
    return this.http.post<any>(`${environment.main_url}/video/infinite/video_thumbnail/`, payload);
  }
}
