export class LoadMatches {
  static readonly type = '[Matches] Load Matches';

  constructor(
    public seasonId: number,
    public weekNo: number,
    public countNo: number,
    public playType: string,
    public sob: string,
    public periodDescription: number | null,
    public type: string | null
  ) {}
}
// export class PlayerMatch {
//   static readonly type = '[Matches] Player Match';

//   constructor(
//     public matchId: number,
//     public sob: string
//   ) {}
// }

export class DeletePlayerMatch {
  static readonly type = '[Matches] Delete Match';
  constructor(public matchId: number) {}
}
