import { Action, State, StateContext } from '@ngxs/store';
import { PlayFields } from '../../interfaces/infinite-athlete.interface';
import { GetPlayFields } from './play-fields.action';
import { Injectable } from '@angular/core';
import { Observable, iif, of, tap } from 'rxjs';
import { InfiniteAthleteService } from '../../services/infinite-athlete.service';

@State<PlayFields>({
  name: 'playFields',
  defaults: {
    situation: null,
    play: null,
    results: null,
    players: null,
    officials: null,
    special_teams: null,
    telemetry: null,
    all_filters: null,
    operators: null,
    conditional: null,
    conference: '',
    season_types: null,
  },
})
@Injectable()
export class PlayFieldsState {
  constructor(private infiniteAthleteService: InfiniteAthleteService) {}

  @Action(GetPlayFields)
  getPlayFields({ getState, patchState }: StateContext<PlayFields>): Observable<any> {
    return iif(
      () => getState().season_types !== null,
      of(getState()),
      this.infiniteAthleteService.getPreDefinedFilters().pipe(
        tap((playFields: any) => {
          patchState(playFields);
        })
      )
    );
  }
}
