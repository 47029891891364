import { SobState, SobStateModel } from './sob.state';
import { Selector } from '@ngxs/store';

export class SobSelectors {
  @Selector([SobState])
  static sobName(state: SobStateModel): string {
    return state.selectedSob.name;
  }

  @Selector([SobState])
  static sobValue(state: SobStateModel): string {
    return state.selectedSob.value;
  }

  @Selector([SobState])
  static sobAlias(state: SobStateModel): string {
    return state.selectedSob.alias;
  }

  @Selector([SobState])
  static selectedSob(state: SobStateModel): SobStateModel['selectedSob'] {
    return state.selectedSob;
  }

  @Selector([SobState])
  static allSobs(state: SobStateModel): SobStateModel['allSobs'] {
    return state.allSobs;
  }
}
