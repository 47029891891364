import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';

@Injectable()
export class PublicTokenInterceptor implements HttpInterceptor {
  storageMedium: Storage;

  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;

  constructor() {
    this.storageMedium$.subscribe((storageMedium: Storage) => {
      this.storageMedium = storageMedium;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Extract token from URL query parameter
    if (!request.url.startsWith('api') && sessionStorage.getItem('guest_token')) {
      const token = sessionStorage.getItem('guest_token');
      // Clone the request and add the token as an Authorization header
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}
