import { VideoProgressI } from '@shared/interfaces/data-upload-interface';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';

export class GamestratDataUploadInitialData {
  static readonly type = '[GamestratDataUpload] Gamestrat Data Upload Initial Data';

  constructor(
    public sob: SOB,
    public season: Season,
    public payload: any
  ) {}
}

export class GamestratVideoUploadProgress {
  static readonly type = '[GamestratDataUpload] Gamestrat Video Upload Progrss';

  constructor(
    public sobValue: SOB['value'],
    public payload: VideoProgressI
  ) {}
}

export class RemoveGamestratUploadedData {
  static readonly type = '[GamestratDataUpload] Remove Gamestrat Uploaded Data';

  constructor(public sobValue: SOB['value']) {}
}
