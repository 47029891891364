import { Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../permissions.service';
import { Permission } from '../enums/permissions.enum';

@Directive({
  selector: '[hasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnInit, OnChanges {
  @Input() hasPermission: Permission | boolean | (Permission | boolean | string)[];

  private permissions: Set<string>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.permissions = new Set<string>(this.permissionsService.Permissions);
    this.checkPermission();
  }

  ngOnChanges() {
    this.checkPermission();
  }

  private checkPermission() {
    this.viewContainer.clear();
    const hasPermission = this.checkAllPermissions();
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  checkAllPermissions(): boolean {
    if (this.permissions) {
      if (Array.isArray(this.hasPermission)) {
        return this.hasPermission.every((permissionOrBoolean) => {
          if (typeof permissionOrBoolean === 'boolean') {
            return permissionOrBoolean;
          } else if (typeof permissionOrBoolean === 'string') {
            return this.permissions.has(permissionOrBoolean as string);
          }
        });
      } else {
        if (typeof this.hasPermission === 'boolean') {
          return this.hasPermission;
        } else {
          return this.permissions.has(this.hasPermission as string);
        }
      }
    }
  }
}
