import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WeeksService {
  constructor(private http: HttpClient) {}

  getWeeks(id: number): Observable<WeeksApi[]> {
    return this.http.get<WeeksApi[]>(`${environment.main_url}/Week/weekseasons/${id}/`);
  }
}
