import { Injectable } from '@angular/core';
import { GuestToken, GuestUser, GuestUserPayload } from '../interfaces/guest.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GuestService {
  constructor(private http: HttpClient) {}
  getGuestUsers(): Observable<GuestUser[]> {
    return this.http.get<GuestUser[]>(`${environment.main_url}/guest-users/`);
  }
  deleteGuestUsers(id: GuestUser['id']): Observable<null> {
    return this.http.delete<null>(`${environment.main_url}/guest-users/${id}/`);
  }
  updateGuestUsers(id: GuestUser['id'], payload: GuestUserPayload): Observable<null> {
    return this.http.patch<null>(`${environment.main_url}/guest-users/${id}/`, payload);
  }
  createGuestUsers(payload: GuestUserPayload): Observable<string> {
    return this.http.post<string>(`${environment.main_url}/User/register-guest-user/`, payload);
  }

  getGuestToken(): Observable<GuestToken[]> {
    return this.http.get<GuestToken[]>(`${environment.main_url}/guest-user-tokens/`);
  }

  deleteGuestToken(id: GuestToken['id']): Observable<null> {
    return this.http.delete<null>(`${environment.main_url}/guest-user-tokens/${id}/`);
  }
}
