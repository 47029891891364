import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { SkillClassFilter } from '@shared/interfaces/skill-class-filter.interface';
import { SkillClassFilterService } from '@shared/services/skill-class-filter/skill-class-filter.service';
import { GetSkillClasses, ResetSkillClasses } from './skill-class-filter.action';
import _ from 'lodash';

export interface SkillClassFilterStateModel {
  skillClasses: { [key: number]: SkillClassFilter[] };
}

@State<SkillClassFilterStateModel>({
  name: 'skillClasses',
  defaults: {
    skillClasses: null,
  },
})
@Injectable()
export class SkillClassFilterState {
  constructor(private skillClassFilterService: SkillClassFilterService) {}

  @Action(GetSkillClasses)
  getSkillClasses(
    { patchState }: StateContext<SkillClassFilterStateModel>,
    { seasonId, sobValue }: GetSkillClasses
  ): Observable<SkillClassFilter[]> {
    return this.skillClassFilterService.getSkillClasses(seasonId, sobValue).pipe(
      tap((skillClasses) => {
        const groupedAndSortedSkillClasses = _.mapValues(
          _.groupBy(skillClasses, 'skill'),
          (group) => group.slice().sort((a, b) => a.text.localeCompare(b.text))
        );

        patchState({ skillClasses: groupedAndSortedSkillClasses });
      })
    );
  }

  @Action(ResetSkillClasses)
  resetSkillClasses({ patchState }: StateContext<SkillClassFilterStateModel>) {
    patchState({
      skillClasses: null,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<SkillClassFilterStateModel>) {
    ctx.setState({
      skillClasses: null,
    });
  }
}
