import { Component } from '@angular/core';
import { FuseAlertComponent } from '@fuse/components/alert';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: '[info-toast-component]',
  template: `<fuse-alert type="info" appearance="outline" class="my-2">
    <span fuseAlertTitle>{{ title }}</span>
    {{ message }}
  </fuse-alert>`,
  standalone: true,
  imports: [FuseAlertComponent],
})
export class InfoToast extends Toast {
  title = '';
  message = '';

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);

    this.title = toastPackage.title;
    this.message = toastPackage.message;
  }
}
