import { Action, State, StateContext } from '@ngxs/store';
import {
  AddComment,
  AddMtpToPg,
  AddSkill,
  AppendMtp,
  DeleteComment,
  DeleteMtp,
  DeletePgMtp,
  DeleteSkill,
  GetComments,
  GetMtps,
  GetPgMtps,
  GetSkills,
  UpdateComment,
  UpdateCommentsOrder,
  UpdateMtp,
  UpdateMtpOrder,
  UpdateMtpWeight,
  UpdateSkill,
} from './production.action';
import { catchError, of, tap } from 'rxjs';
import { ProductionService } from '../services/production.service';
import { Mtp, PositionGroupMtps, Skill } from '../interfaces/production.interface';
import { Injectable } from '@angular/core';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { Comment } from '@shared/interfaces/comment.interface';

export interface ProductionStateModel {
  positionGroupMtps: PositionGroupMtps[];
  mtps: Mtp[];
}

@State<ProductionStateModel>({
  name: 'production_data',
  defaults: {
    positionGroupMtps: [],
    mtps: [],
  },
})
@Injectable()
export class ProductionState {
  constructor(private productionService: ProductionService) {}

  @Action(GetMtps)
  getMtp(ctx: StateContext<ProductionStateModel>, { sobValue, seasonId }: GetMtps) {
    if (!sobValue || !seasonId) return;

    // let mtps = ctx.getState().mtps;
    // let url =
    //   mtps.length && mtps[0].sobValue == sobValue && mtps[0].season_id == seasonId
    //     ? of(ctx.getState().mtps.sort((a: any, b: any) => a.type.localeCompare(b.type)))
    //     : this.productionService.getMtp(sobValue, seasonId);

    let url = this.productionService.getMtp(sobValue, seasonId);
    return url.pipe(
      tap((mtps) => {
        ctx.patchState({ mtps: mtps });
        return mtps;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(GetPgMtps)
  getPgMtps(ctx: StateContext<ProductionStateModel>, action: GetPgMtps) {
    let url = this.productionService.getPositionGroupMtps(action.pgId, action.seasonId);

    let pgMtps = ctx.getState().positionGroupMtps;
    if (
      pgMtps.length &&
      pgMtps[0].side_of_ball == action.sobValue &&
      pgMtps[0].season_id == action.seasonId
    ) {
      pgMtps.forEach((pgMtp: PositionGroupMtps) => {
        if (pgMtp.name == action.pgId) {
          url = of(pgMtp);
        }
      });
    }

    return url.pipe(
      tap((postionGroupMtps) => {
        let allPgMtps = [...ctx.getState().positionGroupMtps, postionGroupMtps];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(AppendMtp)
  appendMtp(ctx: StateContext<ProductionStateModel>, action: AppendMtp) {
    return this.productionService.addMtp(action.payload).pipe(
      tap((addedMtp: Mtp[]) => {
        const state = ctx.getState();
        const updatedMtps = [...state.mtps, ...addedMtp];
        ctx.patchState({ mtps: updatedMtps });

        return updatedMtps;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(UpdateMtp)
  updateMtp(ctx: StateContext<ProductionStateModel>, action: UpdateMtp) {
    return this.productionService.updateMtp(action.id, action.payload).pipe(
      tap((res: Mtp) => {
        const updatedMtps = ctx.getState().mtps.map((mtp: Mtp) => {
          if (mtp.mtp_typeid === res.id) {
            mtp.type = res.type;
            mtp.mtp_type = res.type;
            mtp.description = res.description;
          }
          return mtp;
        });

        ctx.getState().positionGroupMtps.forEach((pg: PositionGroupMtps) => {
          pg.mtps.forEach(({ mtp }) => {
            if (mtp.mtp_type_id == res.id) {
              mtp.type = res.type;
              mtp.mtp_type = res.type;
              mtp.description = res.description;
            }
          });
        });

        ctx.patchState({ mtps: updatedMtps });
        return updatedMtps;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(UpdateMtpWeight)
  updateMtpWeight(ctx: StateContext<ProductionStateModel>, action: UpdateMtpWeight) {
    return this.productionService.updateMtpWeight(action.id, action.payload).pipe(
      tap((res: Mtp) => {
        const updatedMtps = ctx.getState().mtps.map((mtp: Mtp) => {
          if (mtp.mtp_typeid === res.id) {
            mtp.value = res.value;
          }
          return mtp;
        });

        ctx.getState().positionGroupMtps.forEach((pg: PositionGroupMtps) => {
          pg.mtps.forEach(({ mtp }) => {
            if (mtp.id == res.id) {
              mtp.value = res.value;
            }
          });
        });

        ctx.patchState({ mtps: updatedMtps });
        return updatedMtps;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(DeleteMtp)
  deleteMtp(ctx: StateContext<ProductionStateModel>, action: DeleteMtp) {
    return this.productionService.deleteMtp(action.id).pipe(
      tap(() => {
        ctx.getState().positionGroupMtps.forEach((positionGroup: PositionGroupMtps) => {
          positionGroup.mtps = positionGroup.mtps.filter((m) => m.mtp.id != action.id);
        });

        const updatedMtps = ctx.getState().mtps.filter((mtp) => mtp.id !== action.id);
        ctx.patchState({ mtps: updatedMtps });

        return updatedMtps;
      })
    );
  }

  @Action(UpdateMtpOrder)
  updateMtpOrder(ctx: StateContext<ProductionStateModel>, { id, payload, data }: UpdateMtpOrder) {
    return this.productionService.updateMtpOrder(id, payload).pipe(
      tap(() => {
        const positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == id);
        positionGroup.mtps = data;

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];

        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(AddMtpToPg)
  addMtpToPg(ctx: StateContext<ProductionStateModel>, action: AddMtpToPg) {
    return this.productionService.addMtpToPg(action.payload).pipe(
      tap((res: any) => {
        const positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
        res.forEach((r: any) => {
          positionGroup.mtps.push({
            id: r.id,
            mtp: ctx.getState().mtps.find((m: Mtp) => m.id == r.mtp),
            order: r.order,
          });
        });

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];

        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(DeletePgMtp)
  deletePgMtp(ctx: StateContext<ProductionStateModel>, action: DeletePgMtp) {
    let positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
    let deletedPgMtp = positionGroup.mtps.find((m) => m.mtp.id == action.id);

    return this.productionService.deletePgMtp(deletedPgMtp.id).pipe(
      tap(() => {
        positionGroup.mtps = positionGroup.mtps.filter((m) => m.mtp.id !== action.id);

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });

        return of(action);
      })
    );
  }

  @Action(GetSkills)
  getSkills(ctx: StateContext<ProductionStateModel>, action: GetSkills) {
    const positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);

    return this.productionService.getSkills(action.pgId, action.season_id).pipe(
      tap((skills: Skill[]) => {
        positionGroup.skills = skills;
        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(AddSkill)
  addSkills(ctx: StateContext<ProductionStateModel>, action: AddSkill) {
    return this.productionService.addSkill(action.payload).pipe(
      tap((skill: Skill) => {
        let positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
        positionGroup.skills.push(skill);

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(UpdateSkill)
  updateSkill(ctx: StateContext<ProductionStateModel>, action: UpdateSkill) {
    return this.productionService.updateSkill(action.skillId, action.payload).pipe(
      tap((s: Skill) => {
        let positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
        positionGroup.skills = positionGroup.skills
          .map((skill: Skill) => {
            if (skill.id == s.id) return s;
            else return skill;
          })
          .sort((a, b) => a.order - b.order);

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(DeleteSkill)
  deleteSkill(ctx: StateContext<ProductionStateModel>, action: DeleteSkill) {
    return this.productionService.deleteSkill(action.skillId).pipe(
      tap(() => {
        const positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
        positionGroup.skills = positionGroup.skills.filter(
          (skill: Skill) => skill.id != action.skillId
        );

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(GetComments)
  getComments(ctx: StateContext<ProductionStateModel>, action: GetComments) {
    const positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
    const url = positionGroup?.comments?.length
      ? of(positionGroup.comments)
      : this.productionService.getComments(action.pgId, action.season_id);

    return url.pipe(
      tap((comments: Comment[]) => {
        positionGroup.comments = comments;
        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(AddComment)
  addComment(ctx: StateContext<ProductionStateModel>, action: AddComment) {
    return this.productionService.addComment(action.payload).pipe(
      tap((comment: Comment) => {
        let positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
        positionGroup.comments = [...positionGroup.comments, comment];

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(UpdateComment)
  updateComment(ctx: StateContext<ProductionStateModel>, action: UpdateComment) {
    return this.productionService.updateComment(action.commentId, action.payload).pipe(
      tap((c: Comment) => {
        let positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
        positionGroup.comments = positionGroup.comments
          .map((comment: Comment) => {
            if (comment.id == c.id) return c;
            else return comment;
          })
          .sort((a, b) => a.order - b.order);

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(UpdateCommentsOrder)
  updateCommentsOrder(ctx: StateContext<ProductionStateModel>, action: UpdateCommentsOrder) {
    return this.productionService.updateCommentsOrder(action.payload).pipe(
      tap(() => {
        const positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
        // positionGroup.comments = action.payload;

        action.payload.forEach((p) => {
          positionGroup.comments.forEach((c: Comment) => {
            if (c.id == p.id) c.order = p.order;
          });
        });
        console.log('positionGroup', positionGroup);
        positionGroup.comments.sort((a, b) => a.order - b.order);

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];

        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(DeleteComment)
  deleteComment(ctx: StateContext<ProductionStateModel>, action: DeleteComment) {
    return this.productionService.deleteComment(action.commentId).pipe(
      tap(() => {
        const positionGroup = ctx.getState().positionGroupMtps.find((pg) => pg.name == action.pgId);
        positionGroup.comments = positionGroup.comments.filter(
          (comment: Comment) => comment.id != action.commentId
        );

        let allPgMtps = [...ctx.getState().positionGroupMtps, positionGroup];
        allPgMtps = [...new Map(allPgMtps.map((item) => [item['name'], item])).values()];
        ctx.patchState({
          positionGroupMtps: allPgMtps,
        });
        return of(action);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<ProductionStateModel>) {
    ctx.setState({
      positionGroupMtps: [],
      mtps: [],
    });
  }
}
