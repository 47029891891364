import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import {
  GetComparisonReport,
  GetWeeksPlayersApi,
  PayloadPlayer,
  PlayerComparisonData,
} from '../interfaces/player-comparison-interface';

const playerComparisonData: PlayerComparisonData = {
  player1: {
    first_name: 'Jerry',
    last_name: 'Ely',
    jersey_number: 1,
    total_plays: 395,
    image: '',
    grades: [
      { name: 'OVERALL', type: 'OVERALL', percentage: '-' },
      { name: 'OVERALL', type: 'Pass', percentage: '82.5' },
      { name: 'OVERALL', type: 'Run', percentage: '65.2' },
      { name: 'FT WORK', type: 'OVERALL SKILL', percentage: '87.5' },
      { name: 'FT WORK', type: 'Pass', percentage: '75.4' },
      { name: 'FT WORK', type: 'Run', percentage: '54.3' },
      { name: 'ASSIGN', type: 'OVERALL SKILL', percentage: '77.2' },
      { name: 'ASSIGN', type: 'Pass', percentage: '82.5' },
      { name: 'ASSIGN', type: 'Run', percentage: '91.0' },
      { name: 'TECH', type: 'OVERALL SKILL', percentage: '62.3' },
      { name: 'TECH', type: 'Pass', percentage: '45.8' },
      { name: 'TECH', type: 'Run', percentage: '80.2' },
      { name: 'EFFORT', type: 'OVERALL SKILL', percentage: '54.9' },
      { name: 'EFFORT', type: 'Pass', percentage: '92.1' },
      { name: 'EFFORT', type: 'Run', percentage: '74.0' },
      { name: 'PRODUCTION', type: 'OVERALL SKILL', percentage: '87.5' },
      { name: 'PRODUCTION', type: 'Pass', percentage: '85.3' },
      { name: 'PRODUCTION', type: 'Run', percentage: '-' },
    ],
  },
  player2: {
    first_name: 'John',
    last_name: 'Berry',
    jersey_number: 2,
    total_plays: 395,
    image: '',
    grades: [
      { name: 'OVERALL', type: 'OVERALL', percentage: '0' },
      { name: 'OVERALL', type: 'Pass', percentage: '-' },
      { name: 'OVERALL', type: 'Run', percentage: '54.2' },
      { name: 'FT WORK', type: 'OVERALL SKILL', percentage: '92.3' },
      { name: 'FT WORK', type: 'Pass', percentage: '88.5' },
      { name: 'FT WORK', type: 'Run', percentage: '45.2' },
      { name: 'ASSIGN', type: 'OVERALL SKILL', percentage: '65.3' },
      { name: 'ASSIGN', type: 'Pass', percentage: '80.4' },
      { name: 'ASSIGN', type: 'Run', percentage: '89.1' },
      { name: 'TECH', type: 'OVERALL SKILL', percentage: '74.3' },
      { name: 'TECH', type: 'Pass', percentage: '91.2' },
      { name: 'TECH', type: 'Run', percentage: '55.0' },
      { name: 'EFFORT', type: 'OVERALL SKILL', percentage: '78.3' },
      { name: 'EFFORT', type: 'Pass', percentage: '85.6' },
      { name: 'EFFORT', type: 'Run', percentage: '65.2' },
      { name: 'PRODUCTION', type: 'OVERALL SKILL', percentage: '88.2' },
      { name: 'PRODUCTION', type: 'Pass', percentage: '0' },
      { name: 'PRODUCTION', type: 'Run', percentage: '45.6' },
    ],
  },
  player3: {
    first_name: 'Tomy',
    last_name: 'Erre',
    jersey_number: 3,
    total_plays: 395,
    image: '',
    grades: [
      { name: 'OVERALL', type: 'OVERALL', percentage: '-' },
      { name: 'OVERALL', type: 'Pass', percentage: '92.0' },
      { name: 'OVERALL', type: 'Run', percentage: '78.5' },
      { name: 'FT WORK', type: 'OVERALL SKILL', percentage: '45.7' },
      { name: 'FT WORK', type: 'Pass', percentage: '0' },
      { name: 'FT WORK', type: 'Run', percentage: '90.1' },
      { name: 'ASSIGN', type: 'OVERALL SKILL', percentage: '74.8' },
      { name: 'ASSIGN', type: 'Pass', percentage: '61.0' },
      { name: 'ASSIGN', type: 'Run', percentage: '89.9' },
      { name: 'TECH', type: 'OVERALL SKILL', percentage: '55.9' },
      { name: 'TECH', type: 'Pass', percentage: '75.3' },
      { name: 'TECH', type: 'Run', percentage: '82.0' },
      { name: 'EFFORT', type: 'OVERALL SKILL', percentage: '93.5' },
      { name: 'EFFORT', type: 'Pass', percentage: '68.2' },
      { name: 'EFFORT', type: 'Run', percentage: '77.4' },
      { name: 'PRODUCTION', type: 'OVERALL SKILL', percentage: '-' },
      { name: 'PRODUCTION', type: 'Pass', percentage: '70.9' },
      { name: 'PRODUCTION', type: 'Run', percentage: '82.5' },
    ],
  },
};

@Injectable({
  providedIn: 'root',
})
export class PlayerComparisonService {
  constructor(private http: HttpClient) {}

  getGamesPracticesPlayers(
    pgId: PositionGroup['name'],
    seasonId: Season['id'],
    sob: SOB['value']
  ): Observable<GetWeeksPlayersApi> {
    return this.http.get<GetWeeksPlayersApi>(
      `${environment.main_url}/Player/weeks_matches_pgs/${pgId}/${seasonId}/${sob}/`
    );
  }

  // getComparisonReport(
  //   seasonId: Season['id'],
  //   sob: SOB['value'],
  //   payload: ComparisonReportPayload[]
  // ): Observable<GetComparisonReport> {
  //   return this.http.post<GetComparisonReport>(
  //     `${environment.main_url}/PlayerReport/player_comparison/${sob}/${seasonId}/html/`,
  //     payload
  //   );
  // }

  getComparisonReportData(
    seasonId: Season['id'],
    pg: PositionGroup,
    sob: SOB['value'],
    payloadPlayers: PayloadPlayer[]
  ): Observable<PlayerComparisonData> {
    // const numberOfPlayers = payload.length;
    // const selectedPlayers = Object.keys(playerComparisonData)
    //   .slice(0, numberOfPlayers)
    //   .reduce((result, key) => {
    //     result[key] = playerComparisonData[key];
    //     return result;
    //   }, {} as PlayerComparisonData);

    // return of(selectedPlayers); // Mocked response as an observable
    const payload = {
      season_id: seasonId,
      side_of_ball: sob,
      position_group: pg.name,
      players: [...payloadPlayers],
    };
    return this.http.post<PlayerComparisonData>(
      `${environment.main_url}/PlayerReport/v3/player-comparison/`,
      payload
    );
  }
}
