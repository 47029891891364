import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { Season } from '@shared/interfaces/season.interface';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import { UploadProgressI, VideoProgressI } from '@shared/interfaces/data-upload-interface';
import {
  HudlCollegeUploadInitialData,
  HudlCollegeUploadProgress,
  HudlCollegeVideoProgress,
  RemoveHudlCollegeUploadedData,
} from './hudl-college-data-upload.action';

export interface hudlCollegeDataUploadStateI {
  sob: SOB;
  season: Season;
  week: WeeksApi;
  matchType: string;
  gameNo: string;
  uploadProgress: UploadProgressI;
  videoProgress: VideoProgressI;
}
export interface HudlCollegeDataUploadStateModel {
  hudlCollegeDataUpload: hudlCollegeDataUploadStateI[];
}

@State<HudlCollegeDataUploadStateModel>({
  name: 'HudlCollegeDataUpload',
  defaults: {
    hudlCollegeDataUpload: [],
  },
})
@Injectable()
export class HudlCollegeDataUploadState {
  constructor() {}

  @Action(HudlCollegeUploadInitialData)
  hudlCollegeUploadSOB(
    ctx: StateContext<HudlCollegeDataUploadStateModel>,
    { sob, season, payload }: HudlCollegeUploadInitialData
  ) {
    let state = ctx.getState().hudlCollegeDataUpload;
    state.push({
      sob: sob,
      season: season,
      week: payload.week,
      matchType: payload.match_type,
      gameNo: payload.game_number,
      uploadProgress: { isUploading: false, percent: 0 },
      videoProgress: {
        isUploading: false,
        filesUploaded: 0,
        totalFiles: 0,
        percent: 0,
      },
    });

    ctx.patchState({
      hudlCollegeDataUpload: state,
    });
  }

  @Action(HudlCollegeUploadProgress)
  hudlCollegeUploadProgress(
    ctx: StateContext<HudlCollegeDataUploadStateModel>,
    { sobValue, payload }: HudlCollegeUploadProgress
  ) {
    let state = ctx.getState().hudlCollegeDataUpload;
    state = state.map((s) => {
      if (s.sob.value == sobValue) s.uploadProgress = payload;
      return s;
    });

    ctx.patchState({
      hudlCollegeDataUpload: state,
    });
  }

  @Action(HudlCollegeVideoProgress)
  hudlCollegeVideoProgress(
    ctx: StateContext<HudlCollegeDataUploadStateModel>,
    { sobValue, payload }: HudlCollegeVideoProgress
  ) {
    let state = ctx.getState().hudlCollegeDataUpload;
    state = state.map((s) => {
      if (s.sob.value == sobValue) s.videoProgress = payload;
      return s;
    });

    ctx.patchState({
      hudlCollegeDataUpload: state,
    });
  }

  @Action(RemoveHudlCollegeUploadedData)
  removeHudlCollegeUploadedData(
    ctx: StateContext<HudlCollegeDataUploadStateModel>,
    { sobValue }: RemoveHudlCollegeUploadedData
  ) {
    let state = ctx.getState().hudlCollegeDataUpload;
    state = state.filter((s) => {
      if (s.sob.value != sobValue) return s;
    });

    ctx.patchState({
      hudlCollegeDataUpload: state,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<HudlCollegeDataUploadStateModel>) {
    ctx.setState({
      hudlCollegeDataUpload: [],
    });
  }
}
