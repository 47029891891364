import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { AuthService } from '@auth/auth.service';
import { SignOut } from '@NgXs/authentication/actions/signOut.actions';
import { NotificationService } from '@shared/services/notification/notification.service';
import { FuseLoadingService } from '@fuse/services/loading';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isNetwork = false;
  isServerError = false;
  storageMedium: Storage;

  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;

  constructor(
    private authService: AuthService,
    private store: Store,
    private notificationService: NotificationService,
    private fuseLoadingService: FuseLoadingService
  ) {
    this.storageMedium$.subscribe((storageMedium: Storage) => {
      this.storageMedium = storageMedium;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.isNetwork = false;
    this.isServerError = false;

    this.fuseLoadingService.show();

    let request = req.clone();
    if (!!this.authService.accessToken) {
      if (request.url != `/api/token/refresh/` && request.url != `/api/token/`) {
        request = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.accessToken}`,
          },
        });
      }
    }

    const handle403Error = () => {
      return this.authService.newRefreshToken().pipe(
        switchMap(() => {
          // Retry the previously failed API request with the new token
          if (!!this.authService.accessToken) {
            const clonedReq = req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.authService.accessToken}`,
              },
            });
            return next.handle(clonedReq);
          }
          // If there's still no access token, return an error
          return throwError('Access token is still missing after refresh.');
        })
      );
    };

    return next.handle(request).pipe(
      finalize(() => {
        this.fuseLoadingService.hide();
      }),
      catchError((error) => {
        console.log('error', error);
        let { status } = error;
        if (status == 500) {
          if (!this.isServerError)
            this.notificationService.error('Error occurred!', 'Internal Server Error!');
          this.isServerError = true;
        } else if (status == 404) this.notificationService.error('Error occurred!', 'Not Found!');
        else if (status == 413)
          this.notificationService.error('Error occurred!', 'File size exceeded!');
        else if (status == 0) {
          if (!this.isNetwork)
            this.notificationService.error('Error occurred!', 'Might be the server error.');

          this.isNetwork = true;
        } else if (error instanceof HttpErrorResponse && status === 403) {
          this.fuseLoadingService.show();
          return handle403Error();
        } else if (
          error instanceof HttpErrorResponse &&
          status === 401 &&
          request.url.includes('/api/token/refresh/')
        ) {
          if (this.storageMedium.getItem('login')) {
            this.store.dispatch(new SignOut());

            this.notificationService.warning(
              'Your session has expired due to inactivity. Please login again.'
            );
          }
        } else if (
          !request.url.includes('/api/token/refresh/') &&
          !request.url.includes('/retrieve-match-data/') &&
          !request.url.includes('/match-data-dump/') &&
          !request.url.includes('/scouting-data-dump/') &&
          !request.url.includes('/player-dump/') &&
          !request.url.includes('/Player/player_upload/')
        ) {
          if (typeof error.error == 'string') {
            this.notificationService.error(error.error);
          } else if (Array.isArray(error.error)) {
            for (const err of error.error) {
              if (typeof err == 'object') {
                for (const key in err) {
                  if (key !== 'non_field_errors') {
                    this.notificationService.error(`${key}`, `${err[key]}`);
                  } else {
                    this.notificationService.error(err[key]);
                  }
                }
              } else this.notificationService.error(err);
            }
          } else {
            for (const key in error.error) {
              if (Array.isArray(error.error[key])) {
                for (const err of error.error[key]) {
                  if (key !== 'non_field_errors') {
                    this.notificationService.error(`${key}`, `${err}`);
                  } else {
                    this.notificationService.error(err);
                  }
                }
              } else if (typeof error.error[key] !== 'object') {
                if (
                  !(
                    (status === 401 && error.url.includes('xos')) ||
                    (status === 401 && error.url.includes('qwikcut'))
                  )
                )
                  this.notificationService.error(`${key}`, `${error.error[key]}`);
              }
            }
          }
        }

        return throwError(() => error);
      })
    );
  }
}
