<h3 class="px-4 font-medium text-2xl" mat-dialog-title>
  Select {{ currentSob?.value == Sob.SpecialTeamsValue ? 'Phase(s)' : 'Position Group(s)' }}
</h3>

<mat-dialog-content>
  <span class="label">
    {{ currentSob?.value == Sob.SpecialTeamsValue ? 'Phases' : 'Position Group' }}</span
  >

  <div class="w-full">
    <ng-container *ngIf="currentSob?.value == Sob.SpecialTeamsValue; else showMultiSelect">
      <og-select-position-group
        (positionGroupChangeEvent)="selectedPgs = [$event]"
      ></og-select-position-group>
    </ng-container>

    <ng-template #showMultiSelect>
      <og-multi-select-position-groups
        (pgsChange)="selectedPgs = $event"
      ></og-multi-select-position-groups>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="basic" mat-dialog-close class="rounded-md">Close</button>
  <button
    mat-raised-button
    class="rounded-md"
    color="primary"
    [disabled]="!selectedPgs.length || (isLoading$ | async)"
    (click)="closeDialog()"
  >
    View
  </button>
</mat-dialog-actions>
