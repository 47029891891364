import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { NotificationService } from '@shared/services/notification/notification.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Check the authentication status
    const isAuthenticated = JSON.parse(localStorage.getItem('login'))?.access;
    const isGuestUser = sessionStorage.getItem('guest_token');

    if (isGuestUser) {
      localStorage.clear();
      sessionStorage.clear();
      this.notificationService.warning('Error occurred!. Please login again.');
      this.router.navigate(['/authentication/sign-out']);
      return false as any;
    }

    if (!isAuthenticated) {
      this.router.navigate(['/authentication/sign-out']);
    }
    return isAuthenticated;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, state);
  }
}
