import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Permission } from '../../../../core/permissions/enums/permissions.enum';
import { HasPermissionDirective } from '../../../../core/permissions/directives/has-permissions.directive';

@Component({
  selector: 'og-buttons-renderer',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, HasPermissionDirective],
  templateUrl: './buttons-renderer.component.html',
  styleUrls: ['./buttons-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsRendererComponent implements ICellRendererAngularComp {
  params: any;
  skipPlay: boolean;
  played: boolean;

  agInit(params: { data: { skip_play_bool: boolean; played_rbac: boolean } }): void {
    this.params = params;
    this.skipPlay = params.data.skip_play_bool;
    this.played = params.data.played_rbac;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick(mode: string) {
    if (this.params.onClick instanceof Function) {
      this.params.onClick(this.params.node.data, mode);
    }
  }

  onDelete() {
    if (this.params.onDelete instanceof Function) {
      this.params.onDelete(this.params.node.data);
    }
  }

  protected readonly Permission = Permission;
}
