import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { Player, PlayerPosition } from '@shared/interfaces/player';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridModule } from 'ag-grid-angular';
import { CheckboxRendererComponent } from './components/checkbox-renderer/checkbox-renderer.component';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { MatchData } from '../../interfaces/show-matches.interface';
import { Season } from '@shared/interfaces/season.interface';

@Component({
  selector: 'og-player-match-grid',
  standalone: true,
  imports: [CommonModule, AgGridModule],
  templateUrl: './player-match-grid.component.html',
  styleUrls: ['./player-match-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerMatchGridComponent implements OnInit, OnChanges, OnDestroy {
  context: any;
  rowHeight = 50;
  columnDefs: ColDef[];
  gridApi: GridApi;

  @Input() isSubstitution = false;
  @Input() matchId!: MatchData['id'];
  @Input() seasonId!: Season['id'];
  @Input() players: Player[] = [];
  @Input() positionGroup: PositionGroup[] = [];

  @Output() selectedPlayerEvent = new EventEmitter();

  private unsubscribeAll: Subject<any> = new Subject<any>();

  get colDefs() {
    return [
      {
        field: 'checked',
        headerName: 'Select',
        rowDrag: false,
        // width: 90,
        flex: 1,
        cellRenderer: CheckboxRendererComponent,
        cellRendererParams: (params) => {
          return {
            pgs: this.positionGroup.filter((pg) => params.data.playerpgs?.includes(pg.name)),
          };
        },
      },
      {
        headerName: 'Jersey',
        // width: 95,
        flex: 1,
        valueGetter: (params) => {
          if (
            'season_wise_player_detail' in params.data &&
            params.data.season_wise_player_detail[this.seasonId]
          )
            return params.data.season_wise_player_detail[this.seasonId].jersey;

          return params.data.jersey;
        },
        cellClassRules: {
          notSelected: function (params) {
            return params.data.selected == false;
          },
          selected: function (params) {
            return params.data.selected == true;
          },
        },
      },
      {
        field: 'firstname',
        headerName: 'First name',
        // width: 140,
        flex: 1,
        cellClassRules: {
          notSelected: function (params) {
            return params.data.selected == false;
          },
          selected: function (params) {
            return params.data.selected == true;
          },
        },
      },
      {
        field: 'lastname',
        headerName: 'Last name',
        // width: 140,
        flex: 1,
        cellClassRules: {
          notSelected: function (params) {
            return params.data.selected == false;
          },
          selected: function (params) {
            return params.data.selected == true;
          },
        },
      },
    ];
  }

  constructor(private cdr: ChangeDetectorRef) {
    this.context = { parentComponent: this };
    this.columnDefs = this.colDefs;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('isSubstitution' in changes) {
      this.columnDefs = this.colDefs;

      if (changes['isSubstitution'].currentValue) {
        this.columnDefs.push({
          field: 'positions',
          headerName: 'Position',
          // width: 300,
          flex: 2,
          headerClass: 'left-aligned',
          cellStyle: {
            'justify-content': 'flex-start',
          },
          cellClassRules: {
            notSelected: function (params) {
              return params.data.selected == false;
            },
            selected: function (params) {
              return params.data.selected == true;
            },
          },
        });
      } else {
        this.columnDefs.push({
          headerName: 'Position Group',
          // width: 300,
          flex: 2,
          headerClass: 'left-aligned',
          valueGetter: (params) => {
            if (params.data.season_wise_pgs)
              return params.data.season_wise_pgs[this.seasonId].map((pg) => pg.position_group__pg);
          },
          cellStyle: {
            'justify-content': 'flex-start',
          },
          cellClassRules: {
            notSelected: function (params) {
              return params.data.selected == false;
            },
            selected: function (params) {
              return params.data.selected == true;
            },
          },
        });
      }

      this.gridApi?.setColumnDefs(this.columnDefs);
    }
  }

  onGridReady(params?: GridReadyEvent) {
    this.gridApi = params.api;
  }

  checkboxChange(player: Player, event: boolean): void {
    if (!this.isSubstitution) {
      this.players = this.players.map((p: Player) => {
        if (p.id == player.id) {
          p.disable = false;
          p.checked = !event;
        } else p.disable = p.selected || !event;
        return p;
      });

      // this.setHeight(player.checked);
    } else {
      player.checked = player.selected = player.disable = true;
      this.selectedPlayerEvent.emit({
        jersey: player.jersey,
        match_id: this.matchId,
        player_id: player.id,
        position: player['position_id'],
        position_name: player.positions,
      });
    }

    // this.gridApi.refreshCells();
    this.cdr.detectChanges();
  }

  positionChange(player: Player, position: PlayerPosition): void {
    this.players = this.players.map((player: Player) => {
      player.disable = player.selected;
      return player;
    });

    player.checked = player.selected = player.disable = true;

    // this.setHeight(false);

    this.selectedPlayerEvent.emit({
      jersey: player.season_wise_player_detail[this.seasonId]['jersey'],
      match_id: this.matchId,
      player_id: player.id,
      position: position.id,
      position_name: position.name,
    });
  }

  // setHeight(isChecked: boolean) {
  //   this.rowHeight = isChecked ? 65 : 50;
  //   this.gridApi.redrawRows();
  // }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
