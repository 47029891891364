import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { QueryParamsService } from '@shared/services/queryParams/query-params.service';
import { Season } from '@shared/interfaces/season.interface';
import { SelectedSeason } from '../../states/season/season.action';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeasonSelectors } from '@shared/states/season/season.selector';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule, KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { SeasonEnum } from 'app/modules/seasons/components/add-season/enums/season.enum';
import { SobSelectors } from 'app/core/layout/components/select-sob/state/sob.selector';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'og-select-season',
  standalone: true,
  templateUrl: './select-season.component.html',
  styleUrls: ['./select-season.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSeasonComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    KeyValuePipe,
    NgForOf,
    NgIf,
    MatExpansionModule,
  ],
})
export class SelectSeasonComponent implements OnInit, ControlValueAccessor {
  seasonEnum = SeasonEnum;
  seasons!: Season[];
  formattedSeason: { [year: number]: Season[] } = {};
  selectedSessionSeason!: Season;
  selectedSeason!: Season | string;

  @Input() placeholder = '';
  @Input() showNested = false;
  @Input() showDashed = false;
  @Input() hideCurrentSeason = false;
  @Input() showDefaultAtSignup = false;
  @Input() fieldVariation = '';

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  seasons$: Observable<Season[] | null>;

  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private queryParamsService: QueryParamsService
  ) {
    this.seasons$ = this.store.select(SeasonSelectors.getAllSeasons);
  }

  ngOnInit(): void {
    this.seasons$.pipe(takeUntil(this.unsubscribeAll)).subscribe((seasons) => {
      this.seasons = seasons;
      this.formattedSeason = this.getFormattedSeasons(this.seasons);

      let defaultSeason: Season;
      defaultSeason = this.getDefaultSeason(this.seasons);

      let selectedSeason = defaultSeason;
      let paramsSeason: Season['id'] = this.route.snapshot.queryParams['season'];
      if (paramsSeason) {
        selectedSeason = this.seasons.find((season) => season.id == paramsSeason) || defaultSeason;
      } else {
        this.queryParamsService.updateQueryParams({
          sob: this.store.selectSnapshot(SobSelectors.selectedSob).value,
          season: selectedSeason?.id,
        });
      }

      this.selectedSessionSeason = selectedSeason;
      this.store.dispatch(new SelectedSeason(selectedSeason));
      if (this.hideCurrentSeason)
        this.seasons = this.seasons.filter(
          (season) => season.id != this.store.selectSnapshot(SeasonSelectors.selectedSeason).id
        );

      this.cdr.detectChanges();
    });
  }

  writeValue(season: Season) {
    this.selectedSeason = season;
    if (season) this.propagateChange(season);
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  returnZero() {
    return 0;
  }

  getFormattedSeasons(seasons: Season[]): { [year: number]: Season[] } {
    const formattedSeasons: { [year: number]: Season[] } = {};
    if (seasons) {
      seasons.forEach((season) => {
        const year = season.year.toString();
        if (!formattedSeasons[year]) {
          formattedSeasons[year] = [];
        }
        formattedSeasons[year].push(season);
      });
    }

    return Object.keys(formattedSeasons)
      .sort((a: any, b: any) => b - a)
      .reduce((acc, key) => {
        acc[key + ' '] = formattedSeasons[key];
        return acc;
      }, {});
  }

  getDefaultSeason(seasons: Season[]): Season {
    const defaultSeason = seasons.find((eachSeason) => eachSeason.default_season == true);
    return defaultSeason || seasons[0];
  }

  seasonChange(season: Season): void {
    this.selectedSessionSeason = season;
    this.store.dispatch(new SelectedSeason(season));
    this.queryParamsService.updateQueryParams({ season: this.selectedSessionSeason.id });
    this.accordion.closeAll();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
