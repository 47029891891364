import { UserProfileState, UserProfileStateModel } from './user-profile.state';
import { Selector } from '@ngxs/store';
import { UserData } from '../interfaces/user-profile.interface';

export class UserProfileSelectors {
  @Selector([UserProfileState])
  static getUserProfileState(state: UserProfileStateModel): UserData {
    return state.userProfile;
  }
}
