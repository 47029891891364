import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDragEndEvent,
  RowDragEnterEvent,
} from 'ag-grid-community';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'og-position-grid',
  standalone: true,
  imports: [CommonModule, AgGridModule],
  templateUrl: './position-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionGridComponent implements OnInit, OnDestroy {
  @Input() columnDefs: ColDef[] = [];
  @Input() rowData: any[] = [];
  @Input() context: any;
  @Input() overlayNoRowsTemplate: string =
    '<span class="ag-overlay-loading-center">No rows to display</span>';
  @Input() rowDragManaged: boolean = false;
  @Input() suppressRowDrag: boolean = false;
  @Output() rowDragEnter = new EventEmitter<RowDragEnterEvent>();
  @Output() rowDragEnd = new EventEmitter<RowDragEndEvent>();
  @Output() cellValueChanged = new EventEmitter<any>();
  @Output() gridReady = new EventEmitter<GridReadyEvent>();
  @Output() selectionChanged = new EventEmitter<any>();

  private unsubscribeAll: Subject<any> = new Subject<any>();
  private gridApi: GridApi;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onRowDragEnter(event: RowDragEnterEvent): void {
    this.rowDragEnter.emit(event);
  }

  onRowDragEnd(event: RowDragEndEvent): void {
    this.rowDragEnd.emit(event);
  }

  onCellValueChanged(event: any): void {
    this.cellValueChanged.emit(event);
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridReady.emit(event);
    this.gridApi.sizeColumnsToFit();
  }

  onSelectionChanged(event: any): void {
    this.selectionChanged.emit(event);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
