import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, tap, of, iif } from 'rxjs';
import { GetBoxOptions, ResetBoxOptions } from './box-option.action';
import { SystemSettingService } from '@shared/services/system-setting/system-setting.service';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import _ from 'lodash';

export interface BoxOptionStateModel {
  allBoxOptions: object;
  sobAlias: string;
}

@State<BoxOptionStateModel>({
  name: 'boxOption',
  defaults: {
    allBoxOptions: {},
    sobAlias: null,
  },
})
@Injectable()
export class BoxOptionState {
  constructor(private settingService: SystemSettingService) {}

  @Action(GetBoxOptions)
  getBoxOptions(
    { getState, patchState }: StateContext<BoxOptionStateModel>,
    { sobAlias, seasonId }: GetBoxOptions
  ): Observable<{ [p: string]: string }> {
    return this.settingService.getBoxOptionsBySob(sobAlias, seasonId).pipe(
      tap((newAllBoxOptions: any) => {
        patchState({
          allBoxOptions: newAllBoxOptions,
          sobAlias: sobAlias,
        });
      })
    );
  }

  @Action(ResetBoxOptions)
  @Action(ClearAllStates)
  clearState(ctx: StateContext<BoxOptionStateModel>) {
    ctx.setState({
      allBoxOptions: {},
      sobAlias: null,
    });
  }
}
