import { OppData, Opponent } from '@shared/interfaces/opponent/opponent.interface';

export class AddOpponent {
  static readonly type = '[Opponent] Add Opponent';
  constructor(public payload: { name: string; season_id: number }) {}
}

export class DeleteOpponent {
  static readonly type = '[Opponent] Delete Opponent';
  constructor(public payload: Opponent) {}
}

export class EditOpponent {
  static readonly type = '[Opponent] Edit Opponent ';
  constructor(public payload: Opponent) {}
}

export class GetOpponents {
  static readonly type = '[Opponent] Get Opponents';

  constructor(public payload: number) {}
}

export class AddSchedule {
  static readonly type = '[Opponent] Add Schedule';
  constructor(public payload: any) {}
}

export class EditSchedule {
  static readonly type = '[Opponent] Edit Schedule';
  constructor(public payload: any) {}
}

export class DeleteSchedule {
  static readonly type = '[Opponent] Delete Schedule';
  constructor(public payload: OppData) {}
}
