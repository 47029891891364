<mat-form-field class="w-full">
  <mat-select
    multiple
    panelWidth="257px"
    [placeholder]="currentSob?.value == Sob.SpecialTeamsValue ? 'Phases' : 'Position Group'"
    [formControl]="selectedPGs"
    (opened)="onMatSelectOpened()"
    (closed)="onMatSelectClosed()"
  >
    <!-- New option for selecting all items -->
    <mat-optgroup class="padding-left-6" (click)="toggleAllSelection()">
      <mat-checkbox
        [indeterminate]="
          selectedPGs.value.length < (positionGroups$ | async).length &&
          selectedPGs.value.length > 0
        "
        [checked]="isAllSelected && selectedPGs.value.length > 0"
      >
        Select All
      </mat-checkbox>
    </mat-optgroup>
    <mat-option
      *ngFor="let position of positionGroups"
      [value]="position"
      (click)="checkForSelectAll()"
    >
      {{ position.pg_name }} ({{ position.pg }})
    </mat-option>
  </mat-select>
</mat-form-field>
