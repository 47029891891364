<!-- Button -->
<button mat-icon-button class="mt-[6px]" [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img
      alt="user"
      class="w-8 h-8 rounded-full"
      *ngIf="showAvatar && (userData$ | async)?.coach.avatar"
      [src]="
        (userData$ | async)?.coach.avatar
          ? (userData$ | async)?.coach.avatar
          : 'assets/images/avatar/avatar-male-profile-gray-person.jpg'
      "
      style="height: 2rem !important"
    />
    <mat-icon
      class="icon-size-8"
      *ngIf="!showAvatar || !(userData$ | async)?.coach.avatar"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{ (userData$ | async)?.email }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>

  <ng-container *ngIf="isMobileScreen$ | async">
    <og-select-sob [showAbbreviated]="true"></og-select-sob>
    <mat-divider class="my-2"></mat-divider>
  </ng-container>

  <button mat-menu-item (click)="redirectTo('user-profile')">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button>
  <button mat-menu-item (click)="settingsDrawer.toggle()">
    <mat-icon [svgIcon]="'mat_outline:color_lens'"></mat-icon>
    <span>Theme Settings</span>
  </button>
  <!-- <button mat-menu-item (click)="redirectTo('user-settings')">
    <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
    <span>Settings</span>
  </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

<fuse-drawer
  class="w-screen min-w-screen sm:w-full sm:min-w-[448px] z-999"
  fixed
  [mode]="'over'"
  [name]="'settingsDrawer'"
  [position]="'right'"
  #settingsDrawer
>
  <settings [settingsDrawer]="settingsDrawer"></settings>
</fuse-drawer>
