import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { ThemeSelectors } from '@NgXs/Theme/theme.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Login } from '@NgXs/authentication/actions/login.action';
import { LoadingSelectors } from '@NgXs/loading/loading.selectors';
import { StartLoading, StopLoading } from '@NgXs/loading/loading.action';

@Component({
  selector: 'auth-sign-in',
  templateUrl: './sign-in.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  standalone: true,
  imports: [
    RouterLink,
    FuseAlertComponent,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    AsyncPipe,
    NgOptimizedImage,
    NgForOf,
  ],
})
export class AuthSignInComponent implements OnInit, OnDestroy {
  @Select(ThemeSelectors.currentTheme) currentTheme$: Observable<'dark' | 'light' | 'auto'>;
  @Select(LoadingSelectors.isLoading) isLoading$: Observable<boolean>;

  alerts: { type: FuseAlertType; message: string }[] = [];
  signInForm: UntypedFormGroup;
  showAlert: boolean = false;

  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private store: Store
  ) {
    localStorage.clear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.signInForm = this._formBuilder.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    });

    this.signInForm.valueChanges.subscribe((form) => {
      this._cdr.detectChanges();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   */
  signIn(): void {
    // Return if the form is invalid
    if (this.signInForm.invalid) {
      return;
    }

    // Disable the form
    this.signInForm.disable();
    this.store.dispatch(new StartLoading());

    // Hide the alert
    this.showAlert = false;

    // Sign in
    // Dispatch the Login action with credentials as the payload
    const email = this.signInForm.get('email').value;
    const password = this.signInForm.get('password').value;
    this.store
      .dispatch(new Login({ email, password }))
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (state) => {
          this.alerts = [];
          if (state.auth.isAuthenticated) {
            const redirectURL =
              this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/dashboard';
            this._router.navigateByUrl(redirectURL);
          } else {
            const errors = state.auth.error as any;
            if (
              errors &&
              errors.signIn &&
              Object.keys(errors?.signIn).length > 0 &&
              errors?.signIn?.error?.non_field_errors
            ) {
              const errorsArr = errors?.signIn?.error.non_field_errors as string[];
              errorsArr?.forEach((error) => {
                console.log({ error });
                this.alerts.push({
                  type: 'error',
                  message: error,
                });
              });
            } else {
              this.alerts.push({
                type: 'error',
                message: 'Invalid Username/Email or password',
              });
            }
            // Show the alert
            this.showAlert = true;
          }
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.signInForm.enable();
          this.store.dispatch(new StopLoading());
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
