export class GetInfiniteVideo {
  static readonly type = '[InfiniteVideo] Get InfiniteVideo';
  constructor() {}
}

export class SetInfiniteVideoUrl {
  static readonly type = '[set InfiniteVideoUrl] Set InfiniteVideoUrl';
  constructor(public hlsUrl: string) {}
}

export class SetSelectedPlayIndex {
  static readonly type = '[set SelectedPlayIndex] Set SelectedPlayIndex';
  constructor(public playIndex: number) {}
}

export class SetTotalPlays {
  static readonly type = '[set totalPlays] Set totalPlays';
  constructor(public totalPlays: number) {}
}
