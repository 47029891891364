<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
  <div class="flex md:w-full md:max-w-6xl sm:rounded-2xl sm:shadow overflow-hidden sm:bg-card">
    <div
      style="
        background: url('assets/images/backgrounds/login-screen.jpg');
        background-size: cover;
        background-position: center;
      "
      class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden dark:border-r"
    >
      <!-- Content -->
      <div class="z-10 relative w-full max-w-2xl">
        <!--                <div>text</div>-->
      </div>
    </div>
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-auto flex justify-center items-center">
          <img
            [ngSrc]="
              (currentTheme$ | async) === 'light'
                ? 'assets/images/logo/logo-light2_.svg'
                : 'assets/images/logo/logo-dark.png'
            "
            alt="logo"
            height="83"
            style="max-width: 180px"
            width="100"
          />
        </div>

        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
          Forgot password?
        </div>
        <div class="mt-0.5 font-medium">Fill the form to reset your password</div>

        <!-- Alert -->
        <fuse-alert
          class="mt-8 -mb-4"
          *ngIf="showAlert"
          [appearance]="'outline'"
          [showIcon]="false"
          [type]="alert.type"
          [@shake]="alert.type === 'error'"
        >
          {{ alert.message }}
        </fuse-alert>

        <!-- Forgot password form -->
        <form class="mt-8" [formGroup]="forgotPasswordForm" #forgotPasswordNgForm="ngForm">
          <!-- Email field -->
          <mat-form-field class="w-full">
            <mat-label>Email address</mat-label>
            <input id="email" matInput [formControlName]="'email'" />
            <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
              Email address is required
            </mat-error>
            <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
              Please enter a valid email address
            </mat-error>
          </mat-form-field>

          <!-- Submit button -->
          <button
            class="fuse-mat-button-large w-full mt-3"
            mat-flat-button
            [color]="'primary'"
            [disabled]="(isLoading$ | async) || forgotPasswordForm.invalid"
            (click)="sendResetLink()"
          >
            <span *ngIf="!(isLoading$ | async)"> Send reset link </span>
            <mat-progress-spinner
              *ngIf="isLoading$ | async"
              [diameter]="24"
              [mode]="'indeterminate'"
            ></mat-progress-spinner>
          </button>

          <!-- Form footer -->
          <div class="mt-8 text-md font-medium text-secondary">
            <span>Return to</span>
            <a
              class="ml-1 text-primary-500 hover:underline"
              [routerLink]="['/authentication/sign-in']"
              >sign in
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
