import { UserData, EditDetailsPayload, CoachData } from '../interfaces/user-profile.interface';

export class LoadUserProfile {
  static readonly type = '[UserProfile] Load';
}

export class EditUserProfileDetails {
  static readonly type = '[UserProfile] Edit Details';
  constructor(public payload: EditDetailsPayload) {}
}

export class EditUserProfileAvatar {
  static readonly type = '[UserProfile] Edit Avatar';
  constructor(
    public payload: FormData,
    public id: CoachData['id']
  ) {}
}

export class UserProfileLoaded {
  static readonly type = '[UserProfile] Loaded';
  constructor(public userProfile: UserData) {}
}
