import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'og-pills-renderer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pills-renderer.component.html',
  styleUrls: ['./pills-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillsRendererComponent implements ICellRendererAngularComp {
  params: any;
  fieldValue: any;

  agInit(params: any): void {
    this.params = params;
    this.fieldValue = params.value;
  }

  refresh(params: any): boolean {
    this.params = params;
    this.fieldValue = params.value;
    return true;
  }
}
