import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { LoadWeeks, ResetSelectedWeek, SelectedWeek } from '@shared/states/weeks/weeks.action';
import { NotificationService } from '@shared/services/notification/notification.service';
import { SeasonSelectors } from '@shared/states/season/season.selector';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Season } from '@shared/interfaces/season.interface';
import { WeeksSelector } from '@shared/states/weeks/weeks.selector';

@Component({
  selector: 'og-select-week',
  standalone: true,
  imports: [CommonModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  templateUrl: './select-week.component.html',
  styleUrls: ['./select-week.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWeekComponent implements OnInit, OnDestroy {
  @Select(SeasonSelectors.selectedSeason) selectedSeason$: Observable<Season>;
  @Select(WeeksSelector.selectedWeek) selectedWeek$: Observable<WeeksApi | null>;

  weeks: WeeksApi[] = [];
  selectedWeek: FormControl = new FormControl({ value: null, disabled: true });

  @Input() width = null;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectedSeason$
      .pipe(takeUntil(this.destroy$))
      .subscribe((season) => this.handleSeasonChange(season));

    this.selectedWeek$
      .pipe(takeUntil(this.destroy$))
      .subscribe((week) => this.selectedWeek.setValue(week));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(new ResetSelectedWeek());
  }

  onWeekChange(): void {
    this.store.dispatch(new SelectedWeek(this.selectedWeek.value));
  }

  private handleSeasonChange(season: any): void {
    if (!season?.id) return;

    this.store
      .dispatch(new LoadWeeks(season.id))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.handleLoadWeeksResponse(res));
  }

  private handleLoadWeeksResponse(res: any): void {
    this.selectedWeek.disable();

    this.weeks = res.weeks.weeks;
    if (this.weeks.length) this.selectedWeek.enable();
    else {
      this.selectedWeek.setValue(null);
      this.store.dispatch(
        new SelectedWeek({
          pk: null,
          week_no: null,
          season_id: {
            id: null,
            name: '',
            year: null,
            user_id: null,
          },
          opponent_id: null,
          opponent_name: null,
        })
      );
      this.notificationService.info('No week found');
    }

    const queryParams = this.route.snapshot.queryParams;
    const queryParamWeekNo = queryParams['weekNo'];
    if (
      (queryParamWeekNo != undefined || queryParamWeekNo != null) &&
      this.selectedWeek.value?.week_no !== queryParamWeekNo
    ) {
      const week = this.weeks.find((week) => week.week_no == queryParamWeekNo);
      this.selectedWeek.setValue(week);
      this.onWeekChange();
    }
    this.cdr.detectChanges();
  }
}
