import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { Select } from '@ngxs/store';
import { PositionGroupSelectors } from '@shared/states/position-group/position-group.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';
import { PositionGroup } from '../../interfaces/position-group.interface';
import { LoadingSelectors } from '@NgXs/loading/loading.selectors';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { Sob } from 'app/core/layout/components/select-sob/enums/sob.enum';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { SobSelectors } from 'app/core/layout/components/select-sob/state/sob.selector';

@Component({
  selector: 'og-select-position-group',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatFormFieldModule, MatSelectModule, FormsModule],
  templateUrl: './select-position-group.component.html',
  styleUrls: ['./select-position-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPositionGroupComponent implements OnInit, OnDestroy {
  isMasterListSelected = true;
  selectedPgId!: PositionGroup['name'];
  positionGroups: PositionGroup[] = [];
  currentSob: SOB;

  @Input() type = 'select';
  @Input() isDisabled = false;

  @Input() set positionGroup(pg: PositionGroup) {
    this.selectedPgId = pg?.name;
    this.setSelection();
  }

  @Output() positionGroupChangeEvent = new EventEmitter<PositionGroup>();

  @Select(LoadingSelectors.isLoading) isLoading$: Observable<boolean>;
  @Select(PositionGroupSelectors.positionGroups) positionGroups$: Observable<PositionGroup[]>;
  @Select(SobSelectors.selectedSob) currentSob$: Observable<SOB | null>;

  private unsubscribeAll: Subject<any> = new Subject<any>();
  protected readonly Sob = Sob;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.positionGroups$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((positionGroups: PositionGroup[]) => {
        this.positionGroups = positionGroups;
        this.setSelection();

        this.cd.markForCheck();
      });

    this.currentSob$.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (sob) => {
        this.currentSob = sob;
        this.cd.detectChanges();
      },
    });
  }

  setSelection(): void {
    this.isMasterListSelected = true;
    this.positionGroups.forEach((positionGroup: PositionGroup) => {
      if (positionGroup.name == this.selectedPgId) {
        this.isMasterListSelected = false;
        positionGroup.selected = !positionGroup.selected;
      } else positionGroup.selected = false;
    });
  }

  positionGroupChange(positionGroup: PositionGroup): void {
    this.selectedPgId = positionGroup.name;
    this.setSelection();
    if (!positionGroup.selected) {
      this.isMasterListSelected = true;
      positionGroup = undefined;
    }
    this.positionGroupChangeEvent.emit(positionGroup);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
