import { Player } from '../../interfaces/roster.types';

export class GetPlayers {
  static readonly type = '[players] Get Players';
  constructor(
    public page: number,
    public searchKeyword: string
  ) {}
}

export class AddPlayer {
  static readonly type = '[players] Add Player';
  constructor(public player: Player) {}
}

export class EditPlayer {
  static readonly type = '[players] Edit Player';
  constructor(public player: Player) {}
}

export class DeletePlayer {
  static readonly type = '[players] Delete Player';
  constructor(public playerId: number) {}
}

export class UpdateTotalPlayersCount {
  static readonly type = 'Update total players count';
  constructor(public count: number) {}
}

export class ClearPlayersState {
  static readonly type = '[players] Clear Players';
}
