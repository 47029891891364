<div class="w-full user-profile-container">
  <og-card pageTitle="Profile">
    <div
      class="container mx-auto flex flex-col items-center justify-center p-2 mt-4 bg-white shadow-lg"
      *ngIf="userData"
    >
      <!-- Avatar -->
      <div class="flex w-full justify-center -mt-12">
        <div
          class="relative flex items-center justify-center w-44 h-44 overflow-hidden rounded-full ring-4 ring-bg-card"
        >
          <img
            class="object-cover w-full h-full"
            [src]="
              userData.coach.avatar ?? 'assets/images/avatar/avatar-male-profile-gray-person.jpg'
            "
            alt="avatar"
          />
          <!-- Upload / avatar -->
          <div class="absolute inset-0 z-10 bg-black bg-opacity-50"></div>
          <div class="absolute inset-0 z-20 flex items-center justify-center">
            <div>
              <input
                id="profile-avatar-file-input"
                class="absolute w-0 h-0 opacity-0 pointer-events-none invisible"
                type="file"
                [multiple]="false"
                [accept]="'image/jpeg, image/png'"
                (change)="uploadAvatar($event)"
                #avatarFileInput
              />
              <label
                for="profile-avatar-file-input"
                class="flex items-center justify-center w-10 h-10 cursor-pointer rounded-full hover:bg-hover"
                matRipple
              >
                <mat-icon class="text-white" [svgIcon]="'heroicons_outline:camera'"></mat-icon>
              </label>
            </div>
            <div>
              <button
                mat-icon-button
                (click)="uploadAvatar(undefined); avatarFileInput.value = null"
              >
                <mat-icon class="text-white" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <form class="w-full mt-1" [formGroup]="userProfileForm">
        <mat-expansion-panel #mep="matExpansionPanel" class="m-1">
          <mat-expansion-panel-header>Basic Information</mat-expansion-panel-header>
          <div
            class="flex flex-col items-center justify-center md:justify-between lg:justify-between lg:flex-row"
          >
            <span class="col-xl-6 col-12 d-flex">
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-4 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:user'"></mat-icon>
                </div>
                <div>First Name</div>
              </div>
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-8 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  *ngIf="!isEditing"
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                  >{{ userData.first_name }}</span
                >
                <span *ngIf="isEditing" class="inp my-1ut-with-error my-1 mx-2 w-100">
                  <mat-form-field subscriptSizing="dynamic" class="w-100">
                    <input matInput placeholder="First Name" formControlName="first_name" />
                    <mat-error
                      class="mt-1"
                      *ngIf="userProfileForm.get('first_name').hasError('required') && isEditing"
                    >
                      First Name is required
                    </mat-error>
                  </mat-form-field>
                </span>
              </div>
            </span>

            <span class="col-xl-6 col-12 d-flex">
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-4 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:user'"></mat-icon>
                </div>
                <div>Last Name</div>
              </div>
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-8 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  *ngIf="!isEditing"
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                  >{{ userData.last_name }}</span
                >
                <span *ngIf="isEditing" class="inp my-1ut-with-error my-1 mx-2 w-100">
                  <mat-form-field subscriptSizing="dynamic" class="w-100">
                    <input matInput placeholder="Last Name" formControlName="last_name" />
                    <mat-error
                      class="mt-1"
                      *ngIf="userProfileForm.get('last_name').hasError('required') && isEditing"
                    >
                      Last Name is required
                    </mat-error>
                  </mat-form-field>
                </span>
              </div>
            </span>
          </div>

          <div
            class="flex flex-col items-center justify-center md:justify-between lg:justify-between lg:flex-row"
          >
            <span class="col-xl-6 col-12 d-flex">
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-4 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:envelope'"></mat-icon>
                </div>
                <div>Email</div>
              </div>
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-8 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                  *ngIf="!isEditing"
                  >{{ userData.email }}</span
                >
                <span *ngIf="isEditing" class="inp my-1ut-with-error my-1 mx-2 w-100">
                  <mat-form-field subscriptSizing="dynamic" class="w-100">
                    <input matInput placeholder="Email" formControlName="email" />
                    <mat-error
                      class="mt-1"
                      *ngIf="userProfileForm.get('email').hasError('required') && isEditing"
                    >
                      Email is required
                    </mat-error>
                    <mat-error
                      class="mt-1"
                      *ngIf="userProfileForm.get('email').hasError('email') && isEditing"
                    >
                      Please enter a valid email address
                    </mat-error>
                  </mat-form-field>
                </span>
              </div>
            </span>

            <span class="col-xl-6 col-12 d-flex">
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-4 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
                </div>
                <div>Username</div>
              </div>
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-8 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                  *ngIf="!isEditing"
                  >{{ userData.username }}</span
                >
                <span *ngIf="isEditing" class="inp my-1ut-with-error my-1 mx-2 w-100">
                  <mat-form-field subscriptSizing="dynamic" class="w-100">
                    <input
                      matInput
                      placeholder="Username"
                      autocomplete="username"
                      formControlName="username"
                    />
                    <mat-error
                      class="mt-1"
                      *ngIf="userProfileForm.get('username').hasError('required') && isEditing"
                    >
                      Username is required
                    </mat-error>
                  </mat-form-field>
                </span>
              </div>
            </span>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="m-1">
          <mat-expansion-panel-header>Roles Information</mat-expansion-panel-header>

          <div
            class="flex flex-col items-center justify-center md:justify-between lg:justify-between lg:flex-row"
          >
            <span class="col-xl-6 col-12 d-flex">
              <div
                class="col-4 border-1 py-3 px-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <mat-icon class="mr-1" [svgIcon]="'mat_outline:laptop_mac'"></mat-icon>
                </div>
                <div>Role</div>
              </div>
              <div
                class="col-8 border-1 py-3 px-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                  >{{ userData.coach.roles }}</span
                >
              </div>
            </span>

            <span class="col-xl-6 col-12 d-flex">
              <div
                class="col-4 border-1 py-3 px-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <div
                  class="d-flex align-items-center justify-content-center"
                  class="col-3 d-flex align-items-center justify-content-end"
                >
                  <mat-icon class="mr-1" [svgIcon]="'mat_outline:person_pin_circle'"></mat-icon>
                </div>
                <div class="col-9 d-flex align-items-center justify-content-start">
                  Position Group
                </div>
              </div>
              <div
                class="col-8 border-1 py-3 px-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                  >{{ userData.coach.postion_group ?? '-' }}</span
                >
              </div>
            </span>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="m-1">
          <mat-expansion-panel-header>Package Information</mat-expansion-panel-header>
          <div
            class="flex flex-col items-center justify-center md:justify-between lg:justify-between lg:flex-row"
          >
            <span class="col-xl-6 col-12 d-flex">
              <div
                class="col-5 border-1 border-gray-300 py-3 px-1 d-flex align-items-center justify-content-center"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:calendar-days'"></mat-icon>
                </div>
                <div>Date Joined</div>
              </div>
              <div
                [class.py-3]="!isEditing"
                [class.px-1]="!isEditing"
                class="col-7 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                  >{{ userData.coach.coach_info.date_joined | date: 'MMM d, y' }}</span
                >
              </div>
            </span>

            <span class="col-xl-6 col-12 d-flex">
              <div
                class="col-5 border-1 border-gray-300 py-3 px-1 d-flex align-items-center justify-content-center"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:list-bullet'"></mat-icon>
                </div>
                <div>Package Details</div>
              </div>
              <div
                class="col-7 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                >
                  {{ userData.coach.subscription_info[0]?.package_name }} <br />
                  {{ userData.coach.subscription_info[0]?.start_date | date: 'MMM d, y' }} &nbsp; -
                  &nbsp;
                  {{ userData.coach.subscription_info[0]?.end_date | date: 'MMM d, y' }}</span
                >
              </div>
            </span>
          </div>

          <div
            class="flex flex-col items-center justify-center md:justify-between lg:justify-between lg:flex-row"
          >
            <span class="col-xl-6 col-12 d-flex">
              <div
                class="col-5 border-1 border-gray-300 py-3 px-1 d-flex align-items-center justify-content-center"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:clock'"></mat-icon>
                </div>
                <div>Video Quota Details</div>
              </div>
              <div
                class="col-7 border-1 border-gray-300 d-flex align-items-center justify-content-center"
              >
                <span
                  class="ml-2 text-primary w-full d-flex justify-content-center align-items-center"
                >
                  <b>{{ userData.club_info.utilized_video_quota_in_hours }}</b
                  >&nbsp;hours /&nbsp;
                  <b>
                    {{ userData.club_info.video_quota_in_hours }}
                  </b>
                  &nbsp;hours
                </span>
              </div>
            </span>
          </div>
        </mat-expansion-panel>
      </form>

      <form class="w-full mt-1" [formGroup]="changePasswordForm">
        <mat-expansion-panel
          [disabled]="!isPasswordChangeEnabled.value"
          [expanded]="isPasswordChangeEnabled.value"
        >
          <mat-expansion-panel-header>
            <div class="d-flex justify-content-between align-items-center w-100 mr-2">
              <div>Change Password</div>
              <div>
                <mat-slide-toggle
                  color="primary"
                  labelPosition="before"
                  [formControl]="isPasswordChangeEnabled"
                  (click)="$event.stopPropagation()"
                >
                  Enable Change Password
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div>
            <div
              class="flex flex-col items-center justify-center md:justify-between lg:justify-between lg:flex-row"
            >
              <span class="col-12 d-flex">
                <div
                  class="col-4 border-1 border-gray-300 d-flex align-items-center justify-content-center"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:key'"></mat-icon>
                  </div>
                  <div>Old Password</div>
                </div>
                <div
                  class="col-8 border-1 border-gray-300 d-flex align-items-center justify-content-center"
                >
                  <span class="input-with-error mx-2 my-1 w-1/2">
                    <mat-form-field subscriptSizing="dynamic" class="w-100">
                      <input
                        matInput
                        placeholder="Old Password:"
                        formControlName="old_password"
                        #currentPasswordField
                      />
                      <button
                        (click)="
                          currentPasswordField.type === 'password'
                            ? (currentPasswordField.type = 'text')
                            : (currentPasswordField.type = 'password')
                        "
                        mat-icon-button
                        matSuffix
                        type="button"
                      >
                        <mat-icon
                          *ngIf="currentPasswordField.type === 'password'"
                          [svgIcon]="'heroicons_solid:eye'"
                          class="icon-size-5"
                        ></mat-icon>
                        <mat-icon
                          *ngIf="currentPasswordField.type === 'text'"
                          [svgIcon]="'heroicons_solid:eye-slash'"
                          class="icon-size-5"
                        ></mat-icon>
                      </button>
                    </mat-form-field>
                  </span>
                </div>
              </span>
            </div>

            <div class="flex flex-col">
              <div class="col-12 d-flex">
                <div
                  class="col-4 border-1 border-gray-300 d-flex align-items-center justify-content-center"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:key'"></mat-icon>
                  </div>
                  <div>New Password</div>
                </div>
                <div
                  class="col-8 border-1 border-gray-300 d-flex align-items-center justify-content-center"
                >
                  <span class="input-with-error mx-2 my-1 w-1/2">
                    <mat-form-field subscriptSizing="dynamic" class="w-100">
                      <input
                        matInput
                        placeholder="New Password"
                        formControlName="new_password1"
                        autocomplete="password"
                        #newPasswordField
                      />
                      <button
                        (click)="
                          newPasswordField.type === 'password'
                            ? (newPasswordField.type = 'text')
                            : (newPasswordField.type = 'password')
                        "
                        mat-icon-button
                        matSuffix
                        type="button"
                      >
                        <mat-icon
                          *ngIf="newPasswordField.type === 'password'"
                          [svgIcon]="'heroicons_solid:eye'"
                          class="icon-size-5"
                        ></mat-icon>
                        <mat-icon
                          *ngIf="newPasswordField.type === 'text'"
                          [svgIcon]="'heroicons_solid:eye-slash'"
                          class="icon-size-5"
                        ></mat-icon>
                      </button>
                      <mat-error
                        *ngIf="changePasswordForm.get('new_password1').hasError('required')"
                      >
                        New Password is required
                      </mat-error>
                      <mat-error
                        *ngIf="changePasswordForm.get('new_password1').hasError('minlength')"
                      >
                        Minimum 8 characters. Must include numbers, letters and special characters.
                      </mat-error>
                    </mat-form-field>
                  </span>
                </div>
              </div>
              <div class="col-12 d-flex">
                <div
                  class="col-4 border-1 border-gray-300 d-flex align-items-center justify-content-center"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:key'"></mat-icon>
                  </div>
                  <div>Confirm Password</div>
                </div>
                <div
                  class="col-8 border-1 border-gray-300 d-flex align-items-center justify-content-center"
                >
                  <span class="input-with-error mx-2 my-1 w-1/2">
                    <mat-form-field subscriptSizing="dynamic" class="w-100">
                      <input
                        matInput
                        placeholder="Confirm Password"
                        formControlName="new_password2"
                        autocomplete="password"
                        #newPassword2Field
                      />
                      <button
                        (click)="
                          newPassword2Field.type === 'password'
                            ? (newPassword2Field.type = 'text')
                            : (newPassword2Field.type = 'password')
                        "
                        mat-icon-button
                        matSuffix
                        type="button"
                      >
                        <mat-icon
                          *ngIf="newPassword2Field.type === 'password'"
                          [svgIcon]="'heroicons_solid:eye'"
                          class="icon-size-5"
                        ></mat-icon>
                        <mat-icon
                          *ngIf="newPassword2Field.type === 'text'"
                          [svgIcon]="'heroicons_solid:eye-slash'"
                          class="icon-size-5"
                        ></mat-icon>
                      </button>
                      <mat-error
                        class="mt-1"
                        *ngIf="changePasswordForm.get('new_password2').hasError('required')"
                      >
                        Password confirmation is required
                      </mat-error>
                      <mat-error
                        class="mt-1"
                        *ngIf="changePasswordForm.get('new_password2').hasError('mustMatch')"
                      >
                        Passwords must match
                      </mat-error>
                    </mat-form-field>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <mat-action-row>
            <button
              mat-raised-button
              color="primary"
              [disabled]="isPasswordChangeEnabled && !this.changePasswordForm.valid"
              (click)="changePassword()"
            >
              Change Password
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </form>

      <div class="flex items-center justify-center p-1 pt-4">
        <button
          *ngIf="!isEditing"
          class="m-2 btn-primary"
          mat-raised-button
          color="primary"
          (click)="mep.expanded = true; editFields()"
        >
          Edit
        </button>

        <button *ngIf="!isEditing" class="m-2 btn-secondary" mat-raised-button (click)="home()">
          Exit
        </button>

        <button
          *ngIf="isEditing"
          class="m-2 btn-primary"
          mat-raised-button
          color="primary"
          [disabled]="!userProfileForm.valid"
          (click)="submit()"
        >
          Save
        </button>

        <button
          *ngIf="isEditing"
          class="m-2 btn-secondary"
          mat-raised-button
          (click)="mep.expanded = false; clearForm()"
        >
          Cancel
        </button>
      </div>
    </div>
  </og-card>
</div>
