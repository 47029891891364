import { UploadProgressI, VideoProgressI } from '@shared/interfaces/data-upload-interface';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';

export class HudlCollegeUploadInitialData {
  static readonly type = '[HudlCollegeDataUpload] Hudl College Upload Initial Data';

  constructor(
    public sob: SOB,
    public season: Season,
    public payload: any
  ) {}
}

export class HudlCollegeUploadProgress {
  static readonly type = '[HudlCollegeDataUpload] Hudl College Upload Progress';

  constructor(
    public sobValue: SOB['value'],
    public payload: UploadProgressI
  ) {}
}

export class HudlCollegeVideoProgress {
  static readonly type = '[HudlCollegeDataUpload] Hudl College Video Progress';

  constructor(
    public sobValue: SOB['value'],
    public payload: VideoProgressI
  ) {}
}

export class RemoveHudlCollegeUploadedData {
  static readonly type = '[HudlCollegeDataUpload] Remove Hudl College Uploaded Data';

  constructor(public sobValue: SOB['value']) {}
}
