import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppComponent } from './app.component';
import { appConfig } from './app.config';
import ThemeState from './core/NgXs/Theme/theme.state';
import { AuthState } from '@NgXs/authentication/states/auth.state';
import { LoadingState } from '@NgXs/loading/loading.state';
import { SobState } from './core/layout/components/select-sob/state/sob.state';
import { SeasonState } from '@shared/states/season/season.state';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { StorageMediumState } from '@shared/states/storage-medium/storage-medium.state';
import { OpponentState } from './modules/opponents/state/opponents.state';
import { HttpClientModule } from '@angular/common/http';
import { WeeksState } from '@shared/states/weeks/weeks.state';
import { MatchesState } from './modules/show-matches/state/matches.state';
import { GamePracticeState } from '@shared/states/game-practice/game-practice.state';
import { PositionGroupState } from '@shared/states/position-group/position-group.state';
import { PlayerSubstitutionState } from './modules/player-substitution/state/player-substitution.state';
import { ProductionState } from './modules/production/state/production.state';
import { BoxOptionState } from 'app/modules/system-settings/states/box-option/box-option.state';
import { AATESettingState } from './modules/system-settings/states/aate-setting/aate-setting.state';
import { BreakpointState } from '@NgXs/breakpoint/breakpoint.state';
import { MapReportState } from './modules/system-settings/states/map-report/map-report.state';
import { GuestState } from './modules/guest/state/guest.state';
import { DataImportState } from './modules/data-import/state/data-import.state';
import { PlayersState } from './modules/roster/states/players/player.state';
import { PlayerComparisonState } from './modules/player-comparison/state/player-comparison-state';
import { GradingState } from './modules/grading/state/grading.state';
import { UserProfileState } from './modules/user-profile/state/user-profile.state';
import { PlayFieldsState } from './modules/infinite-athlete/state/play-fields/play-fields.state';
import { CoachState } from './modules/coach/state/coach.state';
import { NgChartsModule } from 'ng2-charts';
import { PlayFilterState } from './modules/infinite-athlete/state/play-filters/play-filters.state';
import { SelectedGameState } from './modules/infinite-athlete/state/selected-game/selected-game.state';
import { IAGamePlayersState } from './modules/infinite-athlete/state/game-players/game-players.state';
import { InfiniteVideoState } from './modules/infinite-athlete/state/infinite-video/infinite-video.state';
import { AWSDataUploadState } from 'app/modules/data-import/components/import-grading-data/components/xos-upload/state/aws-data-upload.state';
import { HudlDataUploadState } from './modules/data-import/components/import-grading-data/components/hudl-upload/state/hudl-data-upload.state';
import { GamestratDataUploadState } from './modules/data-import/components/import-grading-data/components/game-strat-upload/state/game-strat-data-upload.state';
import { HudlCollegeDataUploadState } from './modules/data-import/components/import-grading-data/components/hudl-upload-college/state/hudl-college-data-upload.state';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from '../../cookie-consent.config';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'environments/environment';
import { SkillClassFilterState } from '@shared/states/skill-class-filter/skill-class-filter.state';
import { VideoDurationState } from '@shared/states/video-duration/video-duration.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    NgxsModule.forRoot([
      ThemeState,
      AuthState,
      LoadingState,
      SobState,
      SeasonState,
      StorageMediumState,
      OpponentState,
      WeeksState,
      MatchesState,
      GamePracticeState,
      PositionGroupState,
      ProductionState,
      BoxOptionState,
      AATESettingState,
      BreakpointState,
      MapReportState,
      PlayerSubstitutionState,
      DataImportState,
      PlayersState,
      GuestState,
      GradingState,
      PlayerComparisonState,
      UserProfileState,
      PlayFieldsState,
      CoachState,
      PlayFilterState,
      SelectedGameState,
      IAGamePlayersState,
      InfiniteVideoState,
      AWSDataUploadState,
      HudlDataUploadState,
      HudlCollegeDataUploadState,
      GamestratDataUploadState,
      SkillClassFilterState,
      VideoDurationState,
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    RouterOutlet,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      // maxOpened: 3, removing it otherwise the previous in line was not showing unless you hover you mouse over menu item, indirectly running the change detection, this is the issue with the fuse alert template that we used, if we replace the custom toastr with the ngx-toastr, this issue is not there.
      autoDismiss: true,
    }),
    NgChartsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [...appConfig.providers],
  bootstrap: [AppComponent],
})
export class AppModule {}
