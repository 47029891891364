import { Player } from '../../interfaces/infinite-athlete.interface';

export class GetIAGamePlayers {
  static readonly type = '[IAPlayers] Get IAPlayers';
  constructor() {}
}

export class SetIAGamePlayers {
  static readonly type = '[setIAPlayers] Set IAPlayers';
  constructor(public players: Player[]) {}
}
