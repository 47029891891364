import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';
import { Select, Store } from '@ngxs/store';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  LoadPracticeNumbers,
  ResetSelectedGameType,
  SetSelectedGameType,
} from '@shared/states/game-practice/game-practice.action';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { PracticeNoApi } from '@shared/interfaces/game-practice.interface';
import { NotificationService } from '@shared/services/notification/notification.service';
import { SeasonSelectors } from '@shared/states/season/season.selector';
import { Observable, Subject, takeUntil } from 'rxjs';
import { WeeksSelector } from '@shared/states/weeks/weeks.selector';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import { SobSelectors } from '../../../core/layout/components/select-sob/state/sob.selector';
import { ActivatedRoute } from '@angular/router';
import { Season } from '@shared/interfaces/season.interface';

@Component({
  selector: 'og-select-game-practice',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './select-game-practice.component.html',
  styleUrls: ['./select-game-practice.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGamePracticeComponent implements OnInit, OnDestroy {
  @Select(SobSelectors.selectedSob) selectedSob$: Observable<SOB>;
  @Select(SeasonSelectors.selectedSeason) selectedSeason$: Observable<Season>;
  @Select(WeeksSelector.selectedWeek) selectedWeek$: Observable<WeeksApi>;

  currentSeason: Season;
  selectedWeek: WeeksApi;
  sob: SOB;
  gamesType = { P: 'Practice', G: 'Game' };
  selectedGameType: FormControl = new FormControl(null);
  selectedPracticeNo: FormControl = new FormControl(null);
  practiceNoOptions: PracticeNoApi[];
  showGamePracticeSelect: boolean = false;
  showPracticeNoSelect: boolean = false;
  private count: number = 0;
  private gp_count: number = 0;
  private destroy$ = new Subject<void>();

  @Input() widthForGamePractice = null;
  @Input() widthForPracticeNo = null;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscribeToObservables();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(new ResetSelectedGameType());
  }

  comparer(o1: any, o2: any) {
    if (o1?.count == o2?.count && o1?.gp_count == o2?.gp_count) return true;
    else return false;
  }

  showGameOrPractice() {
    if (
      this.currentSeason?.id &&
      this.selectedWeek?.week_no != null &&
      this.selectedWeek?.week_no != undefined
    ) {
      this.showGamePracticeSelect = true;
    } else {
      this.selectedGameType.setValue(null);
      this.onGameTypeChange();

      this.selectedPracticeNo.setValue(null);
      this.onPracticeChange();

      this.showGamePracticeSelect = false;

      this.practiceNoOptions = [];
      this.showPracticeNoSelect = false;
    }
    this.cdr.detectChanges();
  }

  private subscribeToObservables(): void {
    this.selectedSeason$
      .pipe(takeUntil(this.destroy$))
      .subscribe((season) => this.handleSeasonChange(season));

    this.selectedWeek$
      .pipe(takeUntil(this.destroy$))
      .subscribe((week) => this.handleWeekChange(week));

    this.selectedSob$.pipe(takeUntil(this.destroy$)).subscribe((sob) => this.handleSobChange(sob));
  }

  onGameTypeChange(event?) {
    if (event) event.source.close();
    if (!this.sob) this.sob = this.store.selectSnapshot(SobSelectors.selectedSob);
    this.handleGameTypeChange(this.selectedGameType.value);
  }

  onPracticeChange(event?) {
    if (event) event.source.close();
    this.handlePracticeNoChange(this.selectedPracticeNo.value);
  }

  private handleSeasonChange(season: any): void {
    if (!season?.id) {
      return;
    }

    this.currentSeason = season;
    this.showGameOrPractice();
  }

  clearData() {
    this.practiceNoOptions = [];
    this.showPracticeNoSelect = false;
    this.selectedGameType.setValue(null);
    this.selectedPracticeNo.setValue(null);

    this.store.dispatch(new ResetSelectedGameType());
    this.cdr.detectChanges();
  }

  private handleWeekChange(week: any): void {
    if (week?.week_no == undefined || week?.week_no == null) {
      this.showGamePracticeSelect = false;
      this.clearData();
      return;
    }

    if (week && this.selectedWeek && week.pk != this.selectedWeek.pk) {
      this.showGamePracticeSelect = true;
      this.selectedWeek = week;
      this.clearData();
      return;
    }

    this.selectedWeek = week;
    const queryParams = this.route.snapshot.queryParams;
    const queryParamMatchType = queryParams['matchType'];
    const count = queryParams['count'];
    const gp_count = queryParams['gp_count'];
    if (count) this.count = count;
    if (gp_count) this.gp_count = gp_count;
    if (count && gp_count) {
      this.selectedPracticeNo.setValue({ count, gp_count });
      this.handlePracticeNoChange(this.selectedPracticeNo.value);
      this.cdr.detectChanges();
    }

    if (queryParamMatchType && this.selectedGameType.value !== queryParamMatchType) {
      this.selectedGameType.setValue(queryParamMatchType);
      this.onGameTypeChange(null);
      this.cdr.detectChanges();
    }

    this.showGameOrPractice();
  }

  private handleSobChange(sob: any): void {
    if (!sob?.alias) {
      return;
    }

    this.sob = sob;
    this.showGameOrPractice();
  }

  private handleGameTypeChange(value: string): void {
    let countData = {
      count: this.count,
      gp_count: this.gp_count,
    };

    if (value == 'P') {
      this.store
        .dispatch(
          new LoadPracticeNumbers(
            this.currentSeason?.id,
            this.selectedWeek.week_no,
            this.selectedGameType.value,
            this.sob.alias
          )
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.practiceNoOptions = [];
          this.showPracticeNoSelect = false;

          this.store.dispatch(
            new SetSelectedGameType(
              value,
              countData?.count ? Number(countData?.count) : 0,
              countData?.gp_count ? Number(countData?.gp_count) : 0,
              null,
              null
            )
          );
          this.practiceNoOptions = res.gamePractice.practiceNumbers;
          if (this.practiceNoOptions.length) {
            this.showPracticeNoSelect = true;
          } else {
            this.notificationService.info('No data has been uploaded for Practice.');
          }

          this.cdr.detectChanges();
        });
    } else if (value == 'G') {
      this.store
        .dispatch(
          new LoadPracticeNumbers(
            this.currentSeason?.id,
            this.selectedWeek.week_no,
            this.selectedGameType.value,
            this.sob.alias
          )
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          countData = res.gamePractice.practiceNumbers[0];
          this.store.dispatch(
            new SetSelectedGameType(
              value,
              countData?.count ? Number(countData?.count) : 0,
              countData?.gp_count ? Number(countData?.gp_count) : 0,
              null,
              null
            )
          );
        });
      this.selectedPracticeNo.setValue(null);
      this.onPracticeChange();

      this.practiceNoOptions = [];
      this.showPracticeNoSelect = false;
      this.cdr.detectChanges();
    }
  }

  private handlePracticeNoChange(value: any): void {
    if (value != null) {
      this.store.dispatch(
        new SetSelectedGameType(
          this.selectedGameType.value,
          value.count,
          value.gp_count,
          null,
          null
        )
      );
      this.cdr.detectChanges();
    }
  }
}
