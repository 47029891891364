import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import { CdkDrag } from '@angular/cdk/drag-drop';

@Component({
  selector: 'og-floating-labels',
  standalone: true,
  imports: [CommonModule, CdkDrag],
  templateUrl: './floating-labels.component.html',
  styleUrls: ['./floating-labels.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingLabelsComponent implements OnInit {
  backgroundTasks = [];

  getColor(type: string) {
    let color = {
      Offense: '#89222a',
      Defense: '#990b99',
      'Special Teams': '#dc2626',
    };

    return color[type];
  }

  constructor(private store: Store) {}

  ngOnInit(): void {}
}
