export class GetSkillClasses {
  static readonly type = '[SkillClasses] Get Skill Classes';

  constructor(
    public seasonId: number,
    public sobValue: string
  ) {}
}

export class ResetSkillClasses {
  static readonly type = '[SkillClasses] Reset Skill Classes';
}
