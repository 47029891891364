import { Selector } from '@ngxs/store';
import { BreakpointState, BreakpointStateModel } from './breakpoint.state';

export class BreakpointSelectors {
  @Selector([BreakpointState])
  static smallScreen(state: BreakpointStateModel): boolean {
    return state.isSmallScreen;
  }

  @Selector([BreakpointState])
  static mobileScreen(state: BreakpointStateModel): boolean {
    return state.isMobileScreen;
  }
}
