import { State, Action, StateContext, Selector } from '@ngxs/store';
import { CoachService } from '../services/coach.service';
import {
  GetCoaches,
  GetCoachById,
  CreateCoach,
  UpdateCoach,
  DeleteCoach,
  DeleteAllCoaches,
  ClearSelectedCoach,
  ClearAllCoaches,
} from './coach.action';
import { tap } from 'rxjs/operators';
import { CoachData } from '../../user-profile/interfaces/user-profile.interface';
import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface CoachStateModel {
  coaches: CoachData[];
  selectedCoach: CoachData;
}

@State<CoachStateModel>({
  name: 'coach',
  defaults: {
    coaches: [],
    selectedCoach: null,
  },
})
@Injectable()
export class CoachState {
  constructor(private coachService: CoachService) {}

  @Action(GetCoaches)
  getCoaches(ctx: StateContext<CoachStateModel>) {
    return this.coachService.getCoaches().pipe(
      tap((coaches) => {
        ctx.patchState({ coaches });
      })
    );
  }

  @Action(GetCoachById)
  getCoachById(ctx: StateContext<CoachStateModel>, { id }: GetCoachById) {
    const state = ctx.getState();
    const existingCoach = state.coaches.find((coach) => coach.id === id);

    if (existingCoach) {
      // Coach with the specified id is already in state, use it
      ctx.patchState({ selectedCoach: existingCoach });
      return of(existingCoach); // Return an observable of the existing coach
    } else {
      // Coach not found in state, make API call
      return this.coachService.getCoachById(id).pipe(
        tap((selectedCoach) => {
          ctx.patchState({ selectedCoach });
        })
      );
    }
  }

  @Action(CreateCoach)
  createCoach(ctx: StateContext<CoachStateModel>, { payload }: CreateCoach) {
    return this.coachService.postCoach(payload).pipe(
      tap(() => {
        ctx.dispatch(new GetCoaches());
      })
    );
  }

  @Action(UpdateCoach)
  updateCoach(ctx: StateContext<CoachStateModel>, { id, payload }: UpdateCoach) {
    return this.coachService.patchCoach(payload, id).pipe(
      tap(() => {
        ctx.dispatch(new GetCoaches());
      })
    );
  }

  @Action(DeleteCoach)
  deleteCoach(ctx: StateContext<CoachStateModel>, { id }: DeleteCoach) {
    return this.coachService.deleteCoach(id).pipe(
      tap(() => {
        const state = ctx.getState();
        const updatedCoaches = state.coaches.filter((coach) => coach.User !== id);

        ctx.patchState({
          coaches: updatedCoaches,
          selectedCoach: null,
        });
      })
    );
  }

  @Action(DeleteAllCoaches)
  deleteAllCoaches(ctx: StateContext<CoachStateModel>, { ids }: DeleteAllCoaches) {
    const deleteRequests = ids.map((id) => this.coachService.deleteCoach(id));
    return forkJoin(deleteRequests).pipe(
      tap(() => {
        ctx.patchState({
          coaches: ctx.getState().coaches.filter((coach) => !ids.includes(coach.User)),
          selectedCoach: null,
        });
      })
    );
  }

  @Action(ClearSelectedCoach)
  clearSelectedCoach(ctx: StateContext<CoachStateModel>) {
    ctx.patchState({
      selectedCoach: null,
    });
  }

  @Action(ClearAllStates)
  @Action(ClearAllCoaches)
  clearAllCoaches(ctx: StateContext<CoachStateModel>) {
    ctx.setState({
      coaches: [],
      selectedCoach: null,
    });
  }
}
