import { CoachData } from '../../user-profile/interfaces/user-profile.interface';
import { CoachPayload } from '../interfaces/coach.interface';

export class GetCoaches {
  static readonly type = '[Coach] Get Coaches';
}

export class GetCoachById {
  static readonly type = '[Coach] Get Coach by ID';
  constructor(public id: CoachData['id']) {}
}

export class CreateCoach {
  static readonly type = '[Coach] Create Coach';
  constructor(public payload: CoachPayload) {}
}

export class UpdateCoach {
  static readonly type = '[Coach] Update Coach';
  constructor(
    public id: CoachData['User'],
    public payload: CoachPayload
  ) {}
}

export class DeleteCoach {
  static readonly type = '[Coach] Delete Coach';
  constructor(public id: CoachData['User']) {}
}

export class DeleteAllCoaches {
  static readonly type = '[Coach] Delete All Coaches';
  constructor(public ids: CoachData['User'][]) {}
}

export class ClearSelectedCoach {
  static readonly type = '[Coach] Clear Selected Coach';
}

export class ClearAllCoaches {
  static readonly type = '[Coach] Clear All Coaches';
}
