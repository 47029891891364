import {
  AddSubsPlayersPayload,
  PatchSubsPayload,
  Substitution,
  SubstitutionFormPayload,
} from '../interfaces/player-substitution.interface';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from '../../../core/layout/components/select-sob/interfaces/sob.interface';

export class LoadSubstitutions {
  static readonly type = '[PlayerSubstitution] Load Substitutions';

  constructor(
    public seasonId: Season['id'],
    public sob: SOB['value']
  ) {}
}

export class PostSubstitution {
  static readonly type = '[PlayerSubstitution] Post Substitution';

  constructor(public payload: SubstitutionFormPayload) {}
}

export class PatchSubstitution {
  static readonly type = '[PlayerSubstitution] Patch Substitution';

  constructor(
    public substitutionId: Substitution['id'],
    public payload: SubstitutionFormPayload
  ) {}
}

export class DeleteSubstitution {
  static readonly type = '[PlayerSubstitution] Delete Substitution';

  constructor(public substitutionId: Substitution['id']) {}
}

export class PatchSubstitutionPlayer {
  static readonly type = '[PlayerSubstitution] Patch Substitution Player';

  constructor(
    public playerPosSubId: Substitution['id'],
    public payload: PatchSubsPayload
  ) {}
}

export class DeleteSubstitutionPlayer {
  static readonly type = '[PlayerSubstitution] Delete Substitution Player';

  constructor(public playerPosSubId: Substitution['id']) {}
}

export class LoadPlayersByPositionGroup {
  static readonly type = '[Player Substitution] Load Players by Position Group';
  constructor(
    public positionGroups: PositionGroup[],
    public seasonId: Season['id']
  ) {}
}

export class AddSubstitutionPlayers {
  static readonly type = '[PlayerSubstitution] Add Substitution Players';

  constructor(
    public substitutionId: Substitution['id'],
    public payload: AddSubsPlayersPayload
  ) {}
}
