<mat-form-field floatLabel="always" class="px-2" [ngStyle]="{ width: width ?? '240px' }">
  <mat-select
    placeholder="Select Week"
    label="Select Week"
    [formControl]="selectedWeek"
    (selectionChange)="onWeekChange()"
  >
    <mat-option *ngFor="let week of weeks" [value]="week">{{
      week.week_no + ' - ' + week.opponent_name
    }}</mat-option>
  </mat-select>
  <mat-hint *ngIf="!weeks.length">No weeks found.</mat-hint>
</mat-form-field>
