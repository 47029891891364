import { Injectable } from '@angular/core';

import { State, Action, StateContext, Selector } from '@ngxs/store';
import SetTheme from './set-theme.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface ThemeStateModel {
  theme: 'dark' | 'light' | 'auto';
}

@State<ThemeStateModel>({
  name: 'theme',
  defaults: {
    theme: 'light',
  },
})
@Injectable()
export default class ThemeState {
  @Action(SetTheme)
  setTheme(ctx: StateContext<ThemeStateModel>, action: SetTheme) {
    ctx.patchState({ theme: action.payload });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<ThemeStateModel>) {
    ctx.setState({
      theme: 'light',
    });
  }
}

export class ThemeSelectors {
  @Selector([ThemeState]) // Pass in the state class as an argument
  static currentTheme(state: ThemeStateModel) {
    return state.theme;
  }
}
