import { Action, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, tap, iif, of } from 'rxjs';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { PlayerConcise, RosterApiResponse } from '../../interfaces/roster.types';
import {
  AddPlayer,
  ClearPlayersState,
  DeletePlayer,
  EditPlayer,
  GetPlayers,
  UpdateTotalPlayersCount,
} from './player.action';
import { RosterService } from '../../services/roster.service';
import { SeasonSelectors } from '@shared/states/season/season.selector';
import { Season } from '@shared/interfaces/season.interface';
import _ from 'lodash';

export interface PlayersStateModel {
  currentSeasonId: number;
  players: PlayerConcise[];
  totalPlayers: number;
  searchKeyword: string;
  currentPage: number;
}

@State<PlayersStateModel>({
  name: 'players',
  defaults: {
    players: [],
    totalPlayers: 0,
    currentPage: 1,
    searchKeyword: '',
    currentSeasonId: null,
  },
})
@Injectable()
export class PlayersState {
  constructor(
    private store: Store,
    private rosterService: RosterService
  ) {}

  @Action(GetPlayers)
  getPlayers(
    { getState, patchState, setState }: StateContext<PlayersStateModel>,
    { page, searchKeyword }: GetPlayers
  ): Observable<any> {
    const selectedSeason: Season = this.store.selectSnapshot(SeasonSelectors.selectedSeason);

    if (
      getState().currentSeasonId !== selectedSeason.id ||
      searchKeyword !== getState().searchKeyword
    )
      setState({
        players: [],
        totalPlayers: 0,
        currentPage: 1,
        searchKeyword: '',
        currentSeasonId: null,
      });
    return iif(
      () => getState().players.length > 0 && getState().currentPage >= page,
      of(getState()),
      this.rosterService.getPaginatedPlayers(selectedSeason.id, page, searchKeyword).pipe(
        tap((response: RosterApiResponse) => {
          let players = [...getState().players, ...response.results];
          const playersState: PlayersStateModel = {
            players: _.uniqBy(
              players.sort((a, b) => a.firstname.localeCompare(b.firstname)),
              'id'
            ),
            totalPlayers: response.count,
            currentPage: page,
            searchKeyword: searchKeyword,
            currentSeasonId: selectedSeason.id,
          };
          patchState(playersState);
        })
      )
    );
  }

  @Action(AddPlayer)
  addPlayer(ctx: StateContext<PlayersStateModel>, { player }: AddPlayer) {
    let players = ctx.getState().players;
    players.push({
      id: player.id,
      firstname: player.firstname,
      lastname: player.lastname,
      avatar: player.avatar,
      season_wise_player_detail: player.season_wise_player_detail,
      season_wise_position_groups: player.season_wise_pgs,
    });

    ctx.patchState({
      players: _.uniqBy(
        players.sort((a, b) => a.firstname.localeCompare(b.firstname)),
        'id'
      ),
    });
  }

  @Action(EditPlayer)
  editPlayer(ctx: StateContext<PlayersStateModel>, { player }: EditPlayer) {
    let players = ctx.getState().players;
    players = players.map((p: PlayerConcise) => {
      if (p.id == player.id) {
        p.avatar = player.avatar;
        p.firstname = player.firstname;
        p.lastname = player.lastname;
        p.season_wise_player_detail = player.season_wise_player_detail;
        p.season_wise_position_groups = player.season_wise_pgs;
      }

      return p;
    });

    ctx.patchState({
      players,
    });
  }

  @Action(DeletePlayer)
  deletePlayer(ctx: StateContext<PlayersStateModel>, { playerId }: DeletePlayer) {
    let players = ctx.getState().players;
    players = players.filter((p) => p.id != playerId);

    ctx.patchState({
      players,
    });
  }

  @Action(UpdateTotalPlayersCount)
  updateTotalPlayersCount(
    ctx: StateContext<PlayersStateModel>,
    { count }: UpdateTotalPlayersCount
  ) {
    ctx.patchState({
      totalPlayers: count,
    });
  }

  @Action(ClearAllStates)
  @Action(ClearPlayersState)
  clearState(ctx: StateContext<PlayersStateModel>) {
    ctx.setState({
      players: [],
      totalPlayers: 0,
      currentPage: 1,
      searchKeyword: '',
      currentSeasonId: null,
    });
  }
}
