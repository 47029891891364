import { UploadProgressI, VideoProgressI } from '@shared/interfaces/data-upload-interface';
import { Season } from '@shared/interfaces/season.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';

export class AWSDataUploadInitialData {
  static readonly type = '[AWSDataUpload] AWS Data Upload Initial Data';

  constructor(
    public sob: SOB,
    public season: Season,
    public payload: any
  ) {}
}

export class AWSUploadProgress {
  static readonly type = '[AWSDataUpload] AWS Upload Progress';

  constructor(
    public sobValue: SOB['value'],
    public payload: UploadProgressI
  ) {}
}

export class AWSVideoProgress {
  static readonly type = '[AWSDataUpload] AWS Video Progress';

  constructor(
    public sobValue: SOB['value'],
    public payload: VideoProgressI
  ) {}
}

export class RemoveAWSUploadedData {
  static readonly type = '[AWSDataUpload] Remove AWS Uploaded Data';

  constructor(public sobValue: SOB['value']) {}
}
