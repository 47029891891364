import { Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../permissions.service';
import { UserGroupPermission } from '../enums/user-group.enum';

@Directive({
  selector: '[hasUserGroupPermission]',
  standalone: true,
})
export class HasUserGroupPermissionDirective implements OnInit, OnChanges {
  @Input() hasUserGroupPermission:
    | UserGroupPermission
    | boolean
    | (UserGroupPermission | boolean | string)[];

  private userGroupPermissions: Set<string>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.userGroupPermissions = new Set<string>(this.permissionsService.UserGroupPermissions);
    this.checkPermission();
  }

  ngOnChanges() {
    this.checkPermission();
  }

  private checkPermission() {
    this.viewContainer.clear();
    const hasPermission = this.checkAllPermissions();
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private checkAllPermissions(): boolean {
    if (this.userGroupPermissions) {
      if (Array.isArray(this.hasUserGroupPermission)) {
        return this.hasUserGroupPermission.every((permissionOrBoolean) => {
          if (typeof permissionOrBoolean === 'boolean') {
            return permissionOrBoolean;
          } else if (typeof permissionOrBoolean === 'string') {
            return this.userGroupPermissions.has(permissionOrBoolean as string);
          }
        });
      } else {
        if (typeof this.hasUserGroupPermission === 'boolean') {
          return this.hasUserGroupPermission;
        } else {
          return this.userGroupPermissions.has(this.hasUserGroupPermission as string);
        }
      }
    }
  }
}
