export const environment = {
  production: false,

  main_url: 'https://gradebackend.optimumgrading.com/staging',
  googleAnalytics: '',
  aws: {
    accessKeyId: 'AKIA3TSF4J3HUDDRW67D',
    secretAccessKey: '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',
    region: 'us-east-1',
    bucket: 'play-clips-staging',
    s3UploadConfig: {
      partSize: 5 * 1024 * 1024,
      queueSize: 30,
    },
  },
};
