<h1 mat-dialog-title>Add Player</h1>

<div mat-dialog-content>
  <div>
    <ng-container *ngIf="data.sobValue == Sob.SpecialTeamsValue; else showMultiSelect">
      <og-select-position-group
        class="inline-block w-2/6"
        (positionGroupChangeEvent)="positionGroupChange([$event])"
      ></og-select-position-group>
    </ng-container>

    <ng-template #showMultiSelect>
      <og-multi-select-position-groups class="inline-block w-2/6"></og-multi-select-position-groups>
    </ng-template>

    &nbsp;
    <mat-form-field *ngIf="substitutionList.length" subscriptSizing="dynamic" class="w-2/6">
      <mat-select
        placeholder="Substitution"
        [(ngModel)]="selectedSubstitution"
        (selectionChange)="substitutionChanged($event.value)"
      >
        <mat-option [value]="undefined">None</mat-option>
        <mat-option *ngFor="let substitution of substitutionList" [value]="substitution">{{
          substitution.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mt-2 mb-[6px]">
    <mat-chip-listbox>
      <mat-chip
        *ngFor="let player of listedPlayers; let i = index"
        (removed)="removeListedPlayer(player, i)"
      >
        {{ player.jersey }}-{{ player.position_name }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-listbox>
  </div>

  <div *ngIf="!(isLoading$ | async); else showSkeleton" class="min-h-[350px]">
    <og-player-match-grid
      [isSubstitution]="!!selectedSubstitution"
      [matchId]="data.match.id"
      [seasonId]="data.seasonId"
      [players]="!!selectedSubstitution ? substitutionPlayers : players"
      [positionGroup]="selectedPgs"
      (selectedPlayerEvent)="selectedPlayerChange($event)"
    ></og-player-match-grid>
  </div>

  <ng-template #showSkeleton>
    <div class="grid sm:grid-cols-1 gap-4 px-4 w-full max-h-[37vh] overflow-y-auto">
      <ng-container *ngFor="let i of inputSkeletonRange">
        <div class="animate-pulse flex justify-center">
          <div class="h-[43px] bg-gray-200 rounded-md dark:bg-gray-500 w-full mt-[1px]"></div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>

<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="false">Close</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!listedPlayers.length"
    (click)="startGrading()"
  >
    {{ data.type == 'ADD_DURING_MATCH' ? 'Add' : 'Start' }}
  </button>
</div>
