import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
  /**
   * Check matching password with confirm password
   * @param control AbstractControl
   */
  static MatchPassword(control: AbstractControl) {
    const password1 = control.get('password1').value;

    const password2 = control.get('password2').value;

    if (password1 !== password2) {
      control.get('password2').setErrors({ noMatch: true });
    } else {
      return null;
    }
  }
}
