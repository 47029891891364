import { Selector } from '@ngxs/store';
import { WeeksState, WeeksStateModel } from './weeks.state';
import { WeeksApi } from '@shared/interfaces/weeks.interface';

// Selectors
export class WeeksSelector {
  @Selector([WeeksState])
  static getWeeks(state: WeeksStateModel): WeeksApi[] {
    return state.weeks;
  }

  @Selector([WeeksState])
  static selectedWeek(state: WeeksStateModel): WeeksStateModel['selectedWeek'] {
    return state.selectedWeek;
  }
}
