// game-practice.state.ts

import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  AWSDataUploadInitialData,
  AWSUploadProgress,
  AWSVideoProgress,
  RemoveAWSUploadedData,
} from './aws-data-upload.action';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { Season } from '@shared/interfaces/season.interface';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import { UploadProgressI, VideoProgressI } from '@shared/interfaces/data-upload-interface';

export interface awsDataUploadStateI {
  sob: SOB;
  season: Season;
  week: WeeksApi;
  matchType: string;
  gameNo: string;
  uploadProgress: UploadProgressI;
  videoProgress: VideoProgressI;
}
export interface AWSDataUploadStateModel {
  awsDataUpload: awsDataUploadStateI[];
}

@State<AWSDataUploadStateModel>({
  name: 'AWSDataUpload',
  defaults: {
    awsDataUpload: [],
  },
})
@Injectable()
export class AWSDataUploadState {
  constructor() {}

  @Action(AWSDataUploadInitialData)
  dataUploadSOB(
    ctx: StateContext<AWSDataUploadStateModel>,
    { sob, season, payload }: AWSDataUploadInitialData
  ) {
    let state = ctx.getState().awsDataUpload;
    state.push({
      sob: sob,
      season: season,
      week: payload.week,
      matchType: payload.match_type,
      gameNo: payload.game_number,
      uploadProgress: { isUploading: false, percent: 0 },
      videoProgress: {
        isUploading: false,
        filesUploaded: 0,
        totalFiles: 0,
        percent: 0,
      },
    });

    ctx.patchState({
      awsDataUpload: state,
    });
  }

  @Action(AWSUploadProgress)
  uploadProgress(
    ctx: StateContext<AWSDataUploadStateModel>,
    { sobValue, payload }: AWSUploadProgress
  ) {
    let state = ctx.getState().awsDataUpload;
    state = state.map((s) => {
      if (s.sob.value == sobValue) s.uploadProgress = payload;
      return s;
    });

    ctx.patchState({
      awsDataUpload: state,
    });
  }

  @Action(AWSVideoProgress)
  videoProgress(
    ctx: StateContext<AWSDataUploadStateModel>,
    { sobValue, payload }: AWSVideoProgress
  ) {
    let state = ctx.getState().awsDataUpload;
    state = state.map((s) => {
      if (s.sob.value == sobValue) s.videoProgress = payload;
      return s;
    });

    ctx.patchState({
      awsDataUpload: state,
    });
  }

  @Action(RemoveAWSUploadedData)
  removeAWSUploadedData(
    ctx: StateContext<AWSDataUploadStateModel>,
    { sobValue }: RemoveAWSUploadedData
  ) {
    let state = ctx.getState().awsDataUpload;
    state = state.filter((s) => {
      if (s.sob.value != sobValue) return s;
    });

    ctx.patchState({
      awsDataUpload: state,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<AWSDataUploadStateModel>) {
    ctx.setState({
      awsDataUpload: [],
    });
  }
}
