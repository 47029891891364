import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  CreateDepthChartPositionPlayer,
  CreatePositionGroupApiResponse,
  DepthChartApiResponse,
  DepthChartCreateApiResponse,
  DepthChartPositionClassificationsApiResponse,
  DepthChartPositionColorApiResponse,
  DepthChartPositionGroupColorsApiResponse,
  DepthChartPositionGroupsApiResponse,
  DepthChartPositionPlayerApiResponse,
  DepthChartPositionsApiResponse,
  InjuredPlayerApiResponse,
  InjuredPlayerPayload,
  Player,
} from './interfaces';
import { RosterService } from '../roster/services/roster.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { RefreshDataTypes } from './depth-chart.component';

@Injectable({
  providedIn: 'root',
})
export class DepthChartService {
  private refreshDataSubject = new Subject<RefreshDataTypes>();
  refreshData$ = this.refreshDataSubject.asObservable();
  selectPlayersListId = null;
  resetPanelSubject = new BehaviorSubject<boolean>(false);
  resetPhasesForm = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  createDepthChart(season: number, side_of_ball: string, type: string) {
    return this.http.post<DepthChartCreateApiResponse>(`${environment.main_url}/depth-charts/`, {
      type,
      season,
      side_of_ball,
    });
  }
  getDepthCharts() {
    return this.http.get<DepthChartApiResponse[]>(`${environment.main_url}/depth-charts/`);
  }
  getDepthChartPositionPlayers(depthChartId: number) {
    return this.http.get<DepthChartPositionPlayerApiResponse[]>(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-players/`
    );
  }

  createDepthChartPositionPlayer(
    depthChartId: number,
    depthChartPositionId: number,
    playerId: number,
    order?: number
  ) {
    return this.http.post<CreateDepthChartPositionPlayer>(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-players/`,
      {
        depth_chart_position_id: depthChartPositionId,
        player_id: playerId,
        x: 190.9,
        y: order,
      }
    );
  }
  updateDepthChartPositionPlayer(
    depthChartId: number,
    depthChartPlayerId: number,
    is_flagged: boolean,
    index?: number
  ) {
    return this.http.patch(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-players/${depthChartPlayerId}/`,
      {
        is_flagged,
        x: 190.9,
        y: index || 0,
      }
    );
  }
  deleteDepthChartPositionPlayer(depthChartId: number, playerId: number) {
    return this.http.delete(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-players/${playerId}/`
    );
  }
  getDepthChartPositions(depthChartId: number) {
    return this.http.get<DepthChartPositionsApiResponse[]>(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-positions/`
    );
  }
  getDepthChartPositionGroups(depthChartId: number) {
    return this.http.get<DepthChartPositionGroupsApiResponse[]>(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-groups/`
    );
  }
  createDepthChartPositionGroup(depthChartId: number, positionGroupId: number, order: number) {
    return this.http.post<CreatePositionGroupApiResponse>(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-groups/`,
      {
        position_group_id: positionGroupId,
        x: 10.9,
        y: order,
      }
    );
  }
  updateDepthChartPositionGroup(depthChartId: number, positionGroupId: number, order: number) {
    return this.http.patch<CreatePositionGroupApiResponse>(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-groups/${positionGroupId}/`,
      {
        x: 10.9,
        y: order,
      }
    );
  }
  createDepthChartPositionWithoutPositionGroup(
    depthChartId: number,
    positionId: number,
    x: number,
    y: number
  ) {
    return this.http.post(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-positions/`,
      {
        position_id: positionId,
        x,
        y,
      }
    );
  }
  updateDepthChartPosition(depthChartId: number, positionId: number, x: number, y: number) {
    return this.http.patch(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-positions/${positionId}/`,
      {
        x,
        y,
      }
    );
  }
  deleteDepthChartPositionWithoutPositionGroup(
    depthChartId: number,
    positionIdInCollection: number
  ) {
    return this.http.delete(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-positions/${positionIdInCollection}/`
    );
  }
  deleteDepthChartPositionWithPositionGroup(depthChartId: number, positionIdInCollection: number) {
    return this.http.delete(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-groups/${positionIdInCollection}/`
    );
  }
  createDepthChartPositionWithPositionGroup(
    depthChartId: number,
    positionId: number,
    positionGroupId: number,
    x: number,
    y: number
  ) {
    return this.http.post(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-positions/`,
      {
        position_id: positionId,
        depth_chart_position_group_id: positionGroupId,
        x,
        y,
      }
    );
  }

  getDepthChartPositionGroupColors(depthChartId: number) {
    return this.http.get<DepthChartPositionGroupColorsApiResponse[]>(
      `${environment.main_url}/depth-charts/${depthChartId}/position-group-colors/`
    );
  }

  createDepthChartPositionGroupColor(depthChartId: number, positionGroupId: number, color: string) {
    return this.http.post<DepthChartPositionColorApiResponse>(
      `${environment.main_url}/depth-charts/${depthChartId}/position-group-colors/`,
      {
        position_group_id: positionGroupId,
        color,
      }
    );
  }
  updateDepthChartPositionGroupColor(
    depthChartId: number,
    positionGroupColorId: number,
    color: string
  ) {
    return this.http.patch(
      `${environment.main_url}/depth-charts/${depthChartId}/position-group-colors/${positionGroupColorId}/`,
      {
        color,
      }
    );
  }
  deleteDepthChartPositionGroupColor(depthChartId: number, positionGroupIdInCollection: number) {
    return this.http.delete(
      `${environment.main_url}/depth-charts/${depthChartId}/position-group-colors/${positionGroupIdInCollection}/`
    );
  }
  deleteDepthChartPositionGroup(depthChartId: number, positionGroupIdInCollection: number) {
    return this.http.delete(
      `${environment.main_url}/depth-charts/${depthChartId}/depth-chart-position-groups/${positionGroupIdInCollection}/`
    );
  }

  getInjuredPlayers(depthChartId: number) {
    return this.http.get<InjuredPlayerApiResponse[]>(
      `${environment.main_url}/depth-charts/${depthChartId}/injured-players/`
    );
  }

  postInjuredPlayer(depthChartId: number, payload: InjuredPlayerPayload) {
    return this.http.post<InjuredPlayerApiResponse[]>(
      `${environment.main_url}/depth-charts/${depthChartId}/injured-players/`,
      payload
    );
  }

  deleteInjuredPlayer(depthChartId: number, id: number) {
    return this.http.delete(
      `${environment.main_url}/depth-charts/${depthChartId}/injured-players/${id}/`
    );
  }

  triggerRefreshData(type: RefreshDataTypes) {
    this.refreshDataSubject.next(type);
  }

  createDepthChartPositionClassifications(
    depthChartId: number,
    classification: string,
    color: string,
    order: number
  ) {
    return this.http.post<DepthChartPositionClassificationsApiResponse>(
      `${environment.main_url}/depth-charts/${depthChartId}/classification-colors/`,
      {
        classification,
        color,
        order,
      }
    );
  }
  updateDepthChartPositionClassifications(
    depthChartId: number,
    classificationId: number,
    color: string,
    order: number
  ) {
    return this.http.patch(
      `${environment.main_url}/depth-charts/${depthChartId}/classification-colors/${classificationId}/`,
      {
        color,
        order,
      }
    );
  }
  getDepthChartPositionClassifications(depthChartId: number) {
    return this.http.get<DepthChartPositionClassificationsApiResponse[]>(
      `${environment.main_url}/depth-charts/${depthChartId}/classification-colors/`
    );
  }

  updateResetPanelState(state: boolean): void {
    this.resetPanelSubject.next(state);
  }
}

export enum DepthChartTypes {
  scout = 'scout_team',
  official = 'official',
  mock = 'mock',
  master = '',
}
