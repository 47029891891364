import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Comment } from '@shared/interfaces/comment.interface';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { Season } from '@shared/interfaces/season.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommentService {
  constructor(private http: HttpClient) {}

  getCommentsByPositionGroups(
    pgIds: PositionGroup['name'][] | '',
    seasonId: Season['id']
  ): Observable<Comment[]> {
    return this.http.get<Comment[]>(
      `${environment.main_url}/Comment/?pg__in=${pgIds}&season=${seasonId}`
    );
  }
}
