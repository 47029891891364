import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { PositionGroup } from '../../interfaces/position-group.interface';
import {
  AddPositionGroup,
  AddPositionInPositionGroup,
  DeletePositionGroup,
  DeletePositionInPositionGroup,
  EditPositionGroup,
  EditPositionInPositionGroup,
  GetAllPositionGroups,
  GetAllPositionGroupsWithoutSeasons,
  GetPositionGroups,
  PostPositionOrder,
  SelectedPositionGroups,
} from './position-group.actions';
import { catchError, iif, of, tap, throwError } from 'rxjs';
import { PositionGroupService } from '@shared/services/position-group/position-group.service';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { PositionOrderPayload } from '../../../modules/position/interfaces/position.interface';
import _ from 'lodash';

export interface PositionGroupStateModel {
  positionGroups: PositionGroup[];
  allPositionGroups: PositionGroup[];
  selectedPositionGroup: PositionGroup[];
  allPositionGroupsWithoutSeasons: PositionGroup[];
}

@State<PositionGroupStateModel>({
  name: 'position_group',
  defaults: {
    positionGroups: [],
    allPositionGroups: [],
    selectedPositionGroup: [],
    allPositionGroupsWithoutSeasons: [],
  },
})
@Injectable()
export class PositionGroupState {
  constructor(private positionGroupService: PositionGroupService) {}

  groupPgs(pgs: PositionGroup[]): PositionGroup[] {
    const customOrder = { O: 1, D: 2, ST: 3 };

    return _.orderBy(pgs, (item) => customOrder[item.side_of_ball]);
  }

  @Action(GetPositionGroups)
  getPositionGroups(
    { getState, patchState }: StateContext<PositionGroupStateModel>,
    { sob, seasonId }: GetPositionGroups
  ) {
    let positionGroups = getState().positionGroups;
    return iif(
      () =>
        positionGroups.length &&
        positionGroups[0].side_of_ball == sob &&
        positionGroups[0].season == seasonId,
      of(positionGroups),
      this.positionGroupService.getPositionGroup(sob, seasonId).pipe(
        tap((positionGroups) => {
          patchState({
            positionGroups: positionGroups,
          });
          return positionGroups;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      )
    );
  }

  @Action(GetAllPositionGroups)
  getAllPositionGroups(
    { getState, patchState }: StateContext<PositionGroupStateModel>,
    { seasonId }: GetAllPositionGroups
  ) {
    let allPositionGroups = getState().allPositionGroups;

    return iif(
      () => allPositionGroups.length && allPositionGroups[0].season == seasonId,
      of(allPositionGroups),
      this.positionGroupService.getAllPositionGroups(seasonId).pipe(
        tap((allPositionGroups) => {
          patchState({
            allPositionGroups: this.groupPgs(allPositionGroups),
          });
          return allPositionGroups;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      )
    );
  }

  @Action(SelectedPositionGroups)
  selectedPositionGroup(
    { patchState }: StateContext<PositionGroupStateModel>,
    { payload }: SelectedPositionGroups
  ) {
    patchState({ selectedPositionGroup: payload });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<PositionGroupStateModel>) {
    ctx.setState({
      positionGroups: [],
      allPositionGroups: [],
      selectedPositionGroup: [],
      allPositionGroupsWithoutSeasons: [],
    });
  }

  @Action(AddPositionInPositionGroup)
  addPositionToPositionGroup(
    ctx: StateContext<PositionGroupStateModel>,
    { position }: AddPositionInPositionGroup
  ) {
    const state = ctx.getState();
    const updatePositionGroups = (groups: any[]) =>
      groups.map((group) =>
        group.name === position.postion_group
          ? { ...group, positions: [...group.positions, position] }
          : group
      );

    ctx.patchState({
      allPositionGroups: updatePositionGroups(state.allPositionGroups),
      positionGroups: updatePositionGroups(state.positionGroups),
      allPositionGroupsWithoutSeasons: updatePositionGroups(state.allPositionGroupsWithoutSeasons),
    });
  }

  @Action(EditPositionInPositionGroup)
  editPositionToPositionGroup(
    ctx: StateContext<PositionGroupStateModel>,
    { position }: EditPositionInPositionGroup
  ) {
    const state = ctx.getState();
    const updateGroups = (groups: PositionGroup[]) =>
      groups.map((group) => {
        if (group.name === position.postion_group) {
          return {
            ...group,
            positions: group.positions.map((pos) => (pos.id === position.id ? position : pos)),
          };
        }
        return group;
      });

    ctx.patchState({
      allPositionGroups: updateGroups(state.allPositionGroups),
      positionGroups: updateGroups(state.positionGroups),
      allPositionGroupsWithoutSeasons: updateGroups(state.allPositionGroupsWithoutSeasons),
    });
  }

  @Action(DeletePositionInPositionGroup)
  deletePositionInPositionGroup(
    ctx: StateContext<PositionGroupStateModel>,
    { position }: DeletePositionInPositionGroup
  ) {
    const state = ctx.getState();

    const updateGroups = (groups: PositionGroup[]) =>
      groups.map((group) =>
        group.name === position.postion_group
          ? { ...group, positions: group.positions.filter((pos) => pos.id !== position.id) }
          : group
      );

    ctx.patchState({
      allPositionGroups: updateGroups(state.allPositionGroups),
      positionGroups: updateGroups(state.positionGroups),
      allPositionGroupsWithoutSeasons: updateGroups(state.allPositionGroupsWithoutSeasons),
    });
  }

  @Action(AddPositionGroup)
  addPositionGroup(
    ctx: StateContext<PositionGroupStateModel>,
    { positionGroup }: AddPositionGroup
  ) {
    const state = ctx.getState();

    ctx.patchState({
      allPositionGroups: this.groupPgs([...state.allPositionGroups, positionGroup]),
      positionGroups: this.groupPgs([...state.positionGroups, positionGroup]),
      allPositionGroupsWithoutSeasons: this.groupPgs([
        ...state.allPositionGroupsWithoutSeasons,
        positionGroup,
      ]),
    });
  }

  @Action(EditPositionGroup)
  editPositionGroup(
    ctx: StateContext<PositionGroupStateModel>,
    { positionGroup }: EditPositionGroup
  ) {
    const state = ctx.getState();

    const updatePositionGroupList = (
      positionGroups: PositionGroup[],
      updatedGroup: PositionGroup
    ) => positionGroups.map((pg) => (pg.name === updatedGroup.name ? { ...updatedGroup } : pg));

    const updatedPositionGroups = updatePositionGroupList(state.positionGroups, positionGroup);
    const updatedAllPositionGroups = updatePositionGroupList(
      state.allPositionGroups,
      positionGroup
    );
    const updatedAllPositionGroupsWithouSeason = updatePositionGroupList(
      state.allPositionGroupsWithoutSeasons,
      positionGroup
    );

    ctx.patchState({
      positionGroups: this.groupPgs(updatedPositionGroups),
      allPositionGroups: this.groupPgs(updatedAllPositionGroups),
      allPositionGroupsWithoutSeasons: this.groupPgs(updatedAllPositionGroupsWithouSeason),
    });
  }

  @Action(DeletePositionGroup)
  deletePositionGroup(
    ctx: StateContext<PositionGroupStateModel>,
    { positionGroup }: DeletePositionGroup
  ) {
    const state = ctx.getState();
    const updateGroups = (groups: PositionGroup[]) =>
      groups.filter((group) => group.name !== positionGroup.name);

    ctx.patchState({
      allPositionGroups: updateGroups(state.allPositionGroups),
      positionGroups: updateGroups(state.positionGroups),
      allPositionGroupsWithoutSeasons: updateGroups(state.allPositionGroupsWithoutSeasons),
    });
  }

  @Action(PostPositionOrder)
  postPositionOrder(ctx: StateContext<PositionGroupStateModel>, { payload }: PostPositionOrder) {
    const state = ctx.getState();
    const updatePositionOrder = (groups: PositionGroup[], payload: PositionOrderPayload[]) =>
      groups.map((group) => {
        if (
          payload.some((payloadItem) =>
            group.positions.some((position) => position.id === payloadItem.id)
          )
        ) {
          group.positions = group.positions.map((position) => {
            const updatedPosition = payload.find((item) => item.id === position.id);
            if (updatedPosition) {
              return { ...position, order: updatedPosition.order };
            }
            return position;
          });
          group.positions.sort((a, b) => a.order - b.order);
        }
        return group;
      });
    ctx.patchState({
      allPositionGroups: updatePositionOrder(state.allPositionGroups, payload),
      positionGroups: updatePositionOrder(state.positionGroups, payload),
      allPositionGroupsWithoutSeasons: updatePositionOrder(
        state.allPositionGroupsWithoutSeasons,
        payload
      ),
    });
  }

  @Action(GetAllPositionGroupsWithoutSeasons)
  getAllPositionGroupsWithoutSeasons({
    getState,
    patchState,
  }: StateContext<PositionGroupStateModel>) {
    return this.positionGroupService.getAllPositionGroupsWithoutSeasons().pipe(
      tap((positionGroups) => {
        patchState({
          allPositionGroupsWithoutSeasons: this.groupPgs(positionGroups),
        });
        return positionGroups;
      }),
      catchError((error) => throwError(() => error))
    );
  }
}
