import { ChangeDetectorRef, Component } from '@angular/core';
import { FuseAlertComponent } from '@fuse/components/alert';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: '[error-toast-component]',
  template: `<fuse-alert type="error" appearance="outline" class="my-2">
    <span fuseAlertTitle>{{ title }}</span>
    {{ message }}
  </fuse-alert>`,
  standalone: true,
  imports: [FuseAlertComponent],
})
export class ErrorToast extends Toast {
  title = '';
  message = '';

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private cdr: ChangeDetectorRef
  ) {
    super(toastrService, toastPackage);

    this.title = toastPackage.title;
    this.message = toastPackage.message;

    setTimeout(() => {
      this.cdr.detectChanges();
    }, 6000);
  }
}
