import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Player, PlayerPosition, PositionGroup } from '@shared/interfaces/player';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'og-checkbox-renderer',
  standalone: true,
  imports: [CommonModule, MatInputModule, FormsModule, MatSelectModule],
  template: `
    <div>
      <div
        class="text-center"
        [ngClass]="{ 'h-[26px] -mt-[4px]': player.checked && !player.selected }"
      >
        <input
          type="checkbox"
          [(ngModel)]="player.checked"
          [disabled]="player.disable"
          (click)="checkboxChange(player, player.checked)"
        />
      </div>

      <div *ngIf="player.checked && !player.selected">
        <mat-select
          name="position"
          placeholder="Position"
          class="w-[100px] border-b-[1px] border-[#c6c6c6]"
          [panelWidth]="'220px'"
        >
          <ng-container *ngFor="let pg of pgs">
            <mat-optgroup [label]="pg.pg_name">
              <mat-option
                *ngFor="let position of pg.positions"
                [value]="position"
                (click)="positionChange(player, position)"
              >
                {{ position.name }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-select>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  player!: Player;
  pgs: PositionGroup[] = [];

  params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.player = this.params.data;
    this.pgs = params['pgs'];
  }

  refresh(params?: any): boolean {
    return true;
  }

  checkboxChange(player: Player, event: boolean): void {
    this.params.context.parentComponent.checkboxChange(player, event);
  }

  positionChange(player: Player, position: PlayerPosition): void {
    this.params.context.parentComponent.positionChange(player, position);
  }
}
