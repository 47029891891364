import { ConditionFilter, Game } from '../../interfaces/infinite-athlete.interface';

export class GetSelectedGame {
  static readonly type = '[selectedGame] Get SelectedGame';
  constructor() {}
}

export class SetSelectedGame {
  static readonly type = '[setSelectedGame] Set SelectedGame';
  constructor(public selectedGame: Game) {}
}
