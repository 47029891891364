import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from './core/layout/layout.component';
import { AuthForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { AuthSignInComponent } from './modules/auth/sign-in/sign-in.component';
import { AuthSignUpComponent } from './modules/auth/sign-up/sign-up.component';
import { AppResolver } from '@shared/reslovers/app.resolver';
import { ShowMatchesComponent } from './modules/show-matches/show-matches.component';
import { UserProfileComponent } from './modules/user-profile/user-profile.component';

export const appRoutes: Route[] = [
  // Auth routes for guests
  {
    path: 'authentication',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      { path: 'forgot-password', component: AuthForgotPasswordComponent },
      {
        path: 'reset/:uid/:token',
        loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes'),
      },
      { path: 'sign-in', component: AuthSignInComponent },
      { path: 'sign-up', component: AuthSignUpComponent },
      { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
    ],
  },
  {
    path: 'public',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    loadChildren: () => import('app/modules/public/public.routes'),
  },
  {
    path: 'grading',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      data: AppResolver,
    },
    data: {
      layout: 'empty',
    },
    loadChildren: () => import('app/modules/grading/grading.routes'),
  },

  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      data: AppResolver,
    },
    children: [
      { path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.routes') },
      { path: 'seasons', loadChildren: () => import('app/modules/seasons/seasons.routes') },
      { path: 'opponents', loadChildren: () => import('app/modules/opponents/opponents.routes') },
      { path: 'show-matches', component: ShowMatchesComponent },
      {
        path: 'production-items',
        loadChildren: () => import('app/modules/production/production.routes'),
      },
      {
        path: 'system-settings',
        loadChildren: () => import('app/modules/system-settings/system-settings.routes'),
      },
      {
        path: 'data-import',
        loadChildren: () => import('app/modules/data-import/data-import.routes'),
      },
      {
        path: 'help-center',
        loadChildren: () => import('app/modules/help-center/help-center.routes'),
      },
      { path: 'player', loadChildren: () => import('app/modules/roster/roster.routes') },
      {
        path: 'player-substitution',
        loadChildren: () => import('app/modules/player-substitution/player-substitution.routes'),
      },
      { path: 'position', loadChildren: () => import('app/modules/position/position.routes') },
      { path: 'guest', loadChildren: () => import('app/modules/guest/guest.routes') },
      {
        path: 'reports',
        loadChildren: () => import('app/modules/reports/reports.routes'),
      },
      {
        path: 'player-comparison',
        loadChildren: () => import('app/modules/player-comparison/player-comparison.routes'),
      },
      {
        path: 'user-profile',
        loadComponent: () => UserProfileComponent,
      },
      {
        path: 'production-summary-report',
        loadChildren: () =>
          import('app/modules/production-summary-report/production-summary-report.routes'),
      },
      {
        path: 'infinite-athlete',
        loadChildren: () => import('app/modules/infinite-athlete/infinite-athlete.routes'),
      },
      { path: 'coach', loadChildren: () => import('app/modules/coach/coach.routes') },
      {
        path: 'player-transcript-report',
        loadChildren: () =>
          import('app/modules/player-transcript-report/player-transcript-report.routes'),
      },
      {
        path: 'player/gradingreport/detail',
        loadChildren: () => import('app/shared/components/report-detail/report-detail.routes'),
      },
      {
        path: 'efficiency-report',
        loadChildren: () => import('app/modules/efficiency-report/efficiency-report.routes'),
      },
      {
        path: 'position-group-report',
        loadChildren: () =>
          import('app/modules/position-group-report/position-group-report.routes'),
      },
      {
        path: 'gridiron-report',
        loadChildren: () => import('app/modules/gridiron-report/gridiron-report.routes'),
      },
      {
        path: 'coach-report',
        loadChildren: () => import('app/modules/coach-report/coach-report.routes'),
      },
      {
        path: 'play-by-play-report',
        loadChildren: () => import('app/modules/play-by-play-report/play-by-play-report.routes'),
      },
      {
        path: 'player-production-report',
        loadChildren: () =>
          import('app/modules/player-production-report/player-production-report.routes'),
      },
      {
        path: 'team-production-report',
        loadChildren: () =>
          import('app/modules/team-production-report/team-production-report.routes'),
      },
      {
        path: 'grading-report',
        loadChildren: () => import('app/modules/grading-report/grading-report.routes'),
      },
      {
        path: 'self-scouting-report',
        loadChildren: () => import('app/modules/self-scouting-report/self-scouting-report.routes'),
      },
      {
        path: 'opponent-scouting-report',
        loadChildren: () =>
          import('app/modules/opponent-scouting-report/opponent-scouting-report.routes'),
      },
      { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
    ],
  },
];
