import { Player } from './../../interfaces/infinite-athlete.interface';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { GetIAGamePlayers, SetIAGamePlayers } from './game-players.action';

export interface IAGamePlayersStateModel {
  gamePlayers: Player[];
}

@State<IAGamePlayersStateModel>({
  name: 'IAGamePlayers',
  defaults: {
    gamePlayers: [],
  },
})
@Injectable()
export class IAGamePlayersState {
  constructor() {}

  @Action(GetIAGamePlayers)
  getIAPlayers({ getState }: StateContext<IAGamePlayersState>): Observable<any> {
    return of(getState());
  }

  @Action(SetIAGamePlayers)
  setIAPlayers(
    { patchState }: StateContext<IAGamePlayersStateModel>,
    { players }: SetIAGamePlayers
  ) {
    patchState({
      gamePlayers: players,
    });
  }

  @Action(ClearAllStates)
  clearIAPlayers(ctx: StateContext<IAGamePlayersStateModel>) {
    ctx.setState({
      gamePlayers: [],
    });
  }
}
