<div class="d-flex justify-content-center align-items-center">
  <span
    class="pill"
    [class.run]="fieldValue === 'Run' || fieldValue === 'KO'"
    [class.pass]="fieldValue === 'Pass' || fieldValue === 'KOR'"
    [class.pen]="fieldValue === 'Pen'"
    [class.np]="fieldValue === 'NP'"
    [class.pp]="fieldValue === 'Punt1'"
    [class.pr]="fieldValue === 'PRP'"
    [class.fg]="fieldValue === 'FG'"
    [class.fgb]="fieldValue === 'FGB|XPB'"
  >
    {{ fieldValue }}
  </span>
</div>
