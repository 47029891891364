// matches.state.ts
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatchData, PlayerMatchI } from '../interfaces/show-matches.interface';
import { ShowMatchesService } from '../services/show-matches.service';
import { DeletePlayerMatch, LoadMatches } from './matches.action';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

// Define the state model
export interface MatchesStateModel {
  matches: MatchData[];
}

@State<MatchesStateModel>({
  name: 'matches',
  defaults: {
    matches: [],
  },
})
@Injectable()
export class MatchesState {
  constructor(private matchesService: ShowMatchesService) {}

  @Action(LoadMatches)
  loadMatches(
    { patchState }: StateContext<MatchesStateModel>,
    { seasonId, weekNo, countNo, playType, sob, periodDescription, type }: LoadMatches
  ): Observable<MatchData[]> {
    return this.matchesService
      .getMatches(seasonId, weekNo, countNo, playType, sob, periodDescription, type)
      .pipe(
        tap((matches) => {
          patchState({ matches: matches });
        })
      );
  }

  // @Action(PlayerMatch)
  // getPlayerMatch(
  //   { patchState, getState }: StateContext<MatchesStateModel>,
  //   { matchId, sob }: PlayerMatch
  // ): Observable<PlayerMatchI[]> {
  //   return this.matchesService.getPlayerMatch(matchId, sob).pipe(
  //     tap((playerMatches: PlayerMatchI[]) => {
  //       let match = getState().matches.find((match: MatchData) => match.id == matchId);
  //       if (match) {
  //         match.player_matches = [];
  //         match.player_matches.push(...playerMatches);
  //         match.player_matches = [
  //           ...new Map(match.player_matches.map((item) => [item['id'], item])).values(),
  //         ];

  //         let matches = [...getState().matches, match];
  //         matches = [...new Map(matches.map((item) => [item['id'], item])).values()];

  //         patchState({
  //           matches: matches,
  //         });
  //       } else {
  //         patchState({
  //           matches: [{ id: matchId, player_matches: playerMatches } as any],
  //         });
  //       }
  //     })
  //   );
  // }

  @Action(DeletePlayerMatch)
  deletePlayerMatch(
    { getState, patchState }: StateContext<MatchesStateModel>,
    { matchId }: DeletePlayerMatch
  ): Observable<PlayerMatchI[]> {
    return this.matchesService.deletePlayerMatch(matchId).pipe(
      tap(() => {
        const state = getState();
        const updatedMatches = state.matches.filter((match) => match.id !== matchId);
        patchState({
          matches: updatedMatches,
        });
      })
    );
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<MatchesStateModel>) {
    ctx.setState({
      matches: [],
    });
  }
}
