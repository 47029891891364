import { GuestToken, GuestUser, GuestUserPayload } from '../interfaces/guest.interface';

export class GetGuestUsers {
  static readonly type = '[Guest] Get Guest Users';
}

export class DeleteGuestUser {
  static readonly type = '[Guest] Delete Guest User';
  constructor(public id: GuestUser['id']) {}
}

export class UpdateGuestUser {
  static readonly type = '[Guest] Update Guest User';
  constructor(
    public id: GuestUser['id'],
    public payload: GuestUserPayload
  ) {}
}

export class GetGuestTokens {
  static readonly type = '[Guest] Get Guest Tokens';
}

export class DeleteGuestToken {
  static readonly type = '[Guest] Delete Guest Token';
  constructor(public id: GuestToken['id']) {}
}
