import { WeeksApi } from '@shared/interfaces/weeks.interface';

export class SelectedWeek {
  static readonly type = '[Weeks] Selected Week';

  constructor(public payload: WeeksApi) {}
}

export class ResetWeeks {
  static readonly type = '[Weeks] Reset Weeks';
}

export class ResetSelectedWeek {
  static readonly type = '[Weeks] ResetSelected Week';
}

export class LoadWeeks {
  static readonly type = '[Weeks] Load Weeks';

  constructor(public id: number) {}
}
