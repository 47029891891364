import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  Player,
  PlayerForm,
  PositionGroup,
  RosterApiResponse,
  SeasonWisePlayerDetailValue,
} from '../interfaces/roster.types';
import { environment } from '../../../../environments/environment';
import { Select, Store } from '@ngxs/store';
import { SeasonSelectors } from '@shared/states/season/season.selector';
import { Season } from '@shared/interfaces/season.interface';

@Injectable({ providedIn: 'root' })
export class RosterService implements OnInit {
  @Select(SeasonSelectors.selectedSeason) selectedSeason$: Observable<Season>;
  selectedSeason: Season;
  editModeSource = new BehaviorSubject<boolean>(false);
  editMode$ = this.editModeSource.asObservable();

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private store: Store
  ) {}

  ngOnInit(): void {}

  getPlayersBySeason(seasonId: Season['id']): Observable<Player[]> {
    return this._httpClient.get<Player[]>(
      `${environment.main_url}/Player/?playerposition__season=${seasonId}`
    );
  }

  getPaginatedPlayers(season: number, page = 1, search = ''): Observable<RosterApiResponse> {
    return this._httpClient.get<RosterApiResponse>(
      environment.main_url +
        `/Player-pg/?playerposition__season=${season}&page=${page}&search=${search}`
    );
  }

  getPlayerById(id: number): Observable<any> {
    return this._httpClient.get(environment.main_url + `/Player/${id}/`);
  }

  getCarriers(): Observable<string[]> {
    return this._httpClient.get<string[]>(environment.main_url + '/Player/mobile-carriers/');
  }
  getPositionGroups(
    seasonId: Season['id']
  ): Observable<{ O: PositionGroup[]; D: PositionGroup[]; ST: PositionGroup[] }> {
    return this._httpClient
      .get<
        PositionGroup[]
      >(environment.main_url + `/PositionGroup/?side_of_ball__in=O,D,ST&season=${seasonId}`)
      .pipe(
        map((positionGroups: PositionGroup[]) => {
          const result: {
            O: PositionGroup[];
            D: PositionGroup[];
            ST: PositionGroup[];
          } = { O: [], D: [], ST: [] };

          positionGroups.forEach((positionGroup) => {
            switch (positionGroup.side_of_ball) {
              case 'O':
                result.O.push(positionGroup);
                break;
              case 'D':
                result.D.push(positionGroup);
                break;
              case 'ST':
                result.ST.push(positionGroup);
                break;
            }
          });

          return result;
        })
      );
  }

  assignPlayersInBulk(payload): Observable<any> {
    return this._httpClient.post(`${environment.main_url}/Player/players-assignment/`, payload);
  }

  addPlayer(payload: FormData): Observable<Player> {
    return this._httpClient.post<Player>(`${environment.main_url}/Player/`, payload);
  }

  editPlayer(id, formData: FormData): Observable<Player> {
    return this._httpClient.patch<Player>(`${environment.main_url}/Player/${id}/`, formData);
  }

  deletePlayer(playerId, seasonId) {
    return this._httpClient.delete(
      `${environment.main_url}/Player/delete_player_season/?player=${playerId}&season=${seasonId}`
    );
  }

  postPlayerDetail(payload): Observable<SeasonWisePlayerDetailValue> {
    return this._httpClient.post<SeasonWisePlayerDetailValue>(
      `${environment.main_url}/player-details/`,
      payload
    );
  }

  editPlayerDetail(id: number, payload): Observable<SeasonWisePlayerDetailValue> {
    return this._httpClient.patch<SeasonWisePlayerDetailValue>(
      `${environment.main_url}/player-details/${id}/`,
      payload
    );
  }

  downloadPlayerImportTemplate(): Observable<any> {
    return this._httpClient.get(`${environment.main_url}/PlayerReport/playerimportemplate/`, {
      responseType: 'blob',
    });
  }

  deleteAllPlayers(seasonId: number) {
    return this._httpClient.delete(
      `${environment.main_url}/Player-pg/delete-all-players/${seasonId}/`
    );
  }

  inheritRosterFromAnotherSeason(requestBody: any) {
    return this._httpClient.post(
      `${environment.main_url}/Player/copy-players-from-season/`,
      requestBody
    );
  }

  uploadRosterFile(requestBody: any) {
    return this._httpClient.post(`${environment.main_url}/Player/player_upload/`, requestBody);
  }

  dumpPlayers(requestBody: any) {
    return this._httpClient.post(`${environment.main_url}/player-dump/`, requestBody);
  }

  setEditMode(isEditMode: boolean) {
    this.editModeSource.next(isEditMode);
  }

  getPlayersForSpecificPGandSeason(
    pg: PositionGroup['name'][],
    seasonId: Season['id']
  ): Observable<Player[]> {
    return this._httpClient.get<Player[]>(
      `${environment.main_url}/Player/?playerposition__position_group__in=${pg.join(',')}&playerposition__season=${seasonId}`
    );
  }
}
