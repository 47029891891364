import { Season } from '@shared/interfaces/season.interface';

export class SelectedSeason {
  static readonly type = '[Season] Selected Season';

  constructor(public payload: Season) {}
}

export class AppendSeason {
  static readonly type = '[Season] Append Seasons';
  constructor(public payload: Season) {}
}

export class DeleteSeason {
  static readonly type = '[Season] Delete Seasons';
  constructor(public payload: Season) {}
}

export class EditSeasonDescription {
  static readonly type = '[Season] Edit Seasons Description';
  constructor(public payload: Season) {}
}

export class SetDefaultSeason {
  static readonly type = '[Season] Set Default Season';
  constructor(public payload: Season) {}
}

export class GetSeasons {
  static readonly type = '[Season] Get Seasons';
}

export class UpdateSelectedSeasonsForDeletion {
  static readonly type = '[Season] Update Selected Seasons For Deletion';
  constructor(public seasons: Season[]) {}
}
