import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { CoachData, EditDetailsPayload, UserData } from '../interfaces/user-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(private http: HttpClient) {}

  getProfileData(): Observable<UserData> {
    return this.http.get<UserData>(`${environment.main_url}/rest-auth/user/`);
  }
  editProfileDetails(payload: EditDetailsPayload): Observable<UserData> {
    return this.http.patch<UserData>(`${environment.main_url}/rest-auth/user/`, payload);
  }

  editAvatar(payload: FormData, id: CoachData['id']): Observable<CoachData> {
    return this.http.patch<CoachData>(`${environment.main_url}/Coach/${id}/`, payload);
  }
}
