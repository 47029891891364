import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GuestToken, GuestUser } from '../interfaces/guest.interface';
import { GuestService } from '../services/guest.service';
import {
  DeleteGuestToken,
  DeleteGuestUser,
  GetGuestTokens,
  GetGuestUsers,
  UpdateGuestUser,
} from './guest.action';

export interface GuestStateModel {
  guestUsers: GuestUser[];
  guestTokens: GuestToken[];
}

@State<GuestStateModel>({
  name: 'guest',
  defaults: {
    guestUsers: [],
    guestTokens: [],
  },
})
@Injectable()
export class GuestState {
  constructor(private guestService: GuestService) {}

  @Action(GetGuestUsers)
  getGuestUsers(ctx: StateContext<GuestStateModel>) {
    return this.guestService.getGuestUsers().pipe(
      tap((result) => {
        ctx.patchState({
          guestUsers: result,
        });
      })
    );
  }

  @Action(DeleteGuestUser)
  deleteGuestUser(ctx: StateContext<GuestStateModel>, action: DeleteGuestUser) {
    return this.guestService.deleteGuestUsers(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const filteredUsers = state.guestUsers.filter((user) => user.id !== action.id);
        ctx.patchState({
          guestUsers: filteredUsers,
        });
      })
    );
  }

  @Action(UpdateGuestUser)
  updateGuestUser(ctx: StateContext<GuestStateModel>, action: UpdateGuestUser) {
    const { id, payload } = action;
    return this.guestService.updateGuestUsers(id, payload).pipe(
      tap(() => {
        const state = ctx.getState();
        const updatedGuestUsers = state.guestUsers.map((user) =>
          user.id === id ? { ...user, ...payload } : user
        );

        const updatedGuestTokens = state.guestTokens.map((token) =>
          token.user === id ? { ...token, alias: payload.alias } : token
        );

        ctx.patchState({
          guestUsers: updatedGuestUsers,
          guestTokens: updatedGuestTokens,
        });
      })
    );
  }

  @Action(GetGuestTokens)
  getGuestTokens(ctx: StateContext<GuestStateModel>) {
    return this.guestService.getGuestToken().pipe(
      tap((result) => {
        ctx.patchState({
          guestTokens: result,
        });
      })
    );
  }

  @Action(DeleteGuestToken)
  deleteGuestToken(ctx: StateContext<GuestStateModel>, action: DeleteGuestToken) {
    return this.guestService.deleteGuestToken(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const filteredTokens = state.guestTokens.filter((token) => token.id !== action.id);
        ctx.patchState({
          guestTokens: filteredTokens,
        });
      })
    );
  }
}
