<section *ngIf="type == 'select' && positionGroups.length" class="flex items-center justify-center">
  <mat-form-field subscriptSizing="dynamic" class="w-full">
    <mat-select
      panelWidth="269px"
      [placeholder]="currentSob?.value == Sob.SpecialTeamsValue ? 'Phases' : 'Position Group'"
      label="Select Position Group"
      [(ngModel)]="selectedPgId"
      [disabled]="isDisabled"
    >
      <mat-option
        *ngFor="let positionGroup of positionGroups$ | async"
        [value]="positionGroup?.name"
        (click)="positionGroupChange(positionGroup)"
        >{{ positionGroup.pg_name }} ({{ positionGroup.pg }})</mat-option
      >
    </mat-select>
  </mat-form-field>
</section>

<section *ngIf="type == 'chips' && positionGroups.length" class="flex items-center justify-center">
  <mat-chip-listbox
    aria-label="Position Group selection"
    [disabled]="(isLoading$ | async) || isDisabled"
  >
    <mat-chip-option
      [selected]="positionGroup.selected"
      *ngFor="let positionGroup of positionGroups"
      (click)="positionGroupChange(positionGroup)"
    >
      {{ positionGroup.pg }}
    </mat-chip-option>
    <mat-chip-option
      [selected]="isMasterListSelected"
      (click)="positionGroupChange({ name: undefined })"
    >
      Master List
    </mat-chip-option>
  </mat-chip-listbox>
</section>
