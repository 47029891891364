// Load PgOptions Action

import { PositionGroup } from '@shared/interfaces/position-group.interface';
import {
  AddMtp,
  EditMtp,
  PositionGroupMtps,
  UpdateMtpOrderI,
} from '../interfaces/production.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { Season } from '@shared/interfaces/season.interface';

export class GetMtps {
  static readonly type = '[Production] Get Mtps';

  constructor(
    public sobValue: SOB['value'],
    public seasonId: Season['id']
  ) {}
}

export class GetPgMtps {
  static readonly type = '[Production] PositionGroup Mtps';

  constructor(
    public pgId: PositionGroup['name'],
    public seasonId: Season['id'],
    public sobValue: SOB['value']
  ) {}
}

export class AppendMtp {
  static readonly type = '[Production] Add MTP';

  constructor(public payload: AddMtp) {}
}

export class UpdateMtp {
  static readonly type = '[Production] Update Mtp';

  constructor(
    public id: number,
    public payload: EditMtp
  ) {}
}

export class UpdateMtpWeight {
  static readonly type = '[Production] Update Mtp Weight';

  constructor(
    public id: number,
    public payload: EditMtp
  ) {}
}

export class DeleteMtp {
  static readonly type = '[Production] Delete Mtp';

  constructor(public id: number) {}
}

export class UpdateMtpOrder {
  static readonly type = '[Production] Update Mtp Order';

  constructor(
    public id: number,
    public payload: UpdateMtpOrderI[],
    public data: PositionGroupMtps['mtps']
  ) {}
}

export class AddMtpToPg {
  static readonly type = '[Production] Add MTP to PositionGroup';

  constructor(
    public pgId: PositionGroup['name'],
    public payload: any
  ) {}
}

export class DeletePgMtp {
  static readonly type = '[Production] Delete Position Group Mtp';

  constructor(
    public pgId: PositionGroup['name'],
    public id: number
  ) {}
}

export class GetSkills {
  static readonly type = '[Production] Get Skills';

  constructor(
    public pgId: number,
    public season_id: number
  ) {}
}

export class AddSkill {
  static readonly type = '[Production] Add Skill';

  constructor(
    public pgId: number,
    public payload: any
  ) {}
}

export class UpdateSkill {
  static readonly type = '[Production] Update Skill';

  constructor(
    public pgId: number,
    public skillId: number,
    public payload: any
  ) {}
}

export class DeleteSkill {
  static readonly type = '[Production] Delete Skill';

  constructor(
    public pgId: number,
    public skillId: number
  ) {}
}

export class GetComments {
  static readonly type = '[Production] Get Comments';

  constructor(
    public pgId: number,
    public season_id: number
  ) {}
}

export class AddComment {
  static readonly type = '[Production] Add Comment';

  constructor(
    public pgId: number,
    public payload: any
  ) {}
}

export class UpdateComment {
  static readonly type = '[Production] Update Comment';

  constructor(
    public pgId: number,
    public commentId: number,
    public payload: any
  ) {}
}

export class UpdateCommentsOrder {
  static readonly type = '[Production] Update Comments Order';

  constructor(
    public pgId: number,
    public payload: any
  ) {}
}

export class DeleteComment {
  static readonly type = '[Production] Delete Comment';

  constructor(
    public pgId: number,
    public commentId: number
  ) {}
}
