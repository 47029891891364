import { Selector } from '@ngxs/store';
import { PositionGroupState, PositionGroupStateModel } from './position-group.state';

export class PositionGroupSelectors {
  @Selector([PositionGroupState])
  static positionGroups(state: PositionGroupStateModel): PositionGroupStateModel['positionGroups'] {
    return state.positionGroups.sort((a, b) => a.pg_name.localeCompare(b.pg_name));
  }

  @Selector([PositionGroupState])
  static allPositionGroups(
    state: PositionGroupStateModel
  ): PositionGroupStateModel['allPositionGroups'] {
    return state.allPositionGroups;
  }

  @Selector([PositionGroupState])
  static selectedPositionGroups(
    state: PositionGroupStateModel
  ): PositionGroupStateModel['selectedPositionGroup'] {
    return state.selectedPositionGroup;
  }

  @Selector([PositionGroupState])
  static allPositionGroupsWithoutSeasons(
    state: PositionGroupStateModel
  ): PositionGroupStateModel['allPositionGroupsWithoutSeasons'] {
    return state.allPositionGroupsWithoutSeasons;
  }
}
